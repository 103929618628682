import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { getBaseItem, setItem, getItem } from '@base/lib/storage-handler'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/id'
import { getTabRed } from '@base/api/common'
import { FaceDetectionEnum } from '@base/enum/common'

export interface IAppState {
  loginStatus: boolean
  language: string
  homePopupType: string
  displayCurrentIds: string[]
  showTabPoint: {
    billTabPoint: boolean
    meTabPoint: boolean
  }
}

@Module
export default class App extends VuexModule {
  homePopupType = ''

  showUtils = process.env.VUE_APP_ENV !== 'production'

  showTabPoint = {
    billTabPoint: false,
    meTabPoint: false,
  }
  faceDetectionType = FaceDetectionEnum.UNDEFINED

  loginStatus: boolean = (() => {
    const localStorageLoginStatus = getBaseItem('loginStatus', 'false')
    return Boolean(localStorageLoginStatus)
  })()

  language: string = (() => {
    const language = window.navigator.language.toLowerCase()
    return (getItem('language') || language) === 'en-us' ? 'en-us' : 'id'
  })()

  displayCurrentIds: string[] = (() => {
    const ids = getItem('displayIds') || ''
    return ids.split(',')
  })()

  @Mutation
  updateLoginStatus(payload: boolean) {
    setItem('loginStatus', payload)
  }

  @Mutation
  updateShowUtils(payload: boolean) {
    this.showUtils = payload
    setItem('utils', payload ? 'show' : 'hide')
  }

  @Mutation
  updateLanguage(payload = 'id') {
    const lang: {
      [k: string]: string
    } = {
      'en-us': 'en',
      id: 'id',
    }
    window.vue.$i18n.locale = payload
    window.vue.$validator.localize(lang[payload])
    dayjs.locale(payload === 'id' ? 'id' : 'en')
    setItem('language', payload)
  }
  @Mutation
  saveHomePopupType(payload: string) {
    this.homePopupType = payload
  }
  @Mutation
  addDisplayCurrentId(payload: string) {
    const displayIds = getItem('displayIds') || ''
    if (payload && !displayIds.includes(payload)) {
      setItem('displayIds', displayIds + payload + ',')
    }
  }

  @Mutation
  updateTabPoint(payload: { billTabPoint: boolean; meTabPoint: boolean }) {
    this.showTabPoint = payload
  }

  @Mutation
  updateFaceDetectionType(type: FaceDetectionEnum) {
    this.faceDetectionType = type
  }

  @Action({ commit: 'updateLanguage' })
  saveLanguage(payload = 'id') {
    return payload
  }

  @Action({ commit: 'updateShowUtils' })
  saveShowUtils(payload = true) {
    return payload
  }

  @Action({ commit: 'updateTabPoint' })
  async saveTabPoint() {
    try {
      const { data } = await getTabRed()
      if (data) {
        const { pendingCount, notificationsCount, unreadCouponCount } = data
        return {
          billTabPoint: !!pendingCount,
          meTabPoint: !!notificationsCount || !!unreadCouponCount,
        }
      }
      return {
        billTabPoint: false,
        meTabPoint: false,
      }
    } catch (error: any) {
      window.vue.$handleError(error)
      return {
        billTabPoint: false,
        meTabPoint: false,
      }
    }
  }
}
export const AppModule = App
