import dsBridge from '@base/plugins/dsbridge'
import { getItem, removeItem, setItem } from '@base/lib/storage-handler'
import { DeviceTypeEnum, WebViewType, StaticAgreeOnEnum } from '@base/enum/common'
import { getLocalChannel, getUrlChannel } from '@base/lib/checkChannel'
import MobileDetect from 'mobile-detect'
import dayjs from 'dayjs'
import { ChannelEnum } from '@base/enum/common'
import { isOtherChannel } from '@base/lib/channelUtil'
const pako = require('pako')

const customBack = new CustomEvent('customBack')

export function getUserInfoFromLocal(type = 'userId') {
  try {
    const userInfo = JSON.parse((getItem('userInfo') as string) || '{}')
    return type === 'userId' ? userInfo?.userId : userInfo?.type
  } catch (error) {
    return false
  }
}

/**  app  */
export const isApp = () => {
  const userAgent = window.navigator.userAgent
  return userAgent.includes('AtomeIdApp') || userAgent.includes('PINTAR_ANDROID') || userAgent.includes('PINTAR_IOS')
}

/**  get client env  */
export const getClientEnv = () => {
  const userAgent = window.navigator.userAgent
  if (userAgent.includes('PINTAR_IOS')) return DeviceTypeEnum.IOS
  if (userAgent.includes('PINTAR_ANDROID') || userAgent.includes('AtomeIdApp')) return DeviceTypeEnum.ANDROID
  return DeviceTypeEnum.H5
}

export const getWebViewType = () => {
  const env = getClientEnv()
  switch (env) {
    case DeviceTypeEnum.IOS:
      return WebViewType.IOS_WEBVIEW
    case DeviceTypeEnum.ANDROID:
      return WebViewType.ANDROID_WEBVIEW
    default:
      return WebViewType.WEB
  }
}

/** app version code > xxx enable dsbridge */
export const isEnableUseDsbridge = () => {
  const env = getClientEnv()
  switch (env) {
    case DeviceTypeEnum.IOS:
      return true
    case DeviceTypeEnum.ANDROID:
      // return window.dkBridge?.getAppVersionCode?.() > 300
      return false
    default:
      break
  }
}

//  language
export const getLanguage = () => {
  if (isApp()) {
    if (isEnableUseDsbridge()) {
      return dsBridge.call('getLocale') === 'en-us' ? 'en-US' : 'id'
    } else {
      return window.dkBridge?.getLocale?.() === 'en-us' ? 'en-US' : 'id'
    }
  } else {
    const defaultLang = window.navigator.language.toLowerCase()
    return (getItem('language') || defaultLang) === 'en-us' ? 'en-US' : 'id'
  }
}

/**
 *
 */
export const maintenance = (message: string) => {
  return window.vue.$alert({
    buttonText: ' ',
    title: getLanguage() !== 'id' ? 'Under maintenance' : 'Sedang dalam proses pemeliharaan',
    message,
  })
}

/**
 *
 */
export const updateApp = (message: string) => {
  return window.vue.$alert({
    buttonText: ' ',
    title: getLanguage() !== 'id' ? 'Under maintenance' : 'Sedang dalam proses pemeliharaan',
    message,
  })
}

export const throttle = (fn: () => void, delay: number) => {
  let canRun = true
  return function () {
    if (!canRun) return
    canRun = false
    setTimeout(() => {
      fn.apply(null)
      canRun = true
    }, delay)
  }
}

export const debounce = (fn: () => void, wait: number): (() => void) => {
  let timer: number | null = null
  return function () {
    if (timer) clearTimeout(timer)
    timer = window.setTimeout(() => {
      fn.apply(null)
    }, wait)
  }
}

const stackRouter = (current = '') => {
  setTimeout(() => {
    const nextPath = location.pathname
    console.log('stackRouter:', current, nextPath)
    if (current && current === nextPath) {
      commonFinishFn()
    }
  }, 300)
}

export const goBack = async (type: 'popstate' | 'custom' = 'custom') => {
  const current = location.pathname
  console.log(111, location.pathname)
  if (type === 'custom') {
    window.dispatchEvent(customBack)
    history.back()
  }
  if (isApp()) {
    stackRouter(current)
  }
}

export const goHomeBase = () => {
  if (isApp()) {
    if (isEnableUseDsbridge()) {
      if (dsBridge.hasNativeMethod('launchHomePage')) {
        dsBridge.call('launchHomePage')
      } else {
        dsBridge.call('finish')
      }
    } else {
      if (window.dkBridge?.launchHomePage) {
        window.dkBridge.launchHomePage()
      } else {
        window.dkBridge?.finish?.()
      }
    }
  } else {
    window.vue.$router.replace({
      name: 'Home',
    })
  }
}

export const goToBill = () => {
  if (isApp()) {
    if (isEnableUseDsbridge()) {
      dsBridge.call('switchToBill')
      dsBridge.call('finish')
    } else {
      window.dkBridge?.switchToBill?.()
      window.dkBridge?.finish?.()
    }
  } else {
    window.vue.$router.replace({
      name: 'Bill',
    })
  }
}

export const goModuleApply = (needBack = 'ModuleApply') => {
  window.vue.$router.push({
    name: 'ModuleApply',
    query: {
      needBack,
    },
  })
}

export const goUpdateInfo = (needBack?: boolean) => {
  removeItem('IncompleteUserData')
  const query = needBack ? { needBack } : {}
  window.vue.$router.push({
    name: 'PersonalInfoUpdate',
    query,
  })
}

export const goNewBankCard = () => {
  window.vue.$router.push({
    name: 'AddLoanBankCard',
  })
}

export const isRejectedForever = (rejectTimestamp: number | undefined) => {
  if (rejectTimestamp) {
    const timeNow = new Date().getTime()
    const temp = rejectTimestamp - timeNow
    if (temp < 1000 * 24 * 60 * 60 * 1000) {
      return false
    }
  }
  return true
}

export const getRejectTime = (rejectTimestamp: number | undefined) => {
  if (rejectTimestamp) {
    const timeNow = new Date().getTime()
    const temp = rejectTimestamp - timeNow
    const hours = Math.ceil(temp / (1000 * 60 * 60))

    if (hours > 24) {
      return {
        showDate: true,
        time: dayjs(rejectTimestamp).format('DD MMM YYYY'),
      }
    }
    return {
      showDate: false,
      time: hours,
    }
  }
  return undefined
}

/**
 * app  web ，
 */
export const formatRouteList = (arr: { path: string }[]) => {
  return arr.map((item) => {
    return {
      ...item,
      path: `/${item.path}`,
    }
  })
}

export const getUuid = () => {
  if (!getItem('uuid')) {
    setItem('uuid', createUuid())
  }
  return getItem('uuid')
}

/** TODO: dkBridge  */
export const dkBridgeWrapper = (dkBridge: string) => {}

/**  */
export const reformatMoney = (value: string) => {
  if (!value) return 0
  const newVal = value.replace(/\./g, '').match(/[1-9]\d*\.?\d*/g) || []
  return newVal[0] ? Number(newVal[0]) : 0
}

/**  web  */
export const enableNativePermissions = (permissions: string[]) => {
  window.dkBridge?.requestPermission?.(permissions, 'onPermissionGranted', 'onPermissionDenied')
} // TODO

/**
 *
 */
export function isFromMobile() {
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
    )
  ) {
    return true
  } else {
    return false
  }
}

export function formatSpaces(val: any) {
  if (typeof val === 'string') {
    const reg = /\s+/g
    return val.replace(reg, ' ').trim()
  }
  return val
}

export function formatParams(params: any) {
  for (const key in params) {
    // eslint-disable-next-line no-prototype-builtins
    if (params?.hasOwnProperty(key)) {
      params[key] = formatSpaces(params[key])
    }
  }
  return params
}

/**
 *  falsy ， 0、undefine、null  false
 */
export const compactObject = <S, T>(val: S | T[]) => {
  const data: any = Array.isArray(val) ? val.filter(Boolean) : val
  return Object.keys(data).reduce(
    (acc, key) => {
      const value = data[key]
      if (value) {
        acc[key] = typeof value === 'object' ? compactObject(value) : value
      }
      return acc
    },
    Array.isArray(val) ? [] : {},
  )
}

/**
 * by URLSearchParams get query name
 * @param name
 * @returns
 */
export const getParams = (name: string, ignoreCase = false) => {
  const query = new URLSearchParams(location.search)
  if (ignoreCase) {
    const newQuery = new URLSearchParams(location.search)
    for (const [name, value] of query) {
      newQuery.append(name.toLowerCase(), value)
    }
    return newQuery.get(name.toLocaleLowerCase())
  }
  return query.get(name)
}

/**
 * Manually control asynchronous import
 * @param url
 */
export const loadScript = (url: string) => {
  const script = document.createElement('script')
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const commonFinishFn = () => {
  if (isApp()) {
    if (isEnableUseDsbridge()) {
      dsBridge.call('finish')
    } else {
      window.dkBridge?.finish?.()
    }
  } else {
    const href = window.vue.$route.query?.redirect ? window.vue.$route.query?.redirect : getWebChannelPath('/')
    location.href = href
  }
}

export const getWebChannelPath = (path: string) => {
  const channel = isApp() ? '' : getLocalChannel()
  return `/${channel}${path}`
}

export function trimValue(value: any, gap = ' ') {
  value = typeof value === 'undefined' ? '' : value
  const reg = new RegExp(gap, 'g')
  value = value.toString().replace(reg, '')
  return value
}

export function validByScroll(that: any) {
  that.$el.querySelector(`[data-vv-name="${that.$validator.errors.items[0].field}"]`).scrollIntoView(false)
}
/**
 * customize uuid
 * @returns
 */
export const createUuid = () => {
  const s: any[] = []
  const hexDigits = '0123456789abcdef'
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'

  return s.join('')
}

export const removeLoading = () => {
  const doms = document.getElementsByClassName('loading-container')
  if (doms && doms.length) {
    doms[0] && doms[0].remove()
  }
}

export function sortByResult(init: any[], res: string[]) {
  if (!init.length) return init
  const result = init
    .filter((item) => res.includes(item.type))
    .sort((a: any, b: any) => res.indexOf(a.type) - res.indexOf(b.type))

  const diff = init.filter((item) => !res.includes(item.type))
  return [...result, ...diff]
}

export const getUserMediaResult = (
  constraints = {
    audio: false,
    video: true,
  },
) => {
  if (navigator.mediaDevices === undefined) {
    ;(navigator as any).mediaDevices = {}
  }
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function (constraints: any) {
      // First, if there is getUserMedia, get it
      const getUserMedia =
        (navigator as any).webkitGetUserMedia || (navigator as any).mozGetUserMedia || (navigator as any).getUserMedia

      // Some browsers don't implement it at all - then return an error to the promise's reject to maintain a unified interface
      if (!getUserMedia) {
        return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
      }
      // Otherwise, wrap a Promise for the old navigator.getUserMedia method
      return new Promise(function (resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject)
      })
    }
  }

  return new Promise((resolve: (stream: MediaStream) => void, reject) => {
    const getUseValue = getItem('canUseUserMedia')
    const canUseGetUsermedia = getUseValue && getUseValue === 'false' ? false : true
    if (canUseGetUsermedia && getBrowerSupport() && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          resolve(stream)
        })
        .catch((error) => {
          console.log('mediaDevices:', error)
          reject(error)
        })
    } else {
      reject('custom')
    }
  })
}

export function isFromMobileBrower() {
  return /(iPhone|iPad|iPod|iOS|Android|Linux armv8l|Linux armv7l|Linux aarch64)/i.test(navigator.platform)
}

export function getBrowerSupport() {
  const ua = navigator.userAgent
  if (ua.includes('UCBrowser')) {
    return false
  } else if (ua.includes('HeyTapBrowser')) {
    return false
  } else if (ua.includes('VivoBrowser')) {
    return false
  } else if (ua.includes('Chrome')) {
    const chromeNum = ua.match(/chrome\/[\d]+/gi)?.[0].split('/')?.[1]
    if (Number(chromeNum) < 70) {
      return false
    }
    return true
  } else {
    return true
  }
}

export function dataToQuery(data: object) {
  let str = ''
  let query = ''
  if (data) {
    for (const key in data) {
      str = key + '=' + data[key] + '&'
    }
    query = str.substring(0, str.length - 1)
  }
  return query
}

/**
 * type can't StaticAgreeOnEnum.RISK
 *
 * @param type StaticAgreeOnEnum
 * @returns
 */

export function getTermOrPrivacyLink(type: StaticAgreeOnEnum) {
  const channel = isApp() ? '' : getLocalChannel() + '/'
  return `${process.env.VUE_APP_HOST}${channel}documents/${type}`
}

export function getTAndCStaticFile(type: StaticAgreeOnEnum) {
  let name = ''
  switch (type) {
    case StaticAgreeOnEnum.PRIVACY_POLICY:
      name = 'PrivacyPolicy'
      break
    case StaticAgreeOnEnum.TERM_OF_SERVICE:
      name = 'TermsOfService'
      break
    case StaticAgreeOnEnum.RISK:
      name = 'Risk'
      break
    case StaticAgreeOnEnum.JAGOTERMS_AND_CONDITIONS:
      name = 'jagoTermsAndConditions'
      break
    default:
      name = 'TermsOfService'
      break
  }
  return `${process.env.VUE_APP_HOST}static/html/${name}.html?v=20241001`
}

export const useGetUsermedia = () => {
  // to collect more data, add channel OFFICIAL2021
  const urlChannel = getUrlChannel()?.toUpperCase()
  const channelList = ['H5', 'OFFICIAL2021', 'MYIM3', 'BIMA3']
  if (channelList.includes(urlChannel)) return true
  return false
}

export const getOsVersion = () => {
  if (getClientEnv() === DeviceTypeEnum.ANDROID) {
    return (window as any).dkBridge.getDeviceInfo?.osVersion
  }
  const userAgent = new MobileDetect(window.navigator.userAgent)
  if (userAgent.is('AndroidOS')) return userAgent.versionStr('Android').toString()
  if (userAgent.is('iOS') || userAgent.is('iPadOS')) return userAgent.versionStr('iOS').toString()

  const osType = userAgent.os()
  return userAgent.version(osType)?.toString()
}

export const getAppVersionName = (code: string) => {
  if (getClientEnv() === DeviceTypeEnum.ANDROID) {
    return (window as any).dkBridge.getAppVersionName()
  }
  const userAgent = new MobileDetect(window.navigator.userAgent)
  const ua = window.navigator.userAgent
  if (userAgent.match(/['PINTAR_IOS']/)) {
    const version = ua.substr(ua.indexOf('PINTAR_IOS') + 11)
    const versionCode = version.split('(')[0]
    return versionCode
  }
  return code
}

export const businessAddressValidator = (value: string) => {
  const values = value ? value.split(' ') : []
  const notContain = ['kantor', 'kerja', 'gudang', 'company', 'nama', 'perusahaan']
  const consecutive = /^(?!.*(.)\1\1)[a-zA-Z. ]+$/
  const regs = /^[a-zA-Z][a-zA-Z .]*$/

  const result = values.every(
    (item) =>
      regs.test(item) &&
      consecutive.test(item) &&
      !notContain.some((str) => item.toLowerCase() === str) &&
      consecutiveKeyboard(item),
  )
  return result
}

const consecutiveKeyboard = (word: string) => {
  // Condition 1: Cannot contain 4 or more consecutive letters on the keyboard
  const keyboardLayout = ['qwertyuiop', 'asdfghjkl', 'zxcvbnm']
  for (let i = 0; i < keyboardLayout.length; i++) {
    const row = keyboardLayout[i]
    for (let j = 0; j < row.length - 3; j++) {
      const slice = row.slice(j, j + 4)
      if (
        word.includes(slice) ||
        word.includes(slice.split('').reverse().join('')) ||
        word.includes(slice.toLowerCase())
      ) {
        return false
      }
    }
  }
  // Condition 2: It cannot contain 4 or more consecutive letters from a to z.
  for (let i = 0; i <= 26 - 4; i++) {
    const slice =
      String.fromCharCode(97 + i) +
      String.fromCharCode(97 + i + 1) +
      String.fromCharCode(97 + i + 2) +
      String.fromCharCode(97 + i + 3)
    if (
      word.includes(slice) ||
      word.includes(slice.split('').reverse().join('')) ||
      word.includes(slice.toLowerCase())
    ) {
      return false
    }
  }
  return true
}

/**
 *
 * @param arr
 * @param key
 */
export const filterSupple = (arr: any[], key: 'MANDATORY' | 'OPTIONAL' = 'MANDATORY') => {
  return arr.filter((item) => item.requirementStatus === key)
}

/**
 *  TCASH  OTTP
 */
export function filterVirtualAccount(bankCode: string | null) {
  if ((bankCode === ChannelEnum.TCASH || bankCode === ChannelEnum.OTTO) && !isOtherChannel()) return false
  return true
}

/**
 * json gzip
 */
export function toGzipObj(obj: any) {
  return pako.gzip(JSON.stringify(obj))
}
