import { IOperationConfig } from '@base/interface/common'
import { getLanguage } from './utils'
import { commonBridgeFn } from '@base/lib/common-bridge'
import { getItem, setItem } from './storage-handler'
import { getLocalChannel } from '@base/lib/checkChannel'
//
export function listenKeybordStatus($el: any) {
  // android ：android
  let originHeight = document.documentElement.clientHeight || document.body.clientHeight

  window.addEventListener(
    'resize',
    function () {
      const resizeHeight = document.documentElement.clientHeight || document.body.clientHeight
      if (originHeight < resizeHeight) {
        console.log('android ')
        $el.style.display = 'block'
      } else {
        console.log('android ')
        $el.style.display = 'none'
      }
      originHeight = resizeHeight
    },
    false,
  )
}

export function trimValue(value: any, gap = ' ') {
  value = typeof value === 'undefined' ? '' : value
  const reg = new RegExp(gap, 'g')
  value = value.toString().replace(reg, '')
  return value
}

export const mascotIsShow = (data: any[]) => {
  const mascotIsClosedbannerIdsJSON = localStorage.getItem('mascotIsClosedbannerIds')
  let newData: any[] = []
  if (mascotIsClosedbannerIdsJSON) {
    // [1,2,3]
    const localData = JSON.parse(mascotIsClosedbannerIdsJSON)
    const show = data?.filter((item: IOperationConfig) => {
      // [3,4]
      const result = localData.filter((itm: string) => itm === item.bannerId)
      return result.length === 0
    })
    newData = show.map((item: IOperationConfig) => {
      return { ...item, mascotIsShow: true }
    })
  } else {
    newData = data.map((item: IOperationConfig) => {
      return { ...item, mascotIsShow: true }
    })
  }
  return newData
}

export const verifyPhone = (val: string) => {
  const reg = /^(\+62|62)/
  return val?.replace(reg, '')
}

//
export const updateApp = (url: string) => {
  return window.vue.$alert(
    {
      buttonText: getLanguage() === 'id' ? 'Perbarui Sekarang' : 'Update Now',
      title: getLanguage() === 'id' ? 'Perlu Pembaruan' : 'Require to Update',
      message:
        getLanguage() === 'id'
          ? 'Versi baru aplikasi tersedia, silakan klik di bawah ini untuk memperbarui ke versi terbaru'
          : 'A new version of app is available, please click below to update to the latest version',
      maskClosable: false,
    },
    // () => window.dkBridge?.openBrowser?.(url),
    () => commonBridgeFn('openBrowser', false, { url }),
  )
}

export const getWebHost = (env: string) => {
  if (env === 'development') {
    return 'https://h1.kreditpintar.net/'
  }
  if (env === 'staging') {
    return 'https://h.kreditpintar.net/'
  }
  return 'https://h.kreditpintar.com/'
}

export const routerHandle = () => {
  const { redirect } = window.vue.$route.query
  if (redirect?.includes('/activity/')) {
    window.location.href = redirect as string
    return
  }
  if (redirect && !redirect.includes('code-step')) {
    window.vue.$router.replace(redirect as string)
  } else {
    const channel = getLocalChannel()
    window.vue.$router.push({
      path: `/${channel}/home`,
    })
  }
}

export const loadJs = (src: string, timeout = 10000) => {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = src
    document.body.appendChild(script)
    let isLoadJs = false

    addTime(10000, () => {
      if (!isLoadJs) {
        isLoadJs = true
        reject('Time out')
      }
    })

    if (isLoadJs) return
    script.onload = () => {
      if (isLoadJs) {
        return reject('Time out')
      }
      isLoadJs = true
      resolve()
    }
    script.onerror = (e) => {
      reject(e)
    }
  })
}

// 添加定时器
export const addTime = (time: number, callBack: () => void) => {
  let timer: any = null
  if (timer) {
    clearInter(timer)
  }
  timer = setInterval(() => {
    callBack()
  }, time || 5000)
}

// 清除定时器
export const clearInter = (timer: any) => {
  clearInterval(timer)
}
