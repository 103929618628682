






















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Action } from '@pintar/id-track-event'
import { IAreaParams } from '@base/interface/common'
import { getKtpAreaList } from '@base/api/common'

@Component({})
export default class PcrvFormItem extends Vue {
  @Prop({ type: [String, Number], default: '' }) title!: string | number
  @Prop({ type: [String, Number], default: '' }) placeholder!: string | number
  @Prop({ type: [String, Number], default: '' }) error!: string | number
  @Prop({ type: Object, default: '' }) model!: { [key: string]: string }

  private popup!: any

  innerTxt: any = {}

  isPopupShow = true

  @Watch('model', { immediate: true, deep: true })
  async onModelChange(val: { [key: string]: string }) {
    let index = 5
    const hasValue = Object.keys(val).every((key) => val[key])
    for (let i = 0; i < this.areaParams.length; i++) {
      if (!val[this.areaParams[i]]) {
        index = i
        break
      }
    }
    if (val && index !== 5) {
      const arr: string[] = []
      this.areaParams.forEach((itm, idx) => {
        if (idx < index) {
          arr.push(itm)
        }
      })
      this.innerTxt = {}
      if (arr.length) {
        arr.forEach((key) => {
          this.$set(this.innerTxt, key, val[key])
        })
      } else {
        this.$set(this.innerTxt, 'province', '')
      }
    }
    if (val && hasValue) {
      this.innerTxt = val
    }
    this.$emit('pcrvChange')
  }

  fetchNextArea = async (params?: IAreaParams) => {
    try {
      const { data } = await getKtpAreaList(params)
      return data
    } catch (error: any) {
      this.$handleError(error)
    }
  }

  clickHandler() {
    this.$refs.mdPcrvInput && (this.$refs.mdPcrvInput as any).blur()
    this.showPcrv()
    this.$emit('click')
  }
  hideHandle() {
    this.popup.$updateProps({
      isPopupShow: false,
      value: [{ province: '' }],
    })
    this.$emit('hide')
  }

  confirmHandler(json: any) {
    const a = JSON.parse(JSON.stringify(json))
    const b = { province: a[0].province, city: a[1].city, district: a[2].district, village: a[3].village }
    this.innerTxt = b
    this.$emit('input', this.innerTxt)
    this.hideHandle()
  }

  areaParams: string[] = ['province', 'city', 'district', 'village']

  formatAreaName(name: string) {
    return name?.slice(0, 1).toUpperCase() + name?.slice(1).toLowerCase()
  }

  showPcrv() {
    // if (!this.popup) {
    const a: any = []
    Object.keys(this.innerTxt).forEach((key) => {
      a.push({ [key]: this.innerTxt[key] })
      this.$eventTrack({
        action: Action.ResidentialSelectResult,
        extra: {
          residentialSelectResult: key === 'district' ? 'Region' : this.formatAreaName(key),
          value: this.innerTxt[key],
        },
      })
    })
    this.popup = this.$createPopupPcrv({
      $props: {
        isPopupShow: true,
        title: this.title,
        value: a,
      },
      $events: {
        submit: this.confirmHandler,
        close: this.hideHandle,
      },
    })
    // }
  }
}
