import VeeValidate, { Validator } from 'vee-validate'
const id = require('vee-validate/dist/locale/id')
import { businessAddressValidator } from '@base/lib/utils'
import { invalidNames } from '@base/lib/constant'

export default (Vue) => {
  Vue.use(VeeValidate, {
    locale: id,
  })

  Validator.localize({ id })
  Validator.localize({
    en: {
      messages: {
        required: 'The field is required',
        confirmed: "These account numbers don't match",
      },
    },
    id: {
      messages: {
        required: 'Kolom ini wajib diisi',
        confirmed: 'Nomor rekening tidak sesuai',
      },
    },
  })

  const includesSting = (value, array) => {
    return array.some((str) => str === value)
  }

  const validateRules = {
    requiredpcrv: {
      messages: {
        en: (field) => {
          return 'The field is required'
        },
        id: (field) => {
          return 'Kolom ini wajib diisi'
        },
      },
      validate(value) {
        if (value) {
          return Object.keys(value).every((key) => value[key])
        }
        return false
      },
    },
    nik: {
      messages: {
        en: (field) => {
          return 'Invalid KTP no.'
        },
        id: (field) => {
          return 'KTP no. tidak valid'
        },
      },
      validate(value) {
        return /^\d{16}$/.test(value)
      },
    },
    hasAlphabetSpacesPoints: {
      messages: {
        en: (field) => {
          return 'Please input only alphabet and symbol (.)'
        },
        id: (field) => {
          return 'Silakan masukan huruf dan simbol (.)'
        },
      },
      validate(value) {
        return /^[a-zA-Z.\s]+$/.test(value)
      },
    },
    nikName: {
      messages: {
        en: (field) => {
          return 'Please input only alphabet and symbol (.)'
        },
        id: (field) => {
          return 'Silakan masukan huruf dan simbol (.)'
        },
      },
      validate(value) {
        return /\D+$/.test(value)
      },
    },
    mobile: {
      messages: {
        en: (field) => {
          return 'Must start with 08'
        },
        id: (field) => {
          return 'harus diawali dengan 08'
        },
      },
      validate(value) {
        return /^0?8/.test(value)
      },
    },
    telPhone: {
      messages: {
        en: (field) => {
          return 'Must start with 08/021'
        },
        id: (field) => {
          return 'harus diawali dengan 08/021'
        },
      },
      validate(value) {
        return /^0?(8|21)/.test(value)
      },
    },
    verifyCode4: {
      messages: {
        en: (field) => {
          return 'Verification code must be 4-digit numbers'
        },
        id: (field) => {
          return 'Kode verifikasi harus 4 angka'
        },
      },
      validate(value) {
        return /^.{4}$/.test(value)
      },
    },
    verifyCode: {
      messages: {
        en: (field) => {
          return 'Verification code must be 6 characters'
        },
        id: (field) => {
          return 'Kode verifikasi harus terdiri dari 6 karakter'
        },
      },
      validate(value) {
        return /^\d{6}$/.test(value)
      },
    },
    min3max50: {
      messages: {
        en: (field) => {
          return "Mother's maiden name must be 3-50 characters"
          //
        },
        id: (field) => {
          return 'Nama gadis ibu harus terdiri dari 3-50 karakter'
          //
        },
      },
      validate(value) {
        //  &  & & (.，)
        // ：
        // 1.
        // 2.  space > 2，
        const name = value.toLowerCase().trim().replace(/\./g, '').replace(/\s+/g, ' ')
        if (name.length >= 3 && name.length <= 50) return true

        return false
      },
    },
    motherMaiden: {
      messages: {
        en: (field) => {
          return "Please provide your real mother's maiden name"
        },
        id: (field) => {
          return 'Mohon tuliskan nama gadis ibu Anda dengan benar'
        },
      },
      validate(value, args) {
        // motherMaiden：args[0]
        const name = value.toLowerCase().trim().replace(/\./g, '').replace(/\s+/g, ' ')
        if (name === args[0].toLowerCase().trim()) return false

        if(includesSting(name, invalidNames)) {
          const names = name.split(' ')
          if(names.length <= 1) return false
          if(names.length === 2 && includesSting(name, ['orang tua'])) return false
        }

        return true
      },
    },
    password: {
      messages: {
        en: (field) => {
          return 'Password must be at least 8 characters'
        },
        id: (field) => {
          return 'Kata Sandi paling sedikit harus 8 karakter'
        },
      },
      validate(value) {
        return /^.{8,}$/.test(value)
      },
    },
    phoneLength: {
      messages: {
        en: (field) => {
          return 'Phone number must be 9 - 13 digits'
        },
        id: (field) => {
          return 'Nomor telepon harus 9 - 13 digit'
        },
      },
      validate(value) {
        return value.length <= 13 && value.length >= 9
      },
    },
    verifyPayday: {
      messages: {
        en: (field) => {
          return 'Payday should between 1 and 31'
        },
        id: (field) => {
          return 'Tanggal gajian antara 1 dan 31'
        },
      },
      validate(value) {
        return /^([1-9]|[1-2]\d|3[0-1])$/.test(value)
      },
    },
    sameName: {
      messages: {
        en: (field) => {
          return "E-Con's name mustn't be the same as yours"
        },
        id: (field) => {
          return 'Nama Kontak Darurat tidak boleh sama dengan Anda'
        },
      },
      validate(value, args) {
        if (!value || args.length === 0 || !args[0]) {
          return true
        }
        return args[0] !== value
      },
    },
    // sameMoreName
    sameMoreName: {
      messages: {
        en: (field) => {
          return "E-Con't name cannot be the same as yours or another e-con's"
        },
        id: (field) => {
          return 'Nama kontak darurat tidak boleh sama dengan nama Anda atau kontak darurat lainnya'
        },
      },
      validate(value, args) {
        if (!value || args.length === 0 || !args[0]) {
          return true
        }
        return args[0] !== value && args[1] !== value
      },
    },
    sameMobile: {
      messages: {
        en: (field) => {
          return "E-Con's mobile mustn't be the same as yours"
        },
        id: (field) => {
          return 'Nomor Kontak Darurat tidak boleh sama dengan Anda'
        },
      },
      validate(value, args) {
        if (!value || args.length === 0 || !args[0]) {
          return true
        }
        return args[0].replace(/^0+/, '') !== value.replace(/^0+/, '')
      },
    },
    sameMoreMobile: {
      messages: {
        en: (field) => {
          return 'E-Con’t mobile cannot be the same as yours or another e-con’s'
        },
        id: (field) => {
          return 'Nomor kontak darurat tidak boleh sama dengan nomor Anda atau kontak darurat lainnya'
        },
      },
      validate(value, args) {
        if (!value || args.length === 0 || !args[0]) {
          return true
        }
        return (
          args[0].replace(/^0+/, '') !== value.replace(/^0+/, '') &&
          args[1].replace(/^0+/, '') !== value.replace(/^0+/, '')
        )
      },
    },
    noSpecialNum: {
      messages: {
        en: (field) => {
          return 'Invalid mobile'
        },
        id: (field) => {
          return 'Nomor kontak salah'
        },
      },
      validate(value) {
        return !/^(084|84)/.test(value)
      },
    },
    notEmoji: {
      messages: {
        'en-us': (field) => {
          return 'Name format error please correct'
        },
        id: (field) => {
          return 'Format nama error silakan perbaiki'
        },
      },
      validate(value) {
        return !value.match(/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g)
      },
    },
    notNumber: {
      messages: {
        en: (field) => {
          return 'Only characters and punctuation is allowed '
        },
        id: (field) => {
          return 'Hanya huruf dan tanda baca yang diperbolehkan'
        },
      },
      validate(value) {
        return !/\d/.test(value)
      },
    },
    address: {
      messages: {
        en: (field) => {
          return 'Input your detailed address including street name and house no.'
        },
        id: (field) => {
          return 'Masukkan alamat detil Anda termasuk nama jalan dan nomor rumah'
        },
      },
      validate(value) {
        return !/^\d+$/.test(value)
      },
    },
    empolyerName: {
      messages: {
        en: (field) => {
          return 'Employer name must contain alphabet'
        },
        id: (field) => {
          return 'Nama perusahaan harus huruf'
        },
      },
      validate(value) {
        return /[A-Za-z]{1,}/g.test(value)
      },
    },
    hasAlohabet: {
      messages: {
        en: (field) => {
          return 'Must contain letters'
        },
        id: (field) => {
          return 'Harus mengandung huruf'
        },
      },
      validate(value) {
        return /[A-Za-z]{1,}/g.test(value)
      },
    },
    positiveInteger: {
      messages: {
        en: (field) => {
          return 'Invalid'
        },
        id: (field) => {
          return 'Tidak valid'
        },
      },
      validate(value) {
        return /^[1-9]+[0-9]*$/g.test(value)
      },
    },
    langthMinThree: {
      messages: {
        en: (field) => {
          return 'The employer name field must be at least 3 characters'
        },
        id: (field) => {
          return 'Nama perusahaan minimal mengandung 3 karakter'
        },
      },
      validate(value) {
        return value.length >= 3
      },
    },
    businessMinThree: {
      messages: {
        en: (field) => {
          return 'The business address field must be at least 3 characters'
        },
        id: (field) => {
          return 'Alamat kantor minimal mengandung 3 karakter'
        },
      },
      validate(value) {
        return value.length >= 3
      },
    },
    businessAddress: {
      messages: {
        en: (field) => {
          return 'Invalid business address'
        },
        id: (field) => {
          return 'Alamat kantor tidak valid'
        },
      },
      validate(value) {
        return /^[a-zA-Z.\s/0-9]*$/.test(value)
      },
    },
    companyName: {
      messages: {
        en: (field) => {
          return 'Invalid employer name'
        },
        id: (field) => {
          return 'Nama perusahaan tidak valid'
        },
      },
      validate(value) {
        return businessAddressValidator(value)
      },
    },
    email: {
      messages: {
        en: (field) => {
          return 'The email field must be a valid email'
        },
        id: (field) => {
          return 'Gunakan alamat email yang benar'
        },
      },
      validate(value) {
        return value
      },
    },
    threeConsecutive: {
      messages: {
        en: (field) => {
          return 'The name cannot have three repeated characters'
        },
        id: (field) => {
          return 'Nama tidak boleh memiliki tiga karakter yang berulang'
        },
      },
      validate(value) {
        return !/(.)\1\1/i.test(value)
      },
    },
  }

  // custom validator
  Object.keys(validateRules).forEach((key) => {
    Validator.extend(key, validateRules[key].validate)

    // merge the validator messages
    Object.keys(validateRules[key].messages).forEach((locale) => {
      Validator.localize({
        [locale]: {
          messages: {
            [key]: validateRules[key].messages[locale],
          },
        },
      })
    })
  })
}
