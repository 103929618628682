import Router, { RawLocation } from 'vue-router'

const originalPush: any = Router.prototype.push
const originalReplace: any = Router.prototype.replace

Router.prototype.push = function push(location: RawLocation) {
  return originalPush.call(this, location).catch((err: any) => {
    // if (Router.isNavigationFailure(err, NavigationFailureType.duplicated)) {
    //   Sentry.captureMessage(`${err}`, Sentry.Severity.Info)
    //   return err
    // }
    // return Promise.reject(err)
  })
}

Router.prototype.replace = function replace(location: RawLocation) {
  return originalReplace.call(this, location).catch((err: any) => {
    // if (Router.isNavigationFailure(err, NavigationFailureType.duplicated)) {
    //   Sentry.captureMessage(`${err}`, Sentry.Severity.Info)
    //   return err
    // }
    // return Promise.reject(err)
  })
}

export default Router
