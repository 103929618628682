<template>
  <md-popup
    v-model="isPopupShow"
    :class="{ 'popup-pcrv': true, 'popup-pcrv-more': steps.length > 1 }"
    position="bottom"
  >
    <div class="container">
      <div class="step">
        <md-steps direction="vertical" :steps="steps" :current="steps.length - 1">
          <template slot="reached">
            <div class="reached"></div>
          </template>
          <template slot="current" slot-scope="{ index }">
            <div
              v-if="index === steps.length - 1 && steps[index][Object.keys(steps[index])[0]]"
              class="last-current"
            ></div>
            <div v-else-if="steps.length !== 1" class="current"></div>
            <div v-else class="first-current"></div>
          </template>
          <div slot="content" slot-scope="{ index, step }" class="custom-content" @click="onStep(step, index)">
            <!-- v-text="(areaParams[index]) === 'district' ? 'Region' : formatAreaName(areaParams[steps.length - 1])" -->
            <p v-if="!step[Object.keys(step)[0]]" class="text" v-text="$t(areaParams[steps.length - 1])"></p>
            <p v-else v-text="$t(step[Object.keys(step)[0]])"></p>
          </div>
        </md-steps>
      </div>
      <div class="content">
        <div v-if="loading" class="icon_loading"></div>
        <div
          v-for="(item, index) in areaData"
          v-else
          :key="index"
          :class="{ item: true, active: currenrArea === `${currentKey()}_${item.name}` }"
          @click="selectArea(item)"
        >
          <span v-if="item.name">{{ item.name }}</span>
          <md-icon v-if="currenrArea === `${currentKey()}_${item.name}`" name="right" size="md" color="#1CC812" />
        </div>
      </div>
    </div>
  </md-popup>
</template>
<script>
import { getKtpAreaList } from '@base/api/common'
import { Action } from '@pintar/id-track-event'

export default {
  name: 'PopupPcrv',
  props: {
    title: { type: [String, Number], default: '' },
    isPopupShow: { type: Boolean, default: false },
    value: { type: () => {}, default: [{ province: '' }] },
  },
  data() {
    return {
      loading: false,
      areaParams: ['province', 'city', 'district', 'village'],
      steps: [{ province: '' }],

      areaData: [{ name: '' }],
      currenrArea: '',
    }
  },
  watch: {
    value: {
      async handler(val) {
        this.watchValue(val)
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async watchValue(value) {
      const currentSeps = JSON.parse(JSON.stringify(value))
      this.steps = []
      this.areaParams.forEach((item) => {
        currentSeps.forEach((itm) => {
          if (itm[item]) {
            this.steps.push({ [item]: itm[item] })
          }
        })
      })
      if (this.steps.length < this.areaParams.length) {
        this.steps.push({ [this.areaParams[this.steps.length]]: '' })
      }
      this.currenrArea = `${this.currentKey()}_${this.currentVal()}`
      await this.fetchNextArea(this.createParams())
    },
    createParams() {
      const params = {}
      this.steps.forEach((item) => {
        Object.keys(item).forEach((itm) => {
          if (item[itm] && item[itm] !== '') {
            params[itm] = item[itm]
          }
        })
      })
      return params
    },

    currentKey() {
      let key = ''
      this.steps.forEach((item) => {
        Object.keys(item).forEach((itm) => {
          if (item[itm] && item[itm] === '') {
            key = itm
          }
        })
      })
      return key
    },

    currentVal() {
      let value = ''
      this.steps.forEach((item) => {
        Object.keys(item).forEach((itm) => {
          if (item[itm] && item[itm] === '') {
            value = item[itm]
          }
        })
      })
      return value
    },

    formatAreaName(name) {
      return name?.slice(0, 1).toUpperCase() + name?.slice(1).toLowerCase()
    },

    // async onStep(step: {[key: string]: string}, index: number) {
    async onStep(step, index) {
      this.currenrArea = ''
      this.steps.splice(index, this.steps.length - 1 - index)
      const key = this.areaParams[this.steps.length - 1]
      this.$set(this.steps, index, { [key]: '' })
      await this.fetchNextArea(this.createParams())
    },

    // async fetchNextArea(params?: IAreaParams) {
    async fetchNextArea(params) {
      try {
        this.loading = true
        this.areaData = []
        const { data } = await getKtpAreaList(params)
        this.areaData = data
          .map((item) => ({
            name: item,
          }))
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1
            } else if (a.name > b.name) {
              return 1
            }
            return 0
          })
      } catch (error) {
        this.$handleError(error)
        throw error
      } finally {
        this.loading = false
      }
    },

    // async selectArea(item: IArea) {
    async selectArea(item) {
      if (this.steps.length > this.areaParams.length) {
        return
      }
      const key = this.areaParams[this.steps.length - 1]
      this.currenrArea = `${key}_${item.name}`
      this.$eventTrack({
        action: Action.ResidentialSelectResult,
        extra: {
          residentialSelectResult: key === 'district' ? 'Region' : key,
          value: item.name,
        },
      })
      this.$set(this.steps, this.steps.length - 1, { [key]: item.name })
      if (this.steps.length < this.areaParams.length) {
        this.steps.push({ [this.areaParams[this.steps.length]]: '' })
      } else if (this.steps.length === this.areaParams.length) {
        this.$emit('submit', this.steps)
      }

      await this.fetchNextArea(this.createParams())
    },
  },
  i18n: {
    messages: {
      'en-us': {
        province: 'Province',
        city: 'City',
        district: 'Region',
        village: 'Village',
      },
      id: {
        province: 'Provinsi',
        city: 'Kota',
        district: 'Kecamatan',
        village: 'Kel/Desa',
      },
    },
  },
  // @Prop({ type: [String, Number], default: '' }) title!: string | number
  // @Prop({ type: Boolean, default: false }) isPopupShow!: boolean
  // @Prop({ type: Array, default: [{ 'province': '' }] }) value!: any

  // loading = false

  // areaParams: string[] = [
  //   'province',
  //   'city',
  //   'district',
  //   'village',
  // ]

  // steps: {[key: string]: string}[] = [{ province: '' }]

  // areaData: { name: string }[] = []
  // currenrArea = ''

  // async created() {
  //   this.steps = JSON.parse(JSON.stringify(this.value))
  //   this.areaData = await this.fetchNextArea(this.createParams())
  // }

  // createParams() {
  //   const params: any= {}
  //   this.steps.forEach(item => {
  //     Object.keys(item).forEach(itm => {
  //       if (item[itm] && item[itm] !== '') {
  //         params[itm] = item[itm]
  //       }
  //     })
  //   })
  //   return params
  // }

  // formatAreaName(name: string) {
  //   return name?.slice(0,1).toUpperCase() + name?.slice(1).toLowerCase()
  // }

  // async onStep(step: {[key: string]: string}, index: number) {
  //   this.steps.splice(index, this.steps.length - 1 - index)
  //   const key = this.areaParams[this.steps.length - 1]
  //   this.$set(this.steps, index, { [key]: '' })
  //   this.areaData = await this.fetchNextArea(this.createParams())
  // }

  // async fetchNextArea(params?: IAreaParams) {
  //   try {
  //     this.loading = true
  //     // this.$loading.show()
  //     const { data } = await getKtpAreaList(params)
  //     return data.map(item => ({
  //       name: item,
  //     })).sort((a, b) => {
  //       if (a.name < b.name) {
  //         return -1
  //       } else if (a.name > b.name) {
  //         return 1
  //       }
  //       return 0
  //     })
  //   } catch (error: any) {
  //     this.$handleError(error)
  //     throw error
  //   } finally {
  //     this.loading = false
  //     // this.$loading.hide()
  //   }
  // }

  // async selectArea(item: IArea) {
  //   if (this.steps.length > this.areaParams.length) {
  //     return
  //   }
  //   this.currenrArea = item.name
  //   const key = this.areaParams[this.steps.length - 1]
  //   this.$set(this.steps, this.steps.length - 1, { [key]: item.name })
  //   if (this.steps.length < this.areaParams.length) {
  //     this.steps.push({ [this.areaParams[this.steps.length]]: '' })
  //   } else if (this.steps.length === this.areaParams.length) {
  //     this.$emit('submit', this.steps)
  //   }

  //   this.areaData = await this.fetchNextArea(this.createParams())
  // }
}
</script>
<style lang="stylus" scoped>
.popup-pcrv
  .container
    background-color #fff
    .step
      max-height 272px
      padding 32px
      border-bottom 1px solid rgba(235, 235, 235, 0.7)
      font-family Inter
      font-size 28px
      >>> .md-steps
        padding 0
        .step-wrapper
          align-items center
          margin 0
          min-width 0px
          min-height 0px
          left -40px
        .icon-wrapper
          min-width 16px
          min-height 16px
          .step-node-default
            min-width 0px
            min-height 0px
          .step-node-default-icon
            background-color #fff
            width 12px !important
            height 12px !important
            border 2px solid #1CC812
        .text-wrapper
          padding-left 0px
          left 40px
        .bar.vertical-bar
          width 2px
          background-color #1CC812
          left 8px
      .text
        color #1CC812
      .first-current
        width 0px
        height 0px
      .current
        width 12px
        height 12px
        border-radius 50%
        border 2px solid #1CC812
      .reached, .last-current
        color #000000
        width 16px
        height 16px
        border-radius 50%
        background-color #1CC812
    .content
      height 538px
      padding 0 32px
      overflow-y auto
      .item
        border-bottom 1px solid rgba(235, 235, 235, 0.5)
        color #9E9E9E
        font-size 28px
        padding 32px 0
        line-height 32px
      .active
        color #1CC812
        display flex
        justify-content space-between
        align-items center
      .icon_loading
        background-image url('~@/assets/images/loading_rotate.png')
        width 48px
        height 48px
        background-size cover
        background-repeat no-repeat
        margin 30% auto
        animation rotate 0.8s linear infinite
.popup-pcrv-more
  >>>.md-steps
    .bar.vertical-bar
      height 63px !important
    .step-wrapper
      left 0px !important

@keyframes rotate
  0%
    transform rotate(0deg)
  100%
    transform rotate(360deg)
</style>
