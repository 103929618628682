import Loading from './Loading.vue'

let loading: any = null

export default {
  show: () => {
    if (loading) {
      loading.$updateProps({
        isShow: true,
      })
      return
    }
    loading = (Loading as any).$create({
      $props: {
        isShow: true,
      },
    })
  },
  hide: () => {
    if (loading) {
      loading.$updateProps({ isShow: false })
    }
  },
}
