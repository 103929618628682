/**
 * http://www.dirk.wang/2019/08/16/canvas%E5%8E%8B%E7%BC%A9jpg%E4%B8%A2%E5%A4%B1exif/
 */

//  base64  ArrayBuffer
export function base64ToArrayBuffer(base64: any, contentType?: any) {
  // 'data:image/jpeg;base64,...' => 'image/jpeg'
  contentType = contentType || base64.match(/^data\:([^\;]+)\;base64,/im)?.[1] || ''
  base64 = base64.replace(/^data\:([^\;]+)\;base64,/gim, '')
  // btoa  binary to ascii， binary  ascii ， Base64
  // atob  ascii to binary， ascii  binary
  const binary = atob(base64)
  const len = binary.length
  const buffer = new ArrayBuffer(len)
  const view = new Uint8Array(buffer)
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i)
  }
  return buffer
}

//  0xFFE0~0xFFEF
export function getSegments(arrayBuffer: Iterable<number>) {
  let head = 0
  const segments: any[] = []
  let length, endPoint, seg
  const arr = [].slice.call(new Uint8Array(arrayBuffer), 0)

  // eslint-disable-next-line no-constant-condition
  while (true) {
    // SOS(Start of Scan,  0xff 0xda )
    //  SOS ，， break
    if (arr[head] === 0xff && arr[head + 1] === 0xda) {
      break
    }

    // SOI(Start of Image) JPG ， 0xff 0xd8
    if (arr[head] === 0xff && arr[head + 1] === 0xd8) {
      head += 2
    } else {
      //
      //
      length = arr[head + 2] * 256 + arr[head + 3] //
      endPoint = head + length + 2 //
      //  0xff ，
      seg = arr.slice(head, endPoint)
      head = endPoint
      // push
      segments.push(seg)
    }
    if (head > arr.length) {
      break
    }
  }
  // console.warn('', segments)
  return getEXIF(segments)
}
//  &  exif
function getEXIF(segments: any) {
  if (!segments.length) {
    return []
  }
  let seg: any[] = []
  for (let x = 0; x < segments.length; x++) {
    const s = segments[x]
    // 0xff 0xe1  exif ( app1)
    if (s[0] === 0xff && s[1] === 0xe1) {
      // app1 exif 0xff 0xe1
      seg = seg.concat(s)
    }
  }
  return seg
}

//  Exif
export function insertEXIF(resizedImg: Iterable<number>, exifArr: ConcatArray<number>) {
  const arr = [].slice.call(new Uint8Array(resizedImg), 0)
  //  JPEG
  if (arr[2] !== 0xff || arr[3] !== 0xe0) {
    return resizedImg
  }
  const app0_length = arr[4] * 256 + arr[5] //

  //  SOI + EXIF +  APP0
  const newImg = [0xff, 0xd8].concat(exifArr, arr.slice(4 + app0_length))
  return new Uint8Array(newImg)
}

/**
 * 根据 base64 转成 blob
 * @param base64 base64 字符串
 * @returns
 */
export const dataURLtoBlob = (base64: string) => {
  base64 = base64.replace(/^data:([^;]+);base64,/gim, '')
  const bstr = atob(base64)
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: 'image/jpg' })
}

//blob 转 base64
export const blobToDataURI = (blob: Blob) => {
  return new Promise<string>((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onload = function (e: any) {
      resolve(e.target.result)
    }
  })
}

export const dataType = (data: any) => {
  return Object.prototype.toString.call(data).slice(8, -1).toLowerCase()
}
