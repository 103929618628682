










import { Component, Vue } from 'vue-property-decorator'
import { login } from '@/api/common'
import { State, Action, Mutation } from 'vuex-class'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/id'
import { ApplyModule } from '@base/store/apply'
import FloatButton from '@/views/test-utils/common/FloatButton.vue'
import { Action as EventAction, PageName, dkBridgeMock } from '@pintar/id-track-event'
import { goBack, isApp } from '@base/lib/utils'
import { commonBridgeFn, registerGoback, isIosApp } from '@base/lib/common-bridge'
import { removeItem } from '@base/lib/storage-handler'

@Component({
  components: {
    FloatButton,
  },
})
export default class App extends Vue {
  @State('app') appModule!: any
  @Action('updateUserType', { namespace: 'user' }) updateUserType!: () => void
  @Mutation('resetLoanParams') resetLoanParams!: Function
  @Mutation('updateShowUtils') updateShowUtils!: Function

  routerList = [
    '/about-us',
    '/home/agreement/extension',
    '/risk',
    '/term-service',
    '/login/faq',
    '/download-app-apk',
    '/security/rejected',
    '/download-app',
    '/push/term-and-condition',
    '/push/jago-term-and-condition',
  ]

  goBackClick = false
  isIos = false

  get showUtils() {
    return this.appModule.showUtils
  }

  initDkBridgeMock() {
    if (window.dkBridge) return
    if (process.env.VUE_APP_MOCK_BRIDGE === 'Yes') {
      window.dkBridge = dkBridgeMock
      console.log('Add mock bridge')
    } else {
      console.error('Only support mobile app')
    }
  }

  created() {
    const lang: {
      [k: string]: string
    } = {
      'en-us': 'en',
      id: 'id',
    }
    //  app  delayNotify
    const routerName = window.location.pathname
    if (!this.routerList.includes(routerName)) {
      ApplyModule.fetchIfDelayNotification()
      this.updateUserType()
    }
    removeItem('livenessFailedTime')
    console.log('$route:', routerName)
    //todo
    if (isApp()) {
      // window.dkBridge?.enableRefresh?.(false)
      commonBridgeFn('enableRefresh', false, { enable: false })
      const language = commonBridgeFn('getLocale', false) === 'en-us' ? 'en-us' : 'id'
      this.$root.$i18n.locale = language
      this.$validator.localize(lang[language])
      dayjs.locale(language === 'id' ? 'id' : 'en')
      //  app
      // window.dkBridge?.loadCompleted?.()

      commonBridgeFn('loadCompleted', false)
      if (isIosApp()) {
        this.isIos = true
      }
      // register goback
      registerGoback()
      // window.dkBridge.goBack = () => {
      //   if (this.$route.meta?.root) {
      //     this.$eventTrack({
      //       action: EventAction.PageEvent,
      //       target: Number(PageName[this.$route.meta?.backTo]),
      //     })
      //     this.finishH5()
      //   } else if (this.$route.meta?.back === 'mandatoryBranch') {
      //     return
      //   } else if (this.$route.meta?.back === 'Me') {
      //     window.dkBridge?.finish?.()
      //   } else if (window.history.length === 1 || !window.history.state) {
      //     this.finishH5()
      //   } else if (this.$route.meta?.back === 'BankList') {
      //     this.$router.replace({ name: 'BankList' })
      //   } else if (this.$route.meta?.back === 'PaymentMethod') {
      //     removeSession('dueDate')
      //     goBack()
      //   } else {
      //     goBack()
      //   }
      // }
    } else {
      this.initDkBridgeMock()
    }
  }

  // finishH5() {
  //   if (this.$route.meta?.backTo === 'Home' && window.dkBridge?.launchHomePage) {
  //     window.dkBridge.launchHomePage()
  //   } else {
  //     window.dkBridge?.finish?.()
  //   }
  // }

  async mounted() {
    //
    if (process.env.VUE_APP_ENV === 'development') {
      try {
        await login()
      } catch (error: any) {
        this.$handleError(error)
      }
    }
    this.resetLoanParams()
  }
}
