import ajax from '@base/lib/ajax'

/**
 * 
 */
export const getVccBankInfo = () => ajax.get('/user/bank-info')

/**
 * 
 */
export const getCreditLimit = () => {
  return ajax.get('/user-credit/limit/v2')
}

/**
 * 
 *  NKP， OKP
 *
 * UserTypeNull = 0
 * NEW = 1
 * OKP = 2
 * NKP = 3
 * BOTH = 4
 * EFFORTLESS_LOAN = 5
 */
export const getUserType = () => ajax.get('/user/type')

// source: 'PDL' / 'VCC'
export const getUserImageByName = (params: { fileName: string; source?: string }) =>
  ajax.get<string>('/user/image', { params })

// KTPOKP
export const uploadKtpInfo = (params: {
  idNumber: string
  fullName: string
  idPhoto: string
  birthPlace: string
  birthDate: string
  address: string
  rt: string
  rw: string
  province: string
  city: string
  district: string
  village: string
}) => ajax.post('/user/ktp-info/v3', params)

export const uploadPhotoInfo = (params: { type: string; photo: string }) => {
  return ajax.post(`/user/photo/${params.type}/v2`, params)
}

/**
 * 
 */
export const getSupplementList = () => ajax.get('/supplemental-material/should-resubmit')

/**
 * NIK
 */
export const needVerifyNIK = (params: any) =>
  ajax.get('/loan/allocate-btpn', {
    params,
  })

/**
 * 
 * @param params
 */
export const uploadSupplementPhoto = (params: { filename?: string; type?: string }) =>
  ajax.post('/supplement-material', params)

/**
 * HR
 */
export const uploadHrInfo = (params: any) => ajax.post('/supplemental-material/hr-info', params)

/**
 * 
 * @param params
 */
export const uploadPaymentReceipt = (params: {
  accountNumber: string
  bankCode: string
  photoNames: string[]
  message?: string
}) => ajax.post('/payment/payment-receipt', params)

/**
 * 
 * TOOD 
 * @param params
 */
// export const getUploadedSupMaterials = (
//   params: {
//     type?: string
//     atomeType: string // VCC/PDL
//   } = {
//     atomeType: 'PDL',
//   },
// ) =>
//   ajax.get('/supplement-materials', {
//     params,
//   })

/**
 * 
 * @param params
 */
export const deleteSupplementStatus = (params: { id: string }) => ajax.post(`/supplement-material/${params.id}/delete`)

export const uploadStepFirst = (params: any) => {
  return ajax.post('/user/personal-and-contact-info/v2', params)
}

export const uploadStepSecond = (params: any) => {
  return ajax.post('/user/residential-and-work-info/v2', params)
}

// personal info ( b  )
export const updatePersonalInfo = (params: any) => {
  return ajax.post('/user/personal-and-residential/v3', params)
}

//  basicinfo
export const uploadBasicInfo = (params: any) => {
  return ajax.post('/user/personal-and-residential/v2', params)
}

// contact info
export const uploadContactInfo = (params: any) => {
  return ajax.post('/user/contact-info/v2', params)
}

// uploadUserWorkInfo
export const uploadUserWorkInfo = (params: any) => {
  return ajax.post('/user/work-info/v2', params)
}

/*  email otp */
export const getEmailOtp = (params: { email: string }) => ajax.post('/email-verify/send-otp', params)

/*  email otp */
export const uploadEmailOtpInfo = (params: { email: string; otp: string }) =>
  ajax.post('/email-verify/verify-otp', params)

// 
// export const uploadPaymentInfoToOKP = (params: any) => ajax.post(`/user/payment-info`, params)
export const uploadPaymentInfo = (params: any, config = {}) => ajax.post(`/user/payment-info/v2`, params, config)

// 
export const checkBankCardInfo = (params: any, config = {}) => ajax.post(`/bank/pre-verify-bank`, params, config)

// 
export const getRejectReasons = () => {
  return ajax.get('/loan/return-reason')
}

// 
export const getUserTags = () => {
  return ajax.get('/user/tags/activated')
}

// 
export const checkApplicable = () =>
  ajax.get<{
    availableLimit: boolean
    needChangeRateVersion: boolean
    needOTP: boolean
    needSupplementMaterial: boolean
  }>('/loan/applicable')

// 
export const personalInfo = (params: any) => ajax.post('/user/optimize/personal-info/v1', params)
export const residentialInfo = (params: any) => ajax.post('/user/optimize/residential-info/v1', params)
export const contactInfo = (params: any) => ajax.post('/user/optimize/contact-info/v1', params)
export const workInfo = (params: any) => ajax.post('/user/optimize/work-info/v1', params)
