const CommonDownloadApp = () =>
  import(/* webpackChunkName: "CommonDownloadApp" */ '@base/components/CommonDownloadApp.vue')
const TermsAndCondition = () =>
  import(/* webpackChunkName: "TermsAndCondition" */ '@base/views/commons/TermsAndCondition.vue')
const CommonDownloadAppAPK = () =>
  import(/* webpackChunkName: "CommonDownloadAppAPK" */ '@base/components/CommonDownloadAppAPK.vue')
const CommonDownloadAppSorry = () =>
  import(/* webpackChunkName: "CommonDownloadAppSorry" */ '@base/components/CommonDownloadAppSorry.vue')
const CommonDocuments = () => import(/* webpackChunkName: "TermsOfService" */ '@base/views/commons/CommonDocuments.vue')
const JagoTermsAndCondition = () =>
  import(/* webpackChunkName: "JagoTermsAndCondition" */ '@base/views/commons/JagoTermsAndCondition.vue')

const BaseCommonRoute = [
  {
    name: 'CommonDownloadApp',
    path: 'download-app',
    component: CommonDownloadApp,
  },
  {
    name: 'CommonDownloadAppAPK',
    path: 'download-app-apk',
    component: CommonDownloadAppAPK,
  },
  {
    name: 'CommonDownloadAppSorry',
    path: 'download-app-sorry',
    component: CommonDownloadAppSorry,
    meta: {
      root: true,
      backTo: 'Home',
    },
  },
  {
    name: 'TermsAndConditionPage',
    path: 'push/term-and-condition',
    component: TermsAndCondition,
  },
  {
    name: 'CommonDocumentsPage',
    path: 'documents/:type',
    component: CommonDocuments,
  },
  {
    name: 'JagoTermsAndConditionPage',
    path: 'push/jago-term-and-condition',
    component: JagoTermsAndCondition,
  },
  {
    path: 'authenticate/whatsapp',
    redirect: () => {
      return { path: 'download-app', query: { hasback: 'false' } }
    },
  },
]

export default BaseCommonRoute
