import { logout } from '@base/api/login'
import { getItem, setItem } from '@base/lib/storage-handler'
import { removeComparedChannel, isFromChannel } from '@base/lib/channelUtil'
import Vuex from 'vuex'
import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators'

const store = new Vuex.Store({})

@Module({ dynamic: true, store, name: 'CommonStore' })
class CommonStore extends VuexModule {
  hasAddToast = false
  h5Liveness = false
  streamInfo: any = null

  get getH5LivenessFlag() {
    const flag = getItem('h5Liveness') === 'true'
    return flag || this.h5Liveness
  }

  @Mutation
  updateToastStatus(payload: boolean) {
    this.hasAddToast = payload
  }

  @Mutation
  updateH5Liveness(payload = false) {
    this.h5Liveness = payload
    setItem('h5Liveness', payload)
  }

  @Mutation
  updateStreamInfo(payload = {}) {
    this.streamInfo = payload
  }

  @Action
  async handleLogout() {
    try {
      await logout()
    } catch (error: any) {
      console.log('logout error')
    } finally {
      removeComparedChannel()
      if (isFromChannel()) return
      const route = window.vue.$route
      const query = route.query || {}
      if (route.query?.redirect || route.fullPath !== '/') {
        query.redirect = route.query.redirect ? (route.query.redirect as string) : route.fullPath
      }

      window.vue.$router.replace({
        name: 'Login',
        query,
      })
    }
  }
}
export const CommonModule = getModule(CommonStore)
