import xs, { Producer, Listener } from 'xstream'
import dsBridge from '@base/plugins/dsbridge'
import { isEnableUseDsbridge } from '@base/lib/utils'

export interface IPosition {
  x: number
  y: number
}

const scrollProducer: Producer<IPosition> & {
  handler: (listener: Listener<IPosition>) => (ev: UIEvent) => void
  rawListener?: (ev: any) => void
} = {
  rawListener: undefined,
  handler: (listener) => (ev) => {
    const pos = {
      x: window.screenX,
      y: window.screenY,
    }
    listener.next(pos)
  },
  start(listener) {
    this.rawListener = this.handler(listener)
    window.addEventListener('scroll', this.rawListener)
  },
  stop() {
    this.rawListener && window.removeEventListener('scroll', this.rawListener)
  },
}

export const scrollStream = xs.create(scrollProducer)

const takePhotoCallBackProducer: Producer<{
  imageType?: string
  imageName?: string
  ocrResult?: {
    idNumber: string
    fullName: string
  }
  fileName?: string
  url?: string
}> = {
  start: (listener) => {
    window.dkBridge.takePhotoCb = (json) => {
      listener.next(json)
    }
  },
  stop: () => {},
}

export const takePhotoCbStream = xs.create(takePhotoCallBackProducer)

const refreshProducer = {
  start: (listener: Listener<undefined>) => {
    window.dkBridge.onRefresh = () => {
      listener.next(undefined)
    }
  },
  stop: () => {},
}

export const refreshStream = xs.create(refreshProducer)

// setContacts
const setContactProducer: Producer<{
  name: string
  number: string
}> = {
  start: (listener) => {
    if (window.dkBridge) {
      window.dkBridge.setContact = (contactInfo) => {
        listener.next(contactInfo)
      }
    }
  },
  stop: () => {},
}

export const setContactStream = xs.create(setContactProducer)

// getUploadDataStatusCallback
const uploadDataStatusCallbackProducer: Producer<{
  uploadCompleted: boolean
}> = {
  start: (listener) => {
    window.dkBridge.getUploadDataStatusCallback = (json) => {
      listener.next(json)
    }
  },
  stop: () => {},
}

export const uploadDataStatusStream = xs.create(uploadDataStatusCallbackProducer)

/** watch video callback */

//  loading
const showLoadingProducer: Producer<null> = {
  start: (listener) => {
    if (isEnableUseDsbridge()) {
      dsBridge.registerAsyn('showLoading', () => {
        listener.next(null)
      })
    } else {
      window.dkBridge.showLoading = () => {
        listener.next(null)
      }
    }
  },
  stop: () => {},
}

export const showLoadingStream = xs.create(showLoadingProducer)

//  loading
const closeLoadingProducer: Producer<null> = {
  start: (listener) => {
    // window.dkBridge.hideLoading = () => {
    //   listener.next(null)
    // }
    if (isEnableUseDsbridge()) {
      dsBridge.registerAsyn('hideLoading', () => {
        listener.next(null)
      })
    } else {
      window.dkBridge.showLoading = () => {
        listener.next(null)
      }
    }
  },
  stop: () => {},
}

export const closeLoadingStream = xs.create(closeLoadingProducer)

// showRewardDialog
const showDialogCallbackProducer: Producer<{
  isSuccess: boolean
  score: string
  errorMessage: string
}> = {
  start: (listener) => {
    if (isEnableUseDsbridge()) {
      dsBridge.registerAsyn('showRewardDialog', (json: { isSuccess: boolean; score: string; errorMessage: string }) => {
        listener.next(json)
      })
    } else {
      window.dkBridge.showRewardDialog = (json) => {
        listener.next(json)
      }
    }
  },
  stop: () => {},
}

export const showDialogResultStream = xs.create(showDialogCallbackProducer)

const linkFacebookProducer = {
  start: (listener: Listener<undefined>) => {
    window.dkBridge.linkFacebookCb = (json: any) => {
      listener.next(json)
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  stop: () => {},
}

export const linkFacebookStream = xs.create(linkFacebookProducer)

const faceDetectionProducer = {
  start: (listener: Listener<undefined>) => {
    window.dkBridge.getFaceDetectionCb = (json: any) => {
      listener.next(json)
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  stop: () => {},
}

export const faceDetectionStream = xs.create(faceDetectionProducer)
