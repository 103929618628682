import {
  Button,
  Icon,
  Field,
  Check,
  CheckGroup,
  CellItem,
  InputItem,
  ActivityIndicator,
  Codebox,
  Popup,
  Agree,
  Steps,
} from 'mand-mobile'
import Toast from '@base/lib/toast-ui'
import LoadingButton from '@base/components/LoadingButton.vue'
import PageTitle from '@base/components/PageTitle.vue'
import AutoForm from '@/views/apply/common/AutoForm.vue'
import Selector from '@/views/apply/common/Selector.vue'
import PhoneNumber from '@/views/apply/common/PhoneNumber.vue'
import PcrvFormItem from '@/views/apply/common/PcrvFormItem.vue'
import _Vue from 'vue'

const mdComponents: { [key: string]: any } = {
  Button,
  Icon,
  Field,
  Check,
  CheckGroup,
  CellItem,
  InputItem,
  ActivityIndicator,
  Codebox,
  Toast,
  Popup,
  Agree,
  Steps,
}
const components: { [key: string]: any } = {
  LoadingButton,
  AutoForm,
  PageTitle,
  Selector,
  PhoneNumber,
  PcrvFormItem,
}

export default {
  install(Vue: typeof _Vue, options?: any) {
    Object.keys(mdComponents).forEach((key: string) => {
      Vue.component(`Md${key}`, mdComponents[key])
    })
    Object.keys(components).forEach((key: string) => {
      Vue.component(`${key}`, components[key])
    })
    Vue.prototype.$toast = Toast
  },
}

export const $toast = Toast
