import dayjs from 'dayjs'
import _Vue from 'vue'

//
export function formatMoney(value: string | number | undefined, prefix = true) {
  const type = prefix ? 'Rp ' : ''
  try {
    if (value !== 0 && !value) {
      return `${type}-`
    }
    if (typeof value === 'string') {
      value = parseInt(value, 10)
    }
    return value
      .toFixed(0)
      .replace(/\B(?=(\d{3})+$)/g, '.')
      .replace(/^/, `${type}`)
  } catch (error: any) {
    return `${type}-`
  }
}

// CouponFaceValue
export function formatCouponMoney(value: string | number) {
  try {
    if (value !== 0 && !value) {
      return ''
    }
    if (typeof value === 'string') {
      value = parseInt(value, 10)
    }
    return value.toFixed(0).replace(/\B(?=(\d{3})+$)/g, '.')
  } catch (error: any) {
    return ''
  }
}

export function formatBank(value: string | number) {
  if (!value) {
    return '-'
  }
  if (typeof value === 'number') {
    value = value + ''
  }
  return value.replace(/(\d{4})/g, '$1 ').replace(/\s*$/, '')
}

export function formatTimestamp(value: string | number, format = 'DD-MM-YYYY HH:mm:ss') {
  if (!value) {
    return '-'
  }
  try {
    if (typeof value === 'string') value = value.replace(/-/g, '/')
    return dayjs(value).format(format)
  } catch (error: any) {
    console.error(error)
    return '-'
  }
}
export function formatDate(value: any, format = 'DD MMM') {
  if (!value) {
    return '-'
  }
  try {
    return dayjs(value).format(format)
  } catch (error: any) {
    console.error(error)
    return '-'
  }
}

function formatFirstUpperCase(val: string) {
  if (!val) {
    return
  }
  return val.toLowerCase().replace(/^\S/g, function (s) {
    return s.toUpperCase()
  })
}

export function formatFirstCase(str: string) {
  if (str) {
    return `${str.slice(0, 1).toUpperCase()}${str.slice(1).toLowerCase()}`
  }
  return '-'
}

export default {
  install(Vue: typeof _Vue, options = {}) {
    Vue.filter('formatMoney', formatMoney)
    Vue.filter('formatBank', formatBank)
    Vue.filter('formatTimestamp', formatTimestamp)
    Vue.filter('formatDate', formatDate)
    Vue.filter('formatFirstUpperCase', formatFirstUpperCase)
    Vue.filter('formatCouponMoney', formatCouponMoney)
    Vue.filter('formatFirstCase', formatFirstCase)
  },
}
