const VatAgreement = () => import(/* webpackChunkName: "VatAgreement" */ '@base/views/vat-agreement/Agreement.vue')
const VatAgreementCompleted = () => import(/* webpackChunkName: "VatAgreement" */ '@base/views/vat-agreement/AgreementCompleted.vue')

const VatRoute = [
  {
    name: 'VatAgreement',
    path: 'vat12adjustment',
    component: VatAgreement,
  },
  {
    name: 'VatAgreementCompleted',
    path: 'vat12adjustment/completed',
    component: VatAgreementCompleted,
  },
]

export default VatRoute
