const SecurityCheckList = () =>
  import(/* webpackChunkName: "SecurityCheckList" */ '@base/views/account-security/verification-list.vue')
const SecurityCheckReject = () =>
  import(/* webpackChunkName: "SecurityCheckReject" */ '@base/views/account-security/security-rejected.vue')

const SecurityOTPVerify = () =>
  import(/* webpackChunkName: "SecurityOTPVerify" */ '@base/views/account-security/security-otp.vue')

const SecurityFaceDetecStart = () =>
  import(/* webpackChunkName: "SecurityFaceDetecStart" */ '@base/views/account-security/SecurityLivenessWithKtp.vue')

const AccountSecurityRoute = [
  {
    name: 'SecurityCheckList',
    path: 'security/verification-list',
    component: SecurityCheckList,
  },
  {
    name: 'SecurityCheckReject',
    path: 'security/rejected',
    component: SecurityCheckReject,
  },
  {
    name: 'SecurityOTPVerify',
    path: 'security/code-step',
    component: SecurityOTPVerify,
  },
  {
    name: 'SecurityCheckLivenessWithKtp',
    path: 'security/liveness',
    component: SecurityFaceDetecStart,
  },
]

export default AccountSecurityRoute
