import {
  init,
  vueRouterInstrumentation,
  setTag,
  withScope,
  configureScope,
  captureMessage,
  captureException,
} from '@sentry/vue'
import type { AxiosResponse } from 'axios'
import { Integrations } from '@sentry/tracing'
import { getUserInfoFromLocal } from './utils'
import type { VueConstructor } from 'vue'
import type Router from 'vue-router'
import { CodeWhiteList } from '@base/lib/constant'

export const sentryHandle = (response: AxiosResponse, code: number, type: 'captureException' | 'captureMessage') => {
  let message = ''
  if (code < 500) {
    // sentry 不上报的业务 code 白名单
    if (CodeWhiteList.includes(response.data.code)) return
    if (response.data.message) {
      message = `${code}-${response.data.code}-${response.data.message}`
    } else {
      message = `${code}-${response.data.code}`
    }
  } else {
    message = `${code}: Server error!!`
  }
  setTag('api', response.config.url)
  setTag('http_code', code)
  setTag('business_code', response.data.code)
  withScope((scope) => {
    scope.setExtra('config', {
      data: response.config.data,
      params: response.config.params,
      url: response.config.url,
    })
    scope.setExtra('request', response.request)
    scope.setExtra('response', response.data)
    scope.setUser({ id: getUserInfoFromLocal() })
    type === 'captureMessage' ? captureMessage(`${message}`) : captureException(`${message}`)
  })
}

export const createApplicationSentryLog = (api: string, params: any) => {
  // liberate Sentry
  // setTag('api', api)
  // withScope((scope) => {
  //   scope.setTag('createApplicationType', api)
  //   scope.setExtra('params', {
  //     params: JSON.stringify(params),
  //   })
  //   scope.setUser({ id: getUserInfoFromLocal() })
  //   captureMessage(`CreateApplicationLog: ${api}`)
  // })
}

/** sentry global config */
export const setGlobalConfig = () => {
  configureScope((scope) => {
    scope.setTag('userId', getUserInfoFromLocal())
    scope.setTag('project', process.env.VUE_APP_PROJECT_NAME)
    scope.setUser({
      id: getUserInfoFromLocal() || '',
    })
  })
}

export const initSentryConfig = (Vue: VueConstructor, router: Router) => {
  init({
    Vue,
    release: `v${process.env.GIT_SHA}-${process.env.VUE_APP_ENV}`,
    environment: process.env.VUE_APP_ENV,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'app.kreditpintar.com', 'h.kreditpintar.com', /^\//],
      }),
    ],
    tracesSampleRate: process.env.VUE_APP_ENV === 'production' ? 0.2 : 1.0,
    tracingOptions: {
      trackComponents: true,
    },
  })
}

/**
 * 上报活体相关数据
 */
export const getLivenessSentryLog = (params: any, type: 'key' | 'result') => {
  withScope((scope) => {
    scope.setTag('livenessType', type)
    scope.setTag('resultType', params.resultType)
    scope.setExtra('params', {
      params: JSON.stringify(params),
    })
    scope.setUser({ id: getUserInfoFromLocal() })
    captureMessage(`About Liveness Service Log: ${type}`)
  })
}
