export enum PopupEnum {
  LIMIT = 'LIMIT',
  FROZEN = 'FROZEN',
  SUCCESS = 'SUCCESS',
  WAITING = 'WAITING',
  BLOCKED = 'BLOCKED',
  ONGOING = 'ONGOING',
  // NKP_ONGOING = 'NKP_ONGOING',
  REJECTED = 'REJECTED',
  NKP_REJECTED = 'NKP_REJECTED',
  UNQUALIFIED = 'UNQUALIFIED',
  UNAVAILABLE = 'UNAVAILABLE',
  APPLY_SUCCESS = 'APPLY_SUCCESS',
  UNABLE_TO_LOAN = 'UNABLE_TO_LOAN',
  APPLY_SUCCESS_WITH_PENDING = 'APPLY_SUCCESS_WITH_PENDING',
  COUPON_EXPIRED = 'COUPON_EXPIRED',
  EFFORTLESS = 'EFFORTLESS',
  EFFORTLESS_LOGIN_FORBID = 'EFFORTLESS_LOGIN_FORBID',
  EFFORTLESS_LOAN_FACE_TIMES_ERROR = 'EFFORTLESS_LOAN_FACE_TIMES_ERROR',
  EFFORTLESS_LOAN_FACE_SIMILARITY_ERROR = 'EFFORTLESS_LOAN_FACE_SIMILARITY_ERROR',
}
