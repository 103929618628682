import ajax from '@base/lib/ajax'
import { isApp } from '@base/lib/utils'
import { getItem } from '@base/lib/storage-handler'
import { AGENCY_TOKEN } from '@base/lib/constant'
import { commonBridgeFn } from '@base/lib/common-bridge'

const agency_token = commonBridgeFn('getCacheParams', false, { key: AGENCY_TOKEN })

type ISmsCode = {
  batchNo: string
}

//
export const getLoginType = () => ajax.get<'A' | 'B'>('/auth/type')

// sms ReferFriends
export const referSmsLogin = (payload: { otp: string; mobileNumber: string; invitedBy: string; sourceType?: string }) =>
  ajax.post(
    '/auth/invitation/login/otp',
    {
      otp: payload.otp,
      mobileNumber: payload.mobileNumber,
      invitedBy: payload.invitedBy,
    },
    {
      headers: {
        'x-adv-source-type': payload.sourceType ?? '',
        'x-adv-agency-token': isApp() ? agency_token ?? '' : getItem(AGENCY_TOKEN) ?? '',
      },
    },
  )

// sms  sourceType
export const smsLogin = (payload: { otp: string; mobileNumber: string; sourceType?: string }) =>
  ajax.post(
    '/auth/login/otp',
    { otp: payload.otp, mobileNumber: payload.mobileNumber },
    {
      headers: {
        'x-adv-source-type': payload.sourceType ?? '',
        'x-adv-agency-token': isApp() ? agency_token ?? '' : getItem(AGENCY_TOKEN) ?? '',
      },
    },
  )

// fb
export const fbLogin = (payload: { authorizationCode: string }) => ajax.post('/auth/login', payload)

//  sms
export const getSmsCode = (payload: {
  mobileNumber: string
  type: 'VOICE' | 'SMS'
  otpType?: 'REGISTER' | 'FORGET_PASSWORD'
}) => ajax.post<ISmsCode>('/auth/send-code', payload)

//
export const logout = () => ajax.post('/auth/logout')

export const pollingMessage = (params: { batchNo: string }) =>
  ajax.get<{ message: string }>('/auth/otp/info', { params })
