export enum ErrorCode {
  UNAUTHORIZED = 'UNAUTHORIZED',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  LOGIN_FAILED = 'LOGIN_FAILED',
  CHANNEL_VERIFY_ERROR = 'LINK_CHANNEL_VERIFY_ERROR',
  //重试
  IOH_TOKEN_MOBILE_RETRY = 'IOH_TOKEN_MOBILE_RETRY',
  //no data
  IOH_USER_NO_CRITERIA = 'IOH_USER_NO_CRITERIA',
  IOH_USER_NEED_LOGIN = 'IOH_USER_NEED_LOGIN',

  INVALID_INPUT = 'INVALID_INPUT',
  INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
}

export enum VideoKycStatusEnum {
  INITIATE = 'INITIATE',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  PROCESSING = 'PROCESSING',
}

//  display type
export enum DisplayTypeEnum {
  BANNER = 'BANNER',
  ICON = 'ICON',
  ICON_TEXT = 'ICON_TEXT',
  STATIC_TEXT = 'STATIC_TEXT',
  COUPON_POPUP = 'COUPON_POPUP',
}

export enum DeviceTypeEnum {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  H5 = 'H5',
}

export enum WebViewType {
  WEB = 'WEB',
  ANDROID_WEBVIEW = 'ANDROID_WEBVIEW',
  IOS_WEBVIEW = 'IOS_WEBVIEW',
}

/** 用于 takePhotoResult type 字段 */
export enum TakePhotoEnum {
  KTP = 'KTP',
  /** 带 KTP 活体检测 */
  LIVENESS_KTP = 'LIVENESS_KTP',
  /** 不带 KTP 的活体检测 */
  LIVENESS_WITHOUT_KTP = 'LIVENESS_WITHOUT_KTP',
  ID_HOLDING = 'ID_HOLDING',
  /** 通用的拍照 */
  COMMON_CAMERA = 'COMMON_CAMERA',
}

export enum ResponseCodeEnum {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  /** H5 guardian 服务错误 */
  TOKEN_INVALID = 'TOKEN_INVALID',
}

export const LinkWebsiteEnum: any = {
  lazada: 'LAZADAID',
  tokopedia: 'TOKOPEDIA',
  shopee: 'SHOPEE',
  jdshop: 'JDSHOP',
  gojek: 'GOJEK',
  golife: 'GOLIFE',
  bca: 'BCABANK',
  bri: 'BRIBANK',
  bni: 'BNIBANK',
  xendit: 'XENDIT',
  npwp: 'NPWP',
  facebook: 'FACEBOOK',
  linkedin: 'LINKEDIN',
  bp: 'BPJSHEALTH',
}

//   link link
export enum LinkChannelEnum {
  DEFAULT = 'DEFAULT', //
  OPS = 'OPS', //
  PROFILE = 'PROFILE', // my account
}

export enum FaceDetectionEnum {
  UNDEFINED = 'UNDEFINED',
  UPDATE_USER_INFO = 'UPDATE_USER_INFO',
  RETURN_FLOW = 'RETURN_FLOW',
  CHANGE_MOBILE = 'CHANGE_MOBILE',
  LOG_IN = 'LOG_IN',
  CHANGE_BANK_ACCOUNT = 'CHANGE_BANK_ACCOUNT',
}

export enum StaticAgreeOnEnum {
  PRIVACY_POLICY = 'privacy-policy',
  TERM_OF_SERVICE = 'term-of-service',
  RISK = 'Risk',
  JAGOTERMS_AND_CONDITIONS = 'jagoTermsAndConditions',
}

export enum LoginStatusEnum {
  SUCCESS = 'SUCCESS',
  NEED_CAPTCHA = 'NEED_CAPTCHA',
}

/** chanel type */
export enum ChannelEnum {
  H5 = 'H5',
  TCASH = 'TCASH',
  OTTO = 'OTTO',
}
