<template>
  <md-popup v-model="isPopupShow" class="verify-dialog" :mask-closable="false" position="bottom" @hide="hideHandler">
    <div class="container">
      <div class="icon-top"></div>
      <md-icon class="icon-close" name="icon_close" svg @click="closeHandler" />
      <img class="image" :src="popupType.url" />
      <div v-if="popupType.title" class="title">{{ popupType.title }}</div>
      <div v-if="popupType.message" class="message">{{ popupType.message }}</div>
      <loading-button v-if="showButton" class="submit-btn" :loading="loading" @click="submitHandler">
        {{ popupType.buttonText }}
      </loading-button>
    </div>
  </md-popup>
</template>

<script>
export default {
  name: 'PopupResult',
  model: {
    prop: 'isShow',
    event: 'close',
  },
  props: {
    isShow: { type: Boolean, default: false },
    type: { type: String, default: '' },
    title: { type: String, default: '' },
    message: { type: String, default: '' },
    showButton: { type: Boolean, default: true },
    buttonText: { type: String, default: '' },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      isPopupShow: false,
    }
  },
  computed: {
    allTypes() {
      return [
        // 
        {
          type: 'SUCCESS',
          url: require('@/assets/images/img_success.png'),
          title: '',
          message: this.$t('successMessage'),
          buttonText: this.$t('ok'),
        },
        // 
        {
          type: 'LIMIT',
          url: require('@/assets/images/img_limit.png'),
          title: this.$t('limitTitle'),
          message: this.$t('limitMessage'),
          buttonText: this.$t('payBill'),
        },
        //  ( 0)
        {
          type: 'FROZEN',
          url: require('@/assets/images/img_locked.png'),
          title: this.$t('blockedTitle'),
          message: this.$t('frozenMessage'),
          buttonText: this.$t('ok'),
        },
        //  ( > 0)
        {
          type: 'BLOCKED',
          url: require('@/assets/images/img_locked.png'),
          title: this.$t('blockedTitle'),
          message: this.$t('blockedMessage'),
          buttonText: this.$t('payBill'),
        },
        // 
        {
          type: 'ONGOING',
          url: require('@/assets/images/img_ongoing.png'),
          title: '',
          message: this.$t('ongoingMessage'),
          buttonText: this.$t('ok'),
        },
        // 
        {
          type: 'REJECTED',
          url: require('@/assets/images/img_locked.png'),
          title: this.$t('rejectedTitle'),
          message: this.$t('rejectedMessage'),
          buttonText: this.$t('ok'),
        },
        // 
        {
          type: 'UNQUALIFIED',
          url: require('@/assets/images/img_limit.png'),
          title: this.$t('unqualifiedTitle'),
          message: this.$t('unqualifiedMessage'),
          buttonText: this.$t('ok'),
        },
        // 
        {
          type: 'UNAVAILABLE',
          url: require('@/assets/images/img_limit.png'),
          title: this.$t('unavailableTitle'),
          message: this.$t('unavailableMessage'),
          buttonText: this.$t('ok'),
        },
        // 
        {
          type: 'UNABLE_TO_LOAN',
          url: require('@/assets/images/img_reject.png'),
          title: this.$t('unableToLoanTitle'),
          message: this.$t('unableToLoanMessage'),
          buttonText: this.$t('ok'),
        },
      ]
    },
    popupType() {
      const temp =
        this.allTypes.filter((item) => {
          return item.type === this.type.toUpperCase()
        })[0] || {}
      return {
        type: temp.type,
        url: temp.url,
        title: this.title ? this.title : temp.title,
        message: this.message ? this.message : temp.message,
        buttonText: this.buttonText ? this.buttonText : temp.buttonText,
      }
    },
  },
  watch: {
    isShow: {
      handler(val) {
        this.isPopupShow = this.isShow
      },
      immediate: true,
    },
  },
  methods: {
    hideHandler() {
      this.$emit('hide')
      this.closeHandler()
    },
    closeHandler() {
      this.isPopupShow = false
      this.$emit('close', false)
    },
    submitHandler() {
      this.$emit('submit')
      this.closeHandler()
    },
  },
  i18n: {
    messages: {
      'en-us': {
        submit: 'Submit',
        ok: 'OK',
        payBill: 'Pay Bill',
        successMessage: 'KPintar customer service team has received your issue and will resolve it as soon as possible',
        limitTitle: 'Limit is not enough!',
        limitMessage: 'Pay other bills first to have more available limit',
        blockedTitle: 'Limit locked!',
        frozenMessage: 'You have violated out terms of service and your limit is frozen',
        blockedMessage:
          'You have violated out terms of service and your limit is frozen. Please pay off your remaining bills and try again later',
        ongoingMessage: "Don't worry! You can apply limit after settle your current ongoing bill",
        rejectedTitle: 'Try again on',
        rejectedMessage: 'Your limit is temporary locked because your previous application was rejected',
        unqualifiedTitle: 'Your limit is unqualified',
        unqualifiedMessage: 'Make a good credit history will give you chance to get loans',
        unavailableTitle: 'Product unavailable',
        unavailableMessage: 'Sorry, this prodect is unavailable right now. Please select another product',
        unableToLoanTitle: 'Application rejected',
        unableToLoanMessage: "We noticed you have breached platform policy, the application can't be approved",
      },
      id: {
        submit: 'Ajukan',
        ok: 'OK',
        payBill: 'Bayar Tagihan',
        successMessage:
          'Tim Layanan Pelanggan KPintar telah menerima permasalahan Anda dan akan segera menyelesaikannya',
        limitTitle: 'Limit tidak mencukupi!',
        limitMessage: 'Mohon lunasi tagihan Anda untuk dapat tambahan limit',
        blockedTitle: 'Limit terkunci!',
        frozenMessage: 'Anda telah melanggar Ketentuan Layanan dan limit Anda terkunci',
        blockedMessage:
          'Anda telah melanggar Ketentuan Layanan dan limit Anda terkunci. Mohon lunasi semua tagihan dan coba lagi nanti',
        ongoingMessage:
          'Jangan khawatir! Anda dapat menerapkan batas kredit setelah menyelesaikan tagihan Anda saat ini',
        rejectedTitle: 'Silakan coba lagi pada',
        rejectedMessage: 'Limit Anda terkunci sementara karena pengajuan sebelumnya ditolak',
        unqualifiedTitle: 'Limit Anda tidak memenuhi syarat',
        unqualifiedMessage: 'Bangun riwayat kredit yang baik untuk mendapatkan persetujuan pinjaman',
        unavailableTitle: 'Produk tidak tersedia',
        unavailableMessage: 'Maaf, untuk saat ini Produk tidak tersedia. Silakan pilih produk lain',
        unableToLoanTitle: 'Pengajuan ditolak',
        unableToLoanMessage: 'Kami perhatikan Anda telah melanggar kebijakan platform, aplikasi tidak dapat disetujui',
      },
    },
  },
}
</script>

<style lang="stylus" scoped>
.container
  position relative
  background white
  display flex
  flex-direction column
  align-items center
  padding 16px 48px 40px
  border-radius 32px 32px 0px 0px
  .icon-top
    width 56px
    height 10px
    background #D8D8D8
    border-radius 4px
  .icon-close
    width 24px
    height 24px
    position absolute
    left 48px
    top 48px

  .image
    margin 64px 0px 56px
    max-height 320px
  .title
    text-align center
    font-size 33px
    line-height 50px
    fontBold()
  .message
    text-align center
    line-height 40px
    color #666666
    margin 0px 16px 40px
    font-size 28px
  .submit-btn
    width 100%
</style>
