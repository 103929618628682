export enum LinkWebsiteEnum {
  lazada = 'LAZADAID',
  tokopedia = 'TOKOPEDIA',
  shopee = 'SHOPEE',
  jdshop = 'JDSHOP',
  gojek = 'GOJEK',
  golife = 'GOLIFE',
  bca = 'BCABANK',
  bri = 'BRIBANK',
  bni = 'BNIBANK',
  xendit = 'XENDIT',
  npwp = 'NPWP',
  facebook = 'FACEBOOK',
  linkedin = 'LINKEDIN',
  bp = 'BPJSHEALTH',
}
/**  link  */
export const initLinkMap = {
  LAZADAID: {
    title: 'Lazada',
    iconUrl: require('@/assets/images/logo_lazada.png'),
  },
  TOKOPEDIA: {
    title: 'Tokopedia',
    iconUrl: require('@/assets/images/logo_tokopedia.png'),
  },
  SHOPEE: {
    title: 'Shopee',
    iconUrl: require('@/assets/images/logo_shopee.png'),
  },
  GOJEK: {
    title: 'Go-jek',
    iconUrl: require('@/assets/images/logo_gojek.png'),
  },
  BCABANK: {
    title: 'BCA bank',
    iconUrl: require('@/assets/images/logo_bca.png'),
  },
  NPWP: {
    title: 'NPWP',
    iconUrl: require('@/assets/images/logo_npwp.png'),
  },
}

export enum VideoKycStatusEnum {
  INITIATE = 'INITIATE',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  PROCESSING = 'PROCESSING',
}

export enum FEKycStatusEnum {
  INIT = 'INIT', //
  OVERDUE = 'OVERDUE', //
  FAILED = 'FAILED', //
  PROCESSING = 'PROCESSING', //
  SUCCESS = 'SUCCESS', //
  TRY_AGAIN = 'TRY_AGAIN', //
}

//   link link
export enum LinkChannelEnum {
  DEFAULT = 'DEFAULT',
  OPS = 'OPS',
  PROFILE = 'PROFILE',
}

//  type
export enum FaceDetectionEnum {
  UNDEFINED = 'UNDEFINED',
  UPDATE_USER_INFO = 'UPDATE_USER_INFO',
  RETURN_FLOW = 'RETURN_FLOW',
  CHANGE_MOBILE = 'CHANGE_MOBILE',
  LOG_IN = 'LOG_IN',
  CHANGE_BANK_ACCOUNT = 'CHANGE_BANK_ACCOUNT',
  ACCOUNT_SECURITY = 'ACCOUNT_SECURITY',
}

//
export enum LoginWay {
  SMS = 'sms',
  PWD = 'pwd',
}

/** chanel type */
export enum ChannelEnum {
  TCASH = 'TCASH',
  OTTO = 'OTTO',
}

//  display type
export enum DisplayTypeEnum {
  BANNER = 'BANNER',
  ICON = 'ICON',
  ICON_TEXT = 'ICON_TEXT',
  STATIC_TEXT = 'STATIC_TEXT',
}
