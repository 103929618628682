







import { Component, Vue } from 'vue-property-decorator'
import QuickMenu from './QuickMenu.vue'
import { Action, Mutation, State } from 'vuex-class'
import { commonBridgeFn } from '@base/lib/common-bridge'

@Component({
  components: {
    QuickMenu,
  },
})
export default class FLoatButton extends Vue {
  @State('app') appModule!: any
  @Mutation('updateShowUtils') updateShowUtils!: Function
  @Action('saveShowUtils') saveShowUtils!: () => void

  position = 'bottom-right'

  isShowQuickMenu = false

  menus = [
    {
      name: 'HIDE_BUTTON',
      icon: 'check-disabled',
      isLink: false,
      defaultSvg: true,
    },
    {
      name: 'SETTING',
      icon: 'setting',
      isLink: false,
      defaultSvg: true,
    },
    {
      name: 'REFRESH',
      icon: 'refresh',
      isLink: false,
      defaultSvg: true,
    },
    {
      name: 'SWITCH_LANGUAGE',
      icon: 'switch',
      isLink: false,
      defaultSvg: true,
    },
  ]

  get isShow() {
    const env = process.env.VUE_APP_ENV !== 'production'
    if (env) this.saveShowUtils()
    return env
  }

  blockClickHandler() {
    this.isShowQuickMenu = false
  }

  clickHandler(name: string) {
    this.isShowQuickMenu = false
    switch (name) {
      case 'SWITCH_LANGUAGE':
        {
          const lang: {
            [k: string]: string
          } = {
            'en-us': 'en',
            id: 'id',
          }
          const language = this.$root.$i18n.locale === 'id' ? 'en-us' : 'id'
          this.$root.$i18n.locale = language
          this.$validator.localize(lang[language])
        }
        break
      case 'REFRESH':
        location.reload()
        break
      case 'SETTING':
        if (this.$route.name !== 'Utils') {
          this.$router.push({ name: 'Utils' })
        }
        break
      case 'HIDE_BUTTON':
        this.updateShowUtils(false)
        // window.dkBridge?.finish?.()
        commonBridgeFn('finish', false)
        break
      default:
        break
    }
  }
}
