


















import { Component, Vue, Prop } from 'vue-property-decorator'
import Popup from '@base/views/commons/Popup.vue'
import { IOperationBanner } from '@base/interface/operation'
import { Action } from '@pintar/id-track-event'

@Component({
  i18n: {
    messages: {
      'en-us': {
        completeInfo: 'Complete the application and enjoy interest coupons',
        stay: 'Continue',
        leaveMessage: 'Sure to leave?',
        leave: 'Leave',
      },
      id: {
        completeInfo: 'Lengkapi pengajuan dan nikmati voucher potongan bunga',
        stay: 'Lanjutkan',
        leaveMessage: 'Yakin mau meninggalkan?',
        leave: 'Tinggalkan',
      },
    },
  },
  components: {
    Popup,
  },
})
export default class TemporarySuccess extends Vue {
  @Prop({ type: Object, default: {} })
  popup!: IOperationBanner
  created() {
    this.$eventTrack({
      action: Action.DialogDisplay,
      extra: { message: 'Sure to leave' },
      options: {
        immediately: true,
      },
    })
  }
  closeHandler() {
    this.$eventTrack({
      action: Action.DialogConfirm,
      extra: {
        message: 'Sure to leave',
      },
      options: {
        immediately: true,
      },
    })
    this.$emit('closePopup')
  }
  leavePopup() {
    this.$eventTrack({
      action: Action.DialogCancel,
      extra: { message: 'Sure to leave' },
      options: {
        immediately: true,
      },
    })
    this.$emit('leave')
  }
}
