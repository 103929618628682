export enum CreateStatusCode {
  /**  */
  INCOMPLETE_USER_INFO = 'INCOMPLETE_USER_INFO',
  //
  FRAUD_ORDER = 'FRAUD_ORDER',
  //
  ILLEGAL_PRODUCTS = 'ILLEGAL_PRODUCTS',
  //  limit == 0
  CREDIT_FROZEN = 'CREDIT_FROZEN',
  //  limit != 0
  CREDIT_BLOCKED = 'CREDIT_BLOCKED',
  //
  INSUFFICIENT_CREDIT = 'INSUFFICIENT_CREDIT',
  INVALID_REPAYMENT_PLAN = 'INVALID_REPAYMENT_PLAN',
  /**
   * OTP
   * @deprecated
   */
  OTP_CHECK_FAILED = 'OTP_CHECK_FAILED',
  /**
   * OTP
   * @deprecated
   */
  OTP_CHECK_REQUIRED = 'OTP_CHECK_REQUIRED',
  //
  RATE_VERSION_CHANGED = 'RATE_VERSION_CHANGED',
  //
  NEED_SUPPLEMENTAL_MATERIALS = 'NEED_SUPPLEMENTAL_MATERIALS',
  /** 30OTP */
  NEED_OTP_VERIFICATION = 'NEED_OTP_VERIFICATION',
  ORDER_REJECTING = 'ORDER_REJECTING',
  // ongoing
  ORDER_ONGOING = 'ORDER_ONGOING',
  ORDER_OPENING = 'ORDER_OPENING',
  SUCCESS = 'SUCCESS',
  FULL_NAME_SAME = 'FULL_NAME_SAME',
  AVAILABLE_LIMIT_NOT_ENOUGH = 'AVAILABLE_LIMIT_NOT_ENOUGH',
  // nkp
  UNABLE_TO_LOAN = 'UNABLE_TO_LOAN',
  /* Coupon  */
  COUPON_EXPIRED = 'COUPON_EXPIRED',
  /*  */
  INVALID_INSURANCE = 'INVALID_INSURANCE',

  NEED_RECLAIM = 'NEED_RECLAIM',

  NO_JAGO_ACCOUNT = 'NO_JAGO_ACCOUNT',

  REACH_DBR_RESTRICTION = 'REACH_DBR_RESTRICTION',

  INVALID_INPUT = 'INVALID_INPUT',
}

export enum KtpStatusCode {
  ORDER_REJECTING = 'ORDER_REJECTING', //
  ORDER_ONGOING = 'ORDER_ONGOING', // ongoing
  ORDER_REJECT = 'ORDER_REJECT', //
}

export enum CheckFlexibleLoanType {
  RECHECK = 'RECHECK',
  MODIFY_TENOR = 'MODIFY_TENOR',
  MODIFY_LOAN_AMOUNT = 'MODIFY_LOAN_AMOUNT',
}
