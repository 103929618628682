import { getItem } from '@base/lib/storage-handler'
import { ChannelEnum } from '@/enum/common'
import { trackEvent } from '@pintar/id-track-event'

export function trimValue(value: any, gap = ' ') {
  value = typeof value === 'undefined' ? '' : value
  const reg = new RegExp(gap, 'g')
  value = value.toString().replace(reg, '')
  return value
}

export const validateItem = async (item: { name: string; value: any }[], validator: any) => {
  if (item.every((_) => _.value)) {
    const validates: any[] = []
    item.forEach((itm) => {
      validates.push(validator.validate(itm.name))
    })
    const result = await Promise.all(validates)
    return result.every((_) => _)
  }
}

/**
 *  TCASH  OTTP
 */
export function filterVirtualAccount(bankCode: string | null) {
  if (bankCode === ChannelEnum.TCASH || bankCode === ChannelEnum.OTTO) return false
  return true
}

/**
 *
 * @param arr
 * @param key
 */
export const filterSupple = (arr: any[], key: 'MANDATORY' | 'OPTIONAL' = 'MANDATORY') => {
  return arr.filter((item) => item.requirementStatus === key)
}

/**
 *
 */
export function validByScroll(that: any) {
  that.$el.querySelector(`[data-vv-name="${that.$validator.errors.items[0].field}"]`).scrollIntoView(false)
}

/**
 *
 */
export function sortByResult(init: any[], res: string[]) {
  if (!init.length) return init
  const result = init
    .filter((item) => res.includes(item.type))
    .sort((a: any, b: any) => res.indexOf(a.type) - res.indexOf(b.type))

  const diff = init.filter((item) => !res.includes(item.type))
  return [...result, ...diff]
}

/**
 *  localStorage
 */
export function fetchUserInfoFromLocal() {
  const data = JSON.parse(getItem('userInfo') || '{}')
  if (Object.keys(data).length) {
    trackEvent.updateDeviceInfo({
      userId: data?.userId,
      userType: data?.type,
    })
  }
}

/**  */
export function stopBodyScroll(isFixed: boolean) {
  document.body.style.overflow = isFixed ? 'hidden' : 'auto'
}

export const goBackByPath = (path: string) => {
  if (path) {
    window.location.href = path
  } else {
    window.vue.$router.go(-1)
  }
}
