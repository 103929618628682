














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Popup extends Vue {
  @Prop({ type: Boolean, default: false })
  hasMask!: boolean

  @Prop({ type: Boolean, default: false })
  maskClosable!: boolean

  @Prop({ type: Boolean, default: false })
  cusCloseHandler!: boolean

  @Prop({ type: String, default: '' }) closeType!: string

  close(e: Event) {
    e.stopPropagation()
    this.$emit('close', e)
    return
  }
}
