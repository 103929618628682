import Vue from 'vue'
import CreateAPI from 'vue-create-api'
import { Selector, DatePicker } from 'mand-mobile'
import PopupAlert from '@base/components/PopupAlert.vue'
import PopupResult from '@/components/PopupResult.vue'
import PopupPcrv from '@/components/PopupPcrv.vue'
import PopupCopy from '@/components/PopupCopy.vue'
import Loading from '@/components/loading/Loading.vue'
import { PopupEnum } from '@ops/enum/popup'
import { Action } from '@pintar/id-track-event'
/**
 * power by vue-create-api
 * how to use:
 * Vue.createApi(anyVueComponent, [emitEvents], singleMode: boolean)
 */

Vue.use(CreateAPI)

Vue.createAPI(Selector, ['choose', 'confirm', 'cancel', 'show', 'hide', 'input'], true)

Vue.createAPI(DatePicker, ['initialed', 'change', 'confirm'], true)

Vue.createAPI(PopupAlert, ['close', 'submit', 'input', 'hide', 'addCloseEvent'], true)

Vue.createAPI(PopupResult, ['close', 'submit', 'input', 'hide'], true)

Vue.createAPI(PopupPcrv, ['choose', 'confirm', 'cancel', 'show', 'hide', 'input'], true)

Vue.createAPI(PopupCopy, ['close', 'copy', 'input', 'hide', 'addCloseEvent'], true)

Vue.createAPI(Loading, [], true)

let alertPop: any = null

interface AlertHandler {
  (options: AlertOptions, cb?: (type?: string) => void): void
  forceClose?: () => void
}

interface AlertOptions {
  type?: string
  title?: string
  message?: string
  buttonText?: string
  showCancelButton?: boolean
  buttonCancelText?: string
  customClose?: boolean
  showCountdown?: boolean
  countdownDuration?: number
  customeWrapper?: object
  hasLeftBtn?: boolean
}

const alertPopHandler: AlertHandler = (
  {
    type,
    title,
    message,
    buttonText,
    showCancelButton,
    buttonCancelText,
    customClose = true,
    showCountdown,
    countdownDuration,
    customeWrapper = { aLabel: false },
    hasLeftBtn = false,
  }: AlertOptions,
  cb?: (type?: string) => void,
  close?: () => void,
) => {
  const submitHandler = (type: string) => {
    alertPop.$on('hide', (json: any) => {
      if (alertPop) {
        alertPop.remove()
      }
      alertPop = null
    })
    if (cb) {
      cb(type)
    }
    hideHandler()
  }
  const hideHandler = () => {
    close && close()
  }
  if (alertPop) {
    alertPop.$updateProps({
      isShow: true,
      type,
      buttonText,
      title,
      message,
      showCancelButton,
      buttonCancelText,
      customClose,
      showCountdown,
      countdownDuration,
      customeWrapper,
      hasLeftBtn,
    })
    return
  }
  alertPop = window.vue.$createPopupAlert({
    $props: {
      isShow: true,
      type,
      buttonText,
      title,
      message,
      showCancelButton,
      buttonCancelText,
      customClose,
      showCountdown,
      countdownDuration,
      customeWrapper,
      hasLeftBtn,
    },
    $events: {
      submit: (type: string) => submitHandler(type),
      hide: () => hideHandler(),
      close: () => alertPop && closeHandle(),
      addCloseEvent: () => {
        if (type === PopupEnum.COUPON_EXPIRED) {
          window?.vue?.$eventTrack({
            action: Action.DialogCloseOptionClick,
          })
        }
      },
    },
  })
}

const closeHandle = () => {
  alertPop.$updateProps({ isShow: false })
  alertPop.remove()
  alertPop = null
}

alertPopHandler.forceClose = function () {
  if (alertPop && alertPop.customClose && alertPop.isShow) {
    closeHandle()
  }
}

let copyPop: any = null

function copyPopHandler({ type, title, message, buttonText }: any, cb?: () => void) {
  const submitHandler = () => {
    copyPop.$on('hide', (json: any) => {
      if (copyPop) {
        copyPop.remove()
      }
      copyPop = null
    })
    if (cb) {
      cb()
    }
  }
  if (copyPop) {
    copyPop.$updateProps({
      isShow: true,
      type,
      buttonText,
      title,
      message,
    })
    return
  }
  copyPop = window.vue.$createPopupCopy({
    $props: {
      isShow: true,
      type,
      buttonText,
      title,
      message,
    },
    $events: {
      copy: () => submitHandler(),
      close: () => copyPop && copyPop.$updateProps({ isShow: false }),
      addCloseEvent: () => {
        if (type === PopupEnum.COUPON_EXPIRED) {
          window?.vue?.$eventTrack({
            action: Action.DialogCloseOptionClick,
          })
        }
      },
    },
  })
}

export default {
  install(vue: typeof Vue, options?: any) {
    vue.prototype.$alert = alertPopHandler
    vue.prototype.$copy = copyPopHandler
  },
}
