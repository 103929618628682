export interface DSBridge {
  call(handlerName: string, args?: any, responseCallback?: (retValue: any) => void): any
  call<T, R>(handlerName: string, args?: T, responseCallback?: (retValue: R) => void): R

  register(handlerName: string, handler: object | (() => any), async?: boolean): void
  register<F>(handlerName: string, handler: F, async?: boolean): void

  registerAsyn(handlerName: string, handler: object | (() => void)): void
  registerAsyn<F>(handlerName: string, handler: F): void

  hasNativeMethod(handlerName: string, type?: 'all' | 'asyn' | 'syn'): boolean
  disableJavascriptDialogBlock(disable?: boolean): void
}

const _window = window as any

const dsBridge: DSBridge = {
  call: function (method: string, args?: any, cb?: (retValue: any) => void) {
    // console.log('===> call', method, args, cb)
    let ret: string | null = ''
    if (typeof args === 'function') {
      cb = args
      args = {}
    }
    let arg = { data: args === undefined ? null : args }
    if (typeof cb === 'function') {
      const cbName = 'dscb' + _window.dscb++
      _window[cbName] = cb
      arg['_dscbstub'] = cbName
    }
    arg = JSON.stringify(arg) as any

    // if in webview that dsBridge provided, call!
    if (_window._dsbridge) {
      ret = _window._dsbridge.call(method, arg)
    } else if (_window._dswk || navigator.userAgent.indexOf('_dsbridge') != -1) {
      ret = prompt('_dsbridge=' + method, arg as unknown as string)
    }

    return JSON.parse(ret || '{}').data
  },
  register: function (name: string, fun: object | (() => any), asyn?: boolean) {
    const q = asyn ? _window._dsaf : _window._dsf
    if (!_window._dsInit) {
      _window._dsInit = true
      // notify native that js apis register successfully on next event loop
      setTimeout(function () {
        dsBridge.call('_dsb.dsinit')
      }, 0)
    }
    if (typeof fun === 'object') {
      q._obs[name] = fun
    } else {
      q[name] = fun
    }
  },
  registerAsyn: function (name: string, fun: object | (() => void)) {
    this.register(name, fun, true)
  },
  hasNativeMethod: function (name, type) {
    return this.call('_dsb.hasNativeMethod', { name, type: type || 'all' })
  },
  disableJavascriptDialogBlock: function (disable) {
    this.call('_dsb.disableJavascriptDialogBlock', {
      disable: disable !== false,
    })
  },
}

function init() {
  if (_window._dsf) return
  const ob = {
    _dsf: {
      _obs: {},
    },
    _dsaf: {
      _obs: {},
    },
    dscb: 0,
    dsBridge,
    close: function () {
      dsBridge.call('_dsb.closePage')
    },
    _handleMessageFromNative: function (info: any) {
      const arg: any = JSON.parse(info.data)
      const ret: any = {
        id: info.callbackId,
        complete: true,
      }
      const f = this._dsf[info.method]
      const af = this._dsaf[info.method]
      const callSyn = function (f: any, ob: any) {
        ret.data = f.apply(ob, arg)
        dsBridge.call('_dsb.returnValue', ret)
      }
      const callAsyn = function (f: { apply: (arg0: any, arg1: any) => void }, ob: { _obs: {} }) {
        arg.push(function (data: any, complete: boolean) {
          ret.data = data
          ret.complete = complete !== false
          dsBridge.call('_dsb.returnValue', ret)
        })
        f.apply(ob, arg)
      }
      if (f) {
        callSyn(f, this._dsf)
      } else if (af) {
        callAsyn(af, this._dsaf)
      } else {
        // with namespace
        const name = info.method.split('.')
        if (name.length < 2) return
        const method = name.pop()
        const namespace = name.join('.')
        let obs = this._dsf._obs
        let ob = obs[namespace] || {}
        let m = ob[method]
        if (m && typeof m === 'function') {
          callSyn(m, ob)
          return
        }
        obs = this._dsaf._obs
        ob = obs[namespace] || {}
        m = ob[method]
        if (m && typeof m === 'function') {
          callAsyn(m, ob)
        }
      }
    },
  }
  for (const attr in ob) {
    _window[attr] = ob[attr]
  }
  dsBridge.register('_hasJavascriptMethod', function (method: string, tag: any) {
    const name: any = method.split('.')
    if (name.length < 2) {
      return !!(_window._dsf[name] || _window._dsaf[name])
    } else {
      // with namespace
      const _method = name.pop()
      const namespace = name.join('.')
      const ob = _window._dsf._obs[namespace] || _window._dsaf._obs[namespace]
      return ob && !!ob[_method]
    }
  })
}

init()

export default dsBridge
