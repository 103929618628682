
import { Component, Prop } from 'vue-property-decorator'
import { Component as VueComponent } from 'vue-tsx-support'

interface IPorps {
  loading?: boolean
  toast?: string
  disabled?: boolean
  inactive?: boolean
  btnType?: string
  buttonStyle?: Record<string, string>
  id?: string
}

interface IEvents {
  onClick?: () => void
}

@Component
export default class LoadingButton extends VueComponent<IPorps, IEvents> {
  @Prop({ type: Boolean, default: false }) loading?: boolean
  @Prop({ type: String, default: undefined }) toast?: string
  @Prop({ type: Boolean, default: false }) disabled?: boolean
  @Prop({ type: Boolean, default: false }) inactive?: boolean
  @Prop({ type: String, default: 'primary' }) btnType?: string
  @Prop({ type: String, default: '#ffffff' }) loadingColor?: string
  @Prop({ type: String, default: '' }) id?: string
  @Prop({ type: Object, default: () => {} }) buttonStyle?: {
    background: string
    borderRadius: string
    color: string
  }

  $refs!: {
    button: VueComponent<IPorps>
  }

  mounted() {
    if (this.id) {
      this.$refs.button.$el.id = this.id
    }
  }

  clickHandler() {
    if (this.loading) {
      if (this.toast) {
        this.$toast.info(this.toast)
      }
      return
    }
    this.$emit('click')
  }

  render(h: any) {
    return (
      <div class="loading-button">
        <md-button
          type={this.disabled ? 'disabled' : this.btnType}
          inactive={this.inactive}
          onClick={this.clickHandler}
          style={this.buttonStyle}
          ref="button"
        >
          {!this.loading ? (
            <span class={{ 'btn-text': true, disabled: this.disabled }}>{this.$slots.default}</span>
          ) : (
            <md-activity-indicator type="carousel" color={this.loadingColor} size={15}></md-activity-indicator>
          )}
        </md-button>
      </div>
    )
  }
}
