<template>
  <div ref="quickMenu" class="quick-menu" :style="quickMenuStyle">
    <div v-for="(menu, index) in menuList" :key="`emun-${index}`" class="sub-menu" :style="getSubMenu(index)">
      <router-link v-if="menu.isLink" :to="{ name: menu.pathName }" :target="openNewTab" :style="subMenuStyle">
        <md-icon v-if="menu.defaultSvg" ref="icon" class="icon" :name="menu.icon" />
        <md-icon v-else ref="icon" class="icon" :name="menu.icon" svg />
      </router-link>
      <a v-else :style="subMenuStyle" @click="processCallback(menu.name)">
        <md-icon v-if="menu.defaultSvg" ref="icon" :name="menu.icon" />
        <md-icon v-else ref="icon" :name="menu.icon" svg />
      </a>
    </div>

    <div class="menu" :style="menuStyle">
      <div class="core-menu" @click="toggleMenu">
        <div class="bar"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'QuickMenu',
  model: {
    prop: 'isOpenMenu',
    event: 'toggle',
  },
  props: {
    isOpenMenu: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: '#1cc812',
    },
    color: {
      type: String,
      default: '#fff',
    },
    isOpenNewTab: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'top-left',
    },
    menus: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      menuSize: 80,
      subMenu4: [
        [
          ['0', '-100'],
          ['-50', '-86.6'],
          ['-86.6', '-50'],
          ['-100', '0'],
        ],
        [
          ['0', '-100'],
          ['50', '-86.6'],
          ['86.6', '-50'],
          ['100', '0'],
        ],
        [
          ['0', '100'],
          ['86.6', '50'],
          ['50', '86.6'],
          ['100', '0'],
        ],
        [
          ['-100', '0'],
          ['-86.6', '50'],
          ['-50', '86.6'],
          ['0', '100'],
        ],
      ],
      subMenu3: [
        [
          ['-100', '0'],
          ['-70.7', '-70.7'],
          ['0', '-100'],
        ],
        [
          ['0', '-100'],
          ['70.7', '-70.7'],
          ['100', '0'],
        ],
        [
          ['0', '100'],
          ['70.7', '70.7'],
          ['100', '0'],
        ],
        [
          ['-100', '0'],
          ['-70.7', '70.7'],
          ['0', '100'],
        ],
      ],
      subMenu2: [
        [
          ['-90', '0'],
          ['0', '-90'],
        ],
        [
          ['0', '-90'],
          ['90', '0'],
        ],
        [
          ['0', '90'],
          ['90', '0'],
        ],
        [
          ['-90', '0'],
          ['0', '90'],
        ],
      ],
    }
  },
  computed: {
    menuList() {
      return this.menus.length > 4 ? this.menus.slice(0, 4) : this.menus
    },
    openNewTab() {
      return this.isOpenNewTab ? '_blank' : '_self'
    },
    quickMenuStyle() {
      const topPosition = { top: '30px' }
      const bottomPosition = { bottom: '50px' }
      const leftPosition = { left: '30px' }
      const rightPosition = { right: '24px' }
      const style = this.isTop ? topPosition : bottomPosition
      Object.assign(style, this.isLeft ? leftPosition : rightPosition)
      Object.assign(style, { transform: this.isLeft ? 'rotate(-180deg)' : 'rotate(180deg)' })
      return style
    },
    menuStyle() {
      return {
        backgroundColor: this.backgroundColor,
        color: this.color,
      }
    },
    subMenuStyle() {
      const style = {
        backgroundColor: this.backgroundColor,
        color: this.color,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }
      return style
    },

    isTop() {
      return !!~this.position.toLowerCase().indexOf('top')
    },
    isLeft() {
      return !!~this.position.toLowerCase().indexOf('left')
    },
  },
  watch: {
    isOpenMenu(val) {
      const menuEl = this.$refs.quickMenu
      if (val) {
        menuEl.className += ' active'
      } else {
        menuEl.className = menuEl.className.replace(' active', '')
      }
    },
  },
  methods: {
    getSubMenu(n) {
      let menuPosition =
        this.menuList.length === 4 ? this.subMenu4 : this.menuList.length === 3 ? this.subMenu3 : this.subMenu2
      menuPosition =
        this.isTop && this.isLeft
          ? menuPosition[2]
          : this.isTop && !this.isLeft
          ? menuPosition[1]
          : !this.isTop && this.isLeft
          ? menuPosition[3]
          : menuPosition[0]
      return { top: menuPosition[n][0] + 'px', left: menuPosition[n][1] + 'px' }
    },
    toggleMenu(e) {
      const menuEl = this.$refs.quickMenu
      if (!~menuEl.className.indexOf(' active')) {
        this.$emit('toggle', true)
      } else {
        this.$emit('toggle', false)
      }
    },
    processCallback(name) {
      this.$emit('process', name)
    },
  },
}
</script>
<style lang="stylus" scope>
.menu-animate {
  -webkit-animation: bounce 1s linear 1s;
  -moz-animation: bounce 1s linear 1s;
  animation: bounce 1s linear 1s;
}
.quick-menu {
  color: #fff;
  position: fixed;
  width: 80px;
  height: 80px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  transition: all 1s ease;
  right: 30px;
  z-index: 999;
  > .menu {
    display: block;
    position: absolute;
    border-radius: 50% !important;
    width: 80px;
    height: 80px;
    text-align: center;
    display flex
    justify-content center
    align-items center
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23), 0 3px 10px rgba(0, 0, 0, 0.16);
    color: #fff;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    transition: all 1s ease;
    .core-menu {
      width: 100%;
      height: 100%;
      width: 60px;
      height: 60px;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
      -webkit-transition: all 1s ease;
      -moz-transition: all 1s ease;
      transition: all 1s ease;
      .bar {
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        transition: all 1s ease;
        width: 28px;
        height: 3px;
        background: #fff;
        position: absolute;
        top: 35%;
        margin-top: -1.5px;
        left: 16px;
        -webkit-transform-origin: 0% 50%;
        -moz-transform-origin: 0% 50%;
        -ms-transform-origin: 0% 50%;
        -o-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        &:before ,&:after{
          -webkit-transition: all 1s ease;
          -moz-transition: all 1s ease;
          transition: all 1s ease;
          content: '';
          width: 28px;
          height: 3px;
          background: #fff;
          position: absolute;
          left: 0px;
          -webkit-transform-origin: 0% 50%;
          -moz-transform-origin: 0% 50%;
          -ms-transform-origin: 0% 50%;
          -o-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
        }
        &:before{
          margin-top: 30%;
        }
        &:after {
          margin-top: 60%;
        }
      }
    }
  }
  .sub-menu{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    width: 80px;
    height: 80px;
    font-size: 30px;
    text-align: center;
    display flex
    align-items center
    justify-content center
    border-radius: 50% !important;
    a{
      outline: none;
      text-decoration: none;
      display: inline-block;
      border-radius: 50% !important;
      width: 100%;
      height: 100%;
      i {
        outline: none;
        // height: 100%;
        font-size:40px !important;
        background:transparent;
        &:before{
          vertical-align: middle;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  &.active{
    -webkit-transform: rotate(0deg)!important;
      -moz-transform: rotate(0deg)!important;
      -ms-transform: rotate(0deg)!important;
      -o-transform: rotate(0deg)!important;
      transform: rotate(0deg)!important;
      .menu{
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -o-transform: scale(0.8);
        transform: scale(0.8);
        .bar {
          top: 50%;
          margin-top: -1.5px;
          left: 50%;
          margin-left: -12px;
          -webkit-transform-origin: 50% 50%;
          -moz-transform-origin: 50% 50%;
          -ms-transform-origin: 50% 50%;
          -o-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
          -webkit-transform: rotate(405deg);
          -moz-transform: rotate(405deg);
          -ms-transform: rotate(405deg);
          -o-transform: rotate(405deg);
          transform: rotate(405deg);
              &:before {
                -webkit-transform-origin: 50% 50%;
                -moz-transform-origin: 50% 50%;
                -ms-transform-origin: 50% 50%;
                -o-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
                -webkit-transform: rotate(-450deg);
                -moz-transform: rotate(-450deg);
                -ms-transform: rotate(-450deg);
                -o-transform: rotate(-450deg);
                transform: rotate(-450deg);
                margin-top: 0px;
              }
          &:after{
            opacity: 0;
          }
        }
      }
    }
}
@-webkit-keyframes bounce {
  0%,100% {
    -webkit-transform: translateY(0px);
  }
  10% {
    -webkit-transform: translateY(6px);
  }
  30% {
    -webkit-transform: translateY(-4px);
  }
  70% {
    -webkit-transform: translateY(3px);
  }
  90% {
    -webkit-transform: translateY(-2px);
  }
}
@-moz-keyframes bounce {
  0%,100% {
    -moz-transform: translateY(0px);
  }
  10% {
    -moz-transform: translateY(6px);
  }
  30% {
    -moz-transform: translateY(-4px);
  }
  70% {
    -moz-transform: translateY(3px);
  }
  90% {
    -moz-transform: translateY(-2px);
  }
}
@keyframes bounce {
  0%,100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  10% {
    -webkit-transform: translateY(6px);
    -moz-transform: translateY(6px);
    -ms-transform: translateY(6px);
    -o-transform: translateY(6px);
    transform: translateY(6px);
  }
  30% {
    -webkit-transform: translateY(-4px);
    -moz-transform: translateY(-4px);
    -ms-transform: translateY(-4px);
    -o-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  70% {
    -webkit-transform: translateY(3px);
    -moz-transform: translateY(3px);
    -ms-transform: translateY(3px);
    -o-transform: translateY(3px);
    transform: translateY(3px);
  }
  90% {
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
    transform: translateY(-2px);
  }
}
</style>
