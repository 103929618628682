import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import Vuex from 'vuex'
import { FaceDetectionEnum } from '@base/enum/apply'
import { IOption } from '@base/interface/common'
import { getIfDelayNotification } from '@base/api/common'
const store = new Vuex.Store({})

export interface ILivenessResult {
  hasTakePhoto: boolean
  isLocalImg: boolean
  photoName: string
  photoUrl: string
}

export interface IBankInfo {
  bankCode: string
  accountNumber: string
}

interface IFlexibleLoan {
  minLoanAmount: number
  maxLoanAmount: number
  selectedTenor: number
  loanPlanValid: boolean
  selectedTenorList: number[]
}

@Module({ dynamic: true, store, name: 'apply' })
class ApplyStore extends VuexModule {
  bankInfo: IBankInfo = {
    bankCode: '',
    accountNumber: '',
  }
  loanPurpose = ''
  loanPurposeList: IOption[] = []
  delayNotification = false
  selectedProductIds: string[] = []
  faceDetectionType = ''
  livenessResultInfo: ILivenessResult = {
    hasTakePhoto: false,
    isLocalImg: false,
    photoName: '',
    photoUrl: '',
  }
  creditCode = ''
  /** pre audit reject data */
  rejectData = {
    message: '',
  }
  agreement = {
    productId: '',
    pageName: '',
    isChecked: true,
  }
  updateLoanIntentionBranch = 'A'
  IOHFilledBankAccount = false
  routeQuery = {}

  flexibleLoanData: IFlexibleLoan = {
    selectedTenor: 0,
    minLoanAmount: 0,
    maxLoanAmount: 0,
    loanPlanValid: true,
    selectedTenorList: [],
  }

  prevSelectedCouponIds: string[] = []

  get getAgreement() {
    return this.agreement
  }
  @Mutation
  saveDelayNotification(payload: boolean) {
    this.delayNotification = payload
  }

  @Action({ commit: 'saveDelayNotification' })
  async fetchIfDelayNotification() {
    try {
      const { data } = await getIfDelayNotification()
      return data === 'true'
    } catch (error: any) {
      window.vue.$handleError(error)
    }
  }

  @Mutation
  updateFaceDetectionType(type: FaceDetectionEnum) {
    this.faceDetectionType = type
  }

  @Mutation
  updateLivenessResult(payload: ILivenessResult) {
    this.livenessResultInfo = payload
  }

  @Mutation
  updateRejectData(payload: { message: string }) {
    this.rejectData = payload
  }

  @Mutation
  updateCreditCode(payload: string) {
    this.creditCode = payload
  }

  @Mutation
  saveAgreementCheck(agreement: any) {
    this.agreement = agreement
  }

  @Mutation
  updateLoanIntention(payload: string) {
    this.updateLoanIntentionBranch = payload
  }

  @Mutation
  saveIOHFilledBankAccount(data: boolean) {
    this.IOHFilledBankAccount = data
  }

  @Mutation
  saveApplyLoanRouteQuery(data: Object) {
    this.routeQuery = data
  }

  @Action({ commit: 'saveBankInfo' })
  updateBankInfo(payload: IBankInfo) {
    return payload
  }

  @Mutation
  saveBankInfo(payload: IBankInfo) {
    this.bankInfo = payload
  }

  @Mutation
  saveLoanPurpose(payload: string) {
    this.loanPurpose = payload
  }

  @Mutation
  saveLoanPurposeList(payload: IOption[]) {
    this.loanPurposeList = payload
  }

  @Mutation
  saveSelectProductIds(payload: string[]) {
    this.selectedProductIds = payload
  }

  @Mutation
  saveFlexibleLoanData(payload: IFlexibleLoan) {
    this.flexibleLoanData = payload
  }

  @Mutation
  savePrevSelectedCouponIds(payload: string[]) {
    this.prevSelectedCouponIds = payload
  }
}
export const ApplyModule = getModule(ApplyStore)
