import type { ABTestParams, IAreaParams, IOption } from '@base/interface/common'
import { IUser } from '@base/interface/user'
import ajax, { IosInstance } from '@base/lib/ajax'
import { getItem } from '@base/lib/storage-handler'
import { isApp } from '@base/lib/utils'
import { isOtherChannel } from '@base/lib/channelUtil'

interface IParams {
  types: string[]
}

export const getAbtest = (params: ABTestParams) => {
  return ajax.get(`/abTest/${params.name}/group/calculate`, {
    params: {
      refId: params.id,
      refType: params.type,
    },
  })
}

// check face
export const checkFace = (payload: { form?: null | FormData }) => {
  // return ajax.post('/user/image/face-detection-result', payload.form)
  return IosInstance.post('/user/image/face-detection-result', payload.form)
}

/**
 *
 * @param payload
 */
export const uploadImage = <T>(payload: { type: string; form: null | FormData; forceSave?: boolean }) => {
  // return ajax.post<T>(
  //   `/user/image/${payload.type}/v2` + (payload.forceSave ? `?forceSave=${payload.forceSave}` : ''),
  //   payload.form,
  // )
  return IosInstance.post<T>(
    `/user/image/${payload.type}/v3` + (payload.forceSave ? `?forceSave=${payload.forceSave}` : ''),
    payload.form,
  )
}

/**
 *  params: types
 */
export const getMultiEnum = (params: IParams) =>
  ajax.get<{
    [k: string]: Array<IOption>
  }>('/enums/multi-types', { params })

/**
 *
 */
export const getUserInfo = () => {
  if (!isApp() && isOtherChannel()) {
    const params = {
      token: getItem('channelToken'),
    }
    return ajax.get<IUser>('/user/v2', { params })
  } else {
    return ajax.get<IUser>('/user/v2')
  }
}

export const getKtpAreaList = (params?: IAreaParams) =>
  ajax.get<string[]>('/area/sub-ktp-area', {
    params: {
      province: params?.province,
      city: params?.city,
      district: params?.district,
    },
  })

// effortless esign
export const getEffortlessInfo = () => ajax.get('/contract-center/e-sign/effortless')

export const acceptUserAgreement = (channel: string) =>
  ajax.post(`/pop-notify/accept-user-agreement${channel ? '?tncType=' + channel : ''}`)

export const getlinkAccountStatus = () => ajax.get('/crawler/list-website/authorized')

export const getDynamicUserInfo = () => {
  return ajax.get('/user/v3')
}

export const fetchDynamicUserInfo = (data: any) => {
  return ajax.post('/user/v3', data)
}

//后端返回当前渠道是否可以使用getUsermedia API： Boolean
export const getUseGetUsermedia = () => {
  return ajax.get('/variable/channel-need-old-version')
}

export const getTopupConfig = () => {
  return ajax.get('/operation/top-up/configs')
}

export const getWpsInsuranceUrl = () => {
  return ajax.post('/wps/generate-weblinks')
}

export const fetchDeviceInfo = (data: any) => {
  return ajax.post('/device-info/upload', data, {
    headers: { 'Content-Encoding': 'gzip', 'Content-Type': 'application/json' },
  })
}

export const getTabRed = () => ajax.get(`/global-status/v2?date-value=${new Date().valueOf()}`)

export const getIfDelayNotification = () => ajax.get<string>('/variable/delay-notification-status')

// H5 logout
export const logout = () => ajax.post('/auth/logout')
