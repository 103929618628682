<template>
  <md-popup v-model="isPopupShow" class="popup-alert" :mask-closable="true" @hide="hideHandler">
    <div class="content">
      <div class="popup-wrapper">
        <div class="popup-img-wrapper">
          <md-icon class="popup-icon" :name="popupType.icon || 'icon_bell'" svg />
          <md-icon class="popup-icon-close" :name="popupType.closeIcon || ''" svg @click="hideHandler" />
        </div>
        <div class="title">{{ popupType.title }}</div>
        <div class="message">
          {{ popupType.message }}
        </div>
        <div class="foot-wrapper">
          <div class="button-ok">
            <md-button class="done" @click="doneHandler">Done</md-button>
            <md-button class="copy" @click="copyHandler" ref="copyBtn" :data-clipboard-text="popupType.message">{{
              popupType.buttonText
            }}</md-button>
          </div>
        </div>
      </div>
    </div>
  </md-popup>
</template>

<script>
import Clipboard from 'clipboard'
import { Action } from '@pintar/id-track-event'

export default {
  name: 'PopupCopy',
  model: {
    prop: 'isShow',
    event: 'close',
  },
  props: {
    isShow: { type: Boolean, default: false },
    type: { type: String, default: '' },
    title: { type: String, default: '' },
    message: { type: [String, Array], default: '' },
    buttonText: { type: String, default: 'Copy' },
  },
  data() {
    return {
      isPopupShow: false,
    }
  },
  computed: {
    isCouponExpried() {
      return this.popupType.type === 'COUPON_EXPIRED'
    },
    allTypes() {
      return [
        //
        {
          type: 'COPY',
          title: '',
          icon: 'icon_bell',
          message: this.$t('successMessage'),
          buttonText: this.$t('ok'),
        },
      ]
    },
    popupType() {
      const temp =
        this.allTypes.filter((item) => {
          return item.type === this.type.toUpperCase()
        })[0] || {}
      const message = this.message ? this.message : temp.message
      return {
        icon: temp.icon,
        closeIcon: temp.closeIcon,
        type: 'COPY',
        title: this.title ? this.title : temp.title,
        message: message,
        buttonText: this.buttonText ? this.buttonText : temp.buttonText,
      }
    },
  },
  watch: {
    isShow: {
      handler(val) {
        this.isPopupShow = this.isShow
      },
      immediate: true,
    },
  },
  methods: {
    addCloseEvent() {
      this.$emit('addCloseEvent')
    },
    hideHandler() {
      this.addCloseEvent()
      this.$emit('hide')
      this.closeHandler()
    },
    closeHandler() {
      this.isPopupShow = false
      this.$emit('close', false)
    },
    submitHandler() {
      this.$emit('submit')
      this.closeHandler()
    },
    doneHandler() {
      this.$eventTrack({
        action: Action.DoneButtonClick,
      })
      this.hideHandler()
    },
    copyHandler() {
      const clipboard = new Clipboard('.copy')
      clipboard.on('success', () => {
        this.$eventTrack({
          action: Action.CopyButtonClick,
        })
        this.$toast({
          content: this.$t('copyOk'),
        })
        this.$emit('copy')
        this.closeHandler()
      })
      clipboard.on('error', () => {
        console.log('Copy error!')
      })
    },
  },
  i18n: {
    messages: {
      'en-us': {
        submit: 'Submit',
        ok: 'OK',
        successMessage: 'KPintar customer service team has received your issue and will resolve it as soon as possible',
        copyOk: 'Account no is copied',
      },
      id: {
        submit: 'Ajukan',
        ok: 'OK',
        successMessage:
          'Tim Layanan Pelanggan KPintar telah menerima permasalahan Anda dan akan segera menyelesaikannya',
        copyOk: 'Nomor rekening telah disalin',
      },
    },
  },
}
</script>

<style lang="stylus" scoped>
.popup-alert
  z-index 9999
.content
  width 100vw
.popup-wrapper
  background-color white
  margin auto 80px
  padding 46px 48px 56px
  border-radius 24px
  box-shadow 0px 4px 16px 0px rgba(0,0,0,0.08)
  .popup-img-wrapper
    display flex
    justify-content left
    align-items center
  .popup-img-wrapper-new
    display flex
    justify-content space-between
    align-items flex-start
  .popup-icon
    width 76px
    height 76px
    margin 0px 0 30px
  .popup-icon-new
    width 80px
    height 80px
    margin 0px
  .popup-icon-close
    width 36px
    height 36px
  .title
    font-size 32px
    line-height 44px
    color #666666
    fontBold()
    margin-bottom 16px
  .message
    font-weight 500
    font-size 40px
    line-height 56px
    margin-bottom 8px
    color #101010
  .message-new
    margin-top 24px
    color #000
    line-height 48px
    font-size 32px
    margin-bottom 40px
  .foot-wrapper
    margin-top 48px
    overflow auto
    width 100%
    line-height 40px
    .button-ok
      font-size 28px
      line-height 28px
      float right
      display flex
      .copy
        color #ffffff
        background #1CC812
        margin-left 24px
    .button-re-submit
      background #1cc812
      color #fff
      font-family Inter-Medium, Inter
      padding 16px 45px
      line-height 32px
      border-radius 12px
  .foot-wrapper-new
    margin-top 88px
</style>
