import { commonBridgeFn } from '@base/lib/common-bridge'
import { isApp } from '@pintar/id-track-event/lib/utils'
import _Vue from 'vue'

export const nativeToast = (params: { content: string; type?: string }) => {
  if (!isApp()) return
  const _params = {
    content: params.content,
    type: params.type || 'NORMAL',
  }
  console.log('nativeToast', _params)
  commonBridgeFn('toast', false, _params)
  // if (isIosApp()) {

  // } else {
  //   const AppVersionCode = commonBridgeFn('getAppVersionCode', false)
  //   if (AppVersionCode >= 313) {
  //     commonBridgeFn('toast', false, params)
  //   } else {
  //     const content = params.content
  //     commonBridgeFn('toast', false, { content })
  //   }
  // }
}

export default {
  install(Vue: typeof _Vue, options: any = {}) {
    Vue.nativeToast = (params: { content: string; type?: string }) => nativeToast(params)
    Vue.prototype.$nativeToast = Vue.nativeToast
  },
}
