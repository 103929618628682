import { ChannelEnum } from '@base/enum/channel'
import { AD_CHANNEL, UTM_SOURCE } from '@base/lib/constant'
import { getParams } from '@base/lib/channelUtil'
import { getSession } from '@base/lib/storage-handler'

export const removeQuote = (str: string) => {
  const reg = /['|"](.*?)/g
  return str.replace(reg, '')
}

/**
 * URL 中获取的 channel
 * @returns
 */
export const getUrlChannel = () => {
  let originChannel: string | undefined = location.href.split('/')[3]
  if (originChannel.includes('?')) {
    originChannel = originChannel.match(/(\S*)\?/)?.[1]
  }
  return originChannel ? removeQuote(originChannel) : ChannelEnum.H5
}

/**
 * h5活体拼接url时,channel过期后则从url里面拿
 * @returns
 */
export const getReallyChannel = () => {
  return getLocalChannel() || getUrlChannel()
}

/**
 * 本地缓存获取的 channel
 * @returns
 */
export const getLocalChannel = () => {
  return getSession(AD_CHANNEL) || ''
}

/**
 * 上传给服务器+埋点使用到的 channel
 * @returns
 */
export const getChannel = () => {
  const localChannel = getLocalChannel()
  return localChannel ? removeQuote(localChannel) : getUrlChannel().toUpperCase()
}

/**
 * 1、UTM_SOURCE === 'OfficialWebsite'，不调接口, 将 localChannel 替换掉 urlChannel
 * 2、AD_CHANNEL 和 UTM_SOURCE 都不存在，不调接口
 * 3、urlChannel 和 localChannel 不同，且 AD_CHANNEL 和 UTM_SOURCE 都不存在，不调接口, 将 localChannel 替换掉 urlChannel
 * 4、url 中 query 参数存在 AD_CHANNEL 或 UTM_SOURCE 且 UTM_SOURCE !== 'OfficialWebsite'，调接口
 */
export const checkLocalChannel = () => {
  const adChannel = getParams(AD_CHANNEL)
  const utmSource = getParams(UTM_SOURCE)
  const urlChannel = getUrlChannel()
  const localChannel = getLocalChannel()

  if (!localChannel) return false

  if (utmSource === 'OfficialWebsite') {
    location.href = location.href.replace(urlChannel, localChannel)
    return true
  }
  if (!adChannel && !utmSource) {
    if (urlChannel !== localChannel) location.href = location.href.replace(urlChannel, localChannel)
    return true
  }
  return false
}
