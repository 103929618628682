import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import VueI18n from 'vue-i18n'
import './lib/icons'
import filters from '@base/lib/filters'
import errorHandler from './lib/error-handler'
import KpUi from './lib/kp-ui'
import apiComponents from '@/lib/api-components'
import Loading from '@/components/loading'
import { Component } from 'vue-property-decorator'
import PortalVue from 'portal-vue'
import { eventInstall } from '@pintar/id-track-event'
import { fetchUserInfoFromLocal } from './lib/utils'
import locale from './locale'
import { initSentryConfig, setGlobalConfig } from '@base/lib/sentry-utils'
import pkg from '../../../package.json'
import dsBridge from '@base/plugins/dsbridge'
import NativeToast from '@base/lib/native-toast'

fetchUserInfoFromLocal()

window.addEventListener('DOMContentLoaded', () => {
  require('web-streams-polyfill')
  require('@base/assets/css/font-family.styl')
  require('@base/assets/css/reset.styl')
  require('@base/assets/css/common.styl')
  require('swiper/dist/css/swiper.css')
})

Vue.use(
  eventInstall,
  {
    router,
    bridge: window.dkBridge || dsBridge,
    pageEventEnabled: true,
    pageEventIgnoreRoutes: [],
    webVersionCode: pkg.version,
  },
  {
    immediately: true,
    /* Web SDK is not supported in Web View of Android or iOS Mobile App */
    eventTrackUrl: process.env.VUE_APP_EVENT_TRACK_URL,
    sendLogUrl: process.env.VUE_APP_SEND_LOG_URL,
  },
)

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'en-us',
  messages: locale,
  silentTranslationWarn: true,
})

if (process.env.VUE_APP_ENV === 'production' || process.env.VUE_APP_ENV === 'staging') {
  // initLog(pkg.version)
  initSentryConfig(Vue, router)
  setGlobalConfig()
}

const validator = require('@/lib/validator').default
Vue.use(validator, { local: 'en' })
Vue.use(PortalVue)
Vue.use(KpUi)
Vue.use(errorHandler)
Vue.use(NativeToast)
Vue.use(filters)
Vue.use(apiComponents)
Vue.config.productionTip = false

Vue.prototype.$loading = Loading
!Vue.prototype.$eventTrack && (Vue.prototype.$eventTrack = () => {})
Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate'])

window.vue = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
