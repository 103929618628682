// me
const MySavingAccount = () =>
  import(/* webpackChunkName: "mySavingAccount" */ '@base/views/my-account/my-saving-account/index.vue')
const CardAccountDetail = () =>
  import(/* webpackChunkName: "cardAccountDetail" */ '@base/views/my-account/my-saving-account/AccountDetail.vue')
const MyRefCode = () => import(/* webpackChunkName: "MyRefCode" */ '@base/views/my-account/RefCode.vue')
const ManageAccount = () =>
  import(/* webpackChunkName: "ManageAccount" */ '@base/views/me/setting/security/manage-account/index.vue')

const MeRoute = [
  {
    name: 'MySavingAccountPage',
    path: 'me/my-account/saving-account',
    component: MySavingAccount,
    meta: {
      backTo: 'myAccount',
    },
  },
  {
    name: 'CardAccountDetailPage',
    path: 'me/my-account/card-account-detail',
    component: CardAccountDetail,
  },
  {
    name: 'MyRefCodePage',
    path: 'me/my-account/ref-code',
    component: MyRefCode,
  },
  {
    name: 'ManageAccount',
    path: 'me/setting/security/manage-account',
    component: ManageAccount,
  },
]

export default MeRoute
