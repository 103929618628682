const DBRAlert = () => import(/* webpackChunkName: "DeleteAccount" */ '../views/dbr-alert/index.vue')

const DBRAlertRoute = [
  {
    name: 'DBRExceedNotification',
    path: 'dbr-alert',
    component: DBRAlert,
  },
]

export default DBRAlertRoute
