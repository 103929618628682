import { getUserType } from '@/api/user'
import { getUserInfo } from '@base/api/common'
import { setItem } from '@base/lib/storage-handler'
import { trackEvent } from '@pintar/id-track-event'

export default {
  namespaced: true,
  state: {
    mobileNumber: null,
    idPhoto: null,
    idNumber: null,
    fullName: null,
    handHeldPhoto: null,
    facePhoto: null,
    // basic
    email: null,
    birthPlace: null,
    maritalStatus: null,
    education: null,
    dependents: null,
    motherMaidenName: null,
    // emergency
    contactFullName: null,
    contactRelation: null,
    contactPhoneNumber: null,
    contactFullName2: null,
    contactRelation2: null,
    contactPhoneNumber2: null,
    // residential
    residentialStatus: null,
    residentialHomePhone: null,
    residentialAddress: null,
    residentialProvince: null,
    residentialCity: null,
    residentialDistrict: null,
    residentialVillage: null,
    residentialZipCode: null,
    // work
    jobType: null,
    jobIndustry: null,
    jobPosition: null,
    employerName: null,
    workSince: null,
    monthlyIncome: null,
    businessPhone: null,
    businessAddress: null,
    // account
    paymentBankCode: null,
    paymentBankAccountNo: null,
    paymentBankName: null,
    loanPurpose: null,
    npwp: null,
    // facebookBinding
    facebookBinding: false,
    // repeatUser
    repeatUser: false,
    userType: 0,
    userId: '',
  },
  getters: {
    paymentBankCode: (state: any) => state.paymentBankCode,
    mobileNumber: (state: any) => state.mobileNumber,
    email: (state: any) => state.email,
    idPhoto: (state: any) => state.idPhoto,
    idHoldingPhoto: (state: any) => state.idHoldingPhoto,
    idNumber: (state: any) => state.idNumber,
    fullName: (state: any) => state.fullName,
    loanPurpose: (state: any) => state.loanPurpose,
    repeatUser: (state: any) => state.repeatUser,
    userType: (state: any) => state.userType,
  },
  mutations: {
    saveUserinfo(state: any, data: any) {
      Object.keys(state).forEach((key: any) => {
        state[key] = data[key]
      })
    },
    saveUserType(state: any, data: any) {
      state.userType = data.type
      state.userId = data.userId
      setItem(
        'userInfo',
        JSON.stringify({
          userId: data.userId,
          userType: data.type,
        }),
      )
      trackEvent.updateDeviceInfo({
        userId: data.userId,
        userType: data.type,
      })
    },
  },
  actions: {
    async updateUserinfo({ commit }: any, type: any) {
      const { data } = await getUserInfo()
      commit('saveUserinfo', data)
      return data
    },
    async updateUserType({ commit }: any, type: any) {
      const { data } = await getUserType()
      commit('saveUserType', data)
      return data
    },
  },
}
