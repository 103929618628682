import { Toast } from 'mand-mobile'

Toast.succeed = (
  content: string,
  duration?: number | undefined,
  hasMask?: boolean | undefined,
  parentNode?: Element | undefined,
  square?: boolean | undefined,
) => {
  Toast({
    icon: 'icon_toast_succeed',
    iconSvg: true,
    content,
    duration,
    hasMask,
    parentNode,
    square,
    position: 'bottom',
  } as any)
}

Toast.failed = (
  content: string,
  duration?: number | undefined,
  hasMask?: boolean | undefined,
  parentNode?: Element | undefined,
  square?: boolean | undefined,
) => {
  Toast({
    icon: 'icon_toast_fail',
    iconSvg: true,
    content,
    duration,
    hasMask,
    parentNode,
    square,
    position: 'bottom',
  } as any)
}

Toast.info = (
  content: string,
  duration?: number | undefined,
  hasMask?: boolean | undefined,
  parentNode?: Element | undefined,
) => {
  Toast({
    content,
    duration,
    hasMask,
    parentNode,
    position: 'bottom',
  } as any)
}

export default Toast
