var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('md-popup',{staticClass:"popup-alert",attrs:{"mask-closable":_vm.customClose},on:{"hide":_vm.hideHandler},model:{value:(_vm.isPopupShow),callback:function ($$v) {_vm.isPopupShow=$$v},expression:"isPopupShow"}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"popup-wrapper"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.popupType.type !== 'NORMAL'),expression:"popupType.type !== 'NORMAL'"}],class:{
          'popup-img-wrapper': true,
          'popup-img-wrapper-security': _vm.isAccountSecurity,
          'popup-img-wrapper-new': _vm.isWrapperNew,
        }},[_c('md-icon',{class:{
            'popup-icon': true,
            'popup-icon-new': _vm.isWrapperNew,
          },attrs:{"name":_vm.popupType.icon || 'icon_pop_info',"svg":""}}),(!_vm.popupType.hideCloseIcon)?_c('md-icon',{staticClass:"popup-icon-close",attrs:{"name":_vm.popupType.closeIcon || '',"svg":""},on:{"click":_vm.hideHandler}}):_vm._e()],1),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.popupType.title))]),(_vm.customeWrapper.aLabel)?_c('div',[_c('div',{staticClass:"message"},[_vm._v(" "+_vm._s(_vm.message)+" "),_c('a',{staticClass:"aLabel",attrs:{"href":_vm.customeWrapper.terms.href},on:{"click":function($event){return _vm.touchEvent(_vm.customeWrapper.terms.event)}}},[_vm._v(" "+_vm._s(_vm.customeWrapper.terms.name)+" ")]),_vm._v(" "+_vm._s(_vm.customeWrapper.and)+" "),_c('a',{staticClass:"aLabel",attrs:{"href":_vm.customeWrapper.policy.href},on:{"click":function($event){return _vm.touchEvent(_vm.customeWrapper.policy.event)}}},[_vm._v(" "+_vm._s(_vm.customeWrapper.policy.name)+" ")])])]):_c('div',{class:{ 'message-wrapper': true, 'message-pc': _vm.isPc }},_vm._l((_vm.popupType.message),function(item,index){return _c('div',{key:("message-" + index),class:{
            message: true,
            'message-new': _vm.isWrapperNew,
          }},[_vm._v(" "+_vm._s(item)+" "),_c('br')])}),0),_c('div',{class:{
          'foot-wrapper': true,
          'foot-wrapper-new': _vm.isWrapperNew || _vm.isAccountSecurity,
        }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasLeftBtn),expression:"hasLeftBtn"}],staticClass:"left-btn",on:{"click":_vm.signOutHandle}},[_vm._v(_vm._s(_vm.$t('Logout')))]),(_vm.popupType.showCancelButton)?_c('div',{class:{
            'button-ok': true,
            'button-cancel': true,
          },on:{"click":_vm.hideHandler}},[_vm._v(" "+_vm._s(_vm.popupType.buttonCancelText)+" ")]):_vm._e(),_c('div',{class:{
            'button-ok': true,
            'button-re-submit': _vm.isWrapperNew || _vm.isAccountSecurity,
            disabled: this.isSubmitButtonDisabled,
          },on:{"click":_vm.submitHandler}},[_vm._v(" "+_vm._s(_vm.popupType.buttonText)+" "+_vm._s(_vm.popupType.showCountdown && this.countdown > 0 ? ("(" + (this.countdown) + "s)") : '')+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }