
import { Component, Prop } from 'vue-property-decorator'
import { Component as VueComp } from 'vue-tsx-support'
import { Action } from '@pintar/id-track-event'

interface IProps {
  always?: boolean
}

@Component
export default class AutoForm extends VueComp<IProps> {
  @Prop({
    type: Boolean,
    default: false,
  })
  always!: boolean

  private comps: any[] = []

  get eventType() {
    return this.always ? '$on' : '$once'
  }

  //
  handler(comp: any, type: null | string = null) {
    if (!comp) return
    const { instance, index } = comp
    const currentType = instance.$attrs['at-type']
    if (currentType === 'selector') {
      instance[this.eventType]('input', () => {
        this.nextHandler(this.comps[index + 1], currentType)
      })
    }
    if (currentType === 'input') {
      instance[this.eventType]('blur', () => {
        this.nextHandler(this.comps[index + 1], currentType)
      })
    }
  }

  //
  nextHandler(comp: any, type: null | string = null) {
    if (!comp) return
    const { instance } = comp
    const currentType = instance.$attrs['at-type']
    if (currentType === 'selector') {
      //
      const name = instance.$attrs['data-vv-name'].replace(/\b(\w)(\w*)/g, ($0: any, $1: any, $2: any) => {
        return $1.toUpperCase() + $2
      })
      this.$eventTrack({
        action: Action.SelectorClick,
        extra: {
          field: name,
        },
      })
      type === currentType && instance?.showPicker()
    }
    // if (currentType === 'input') {
    //   type === currentType && instance?.focus()
    // }
  }

  //
  recursionComp(vnodes: any) {
    if (!vnodes || vnodes.length === 0) return []
    const res: any[] = []
    vnodes.forEach((vnode: any) => {
      if (vnode.children && vnode.children.length) {
        res.push(...this.recursionComp(vnode.children))
      } else if (vnode.componentOptions && vnode.componentOptions.children && vnode.componentOptions.children.length) {
        res.push(...this.recursionComp(vnode.componentOptions.children))
      } else {
        res.push(vnode)
      }
    })
    return res
  }

  mounted() {
    const vnodes = this.recursionComp(this.$slots.default)
    this.comps = vnodes
      .filter((item) => item.componentInstance && item.componentInstance.$attrs['at-type'])
      .map((item, index) => ({
        instance: item.componentInstance,
        index: index,
      }))
    if (this.comps && this.comps.length > 0) {
      this.comps.forEach((comp) => this.handler(comp))
    }
  }

  render() {
    return <div>{this.$slots.default}</div>
  }
}
