// Jago
const JagoEntry = () => import(/* webpackChunkName: "JagoEntry" */ '@base/views/jago-account/index.vue')
const JagoLandingPage = () => import(/* webpackChunkName: "JagoEntry" */ '@base/views/jago-account/JagoStatus.vue')
const JagoLoginForm = () => import(/* webpackChunkName: "JagoEntry" */ '@base/views/jago-account/JagoLoginForm.vue')
const JagoHelpList = () => import(/* webpackChunkName: "JagoHelpList" */ '@base/views/jago-account/HelpList.vue')
const JagoHelpDetail = () => import(/* webpackChunkName: "JagoHelpList" */ '@base/views/jago-account/HelpDetail.vue')
const CommonDownloadApp = () =>
  import(/* webpackChunkName: "CommonDownloadApp" */ '@base/components/CommonDownloadApp.vue')
const JagoTnC = () => import(/* webpackChunkName: "JagoTnC" */ '@base/views/jago-account/JagoTnC.vue')
const JagoFatcaCrs = () => import(/* webpackChunkName: "JagoFatcaCrs" */ '@base/views/jago-account/jagoFatcaCrs.vue')
const JagoConsent = () => import(/* webpackChunkName: "JagoConsent" */ '@base/views/jago-account/JagoConsent.vue')

const JagoRoute = [
  {
    name: 'JagoEntryPage',
    path: 'jago/entry',
    component: JagoEntry,
    meta: {
      cantGoHack: true,
      backTo: 'mySavingAccount',
    },
  },
  {
    name: 'JagoLandingPage',
    path: 'jago/callback',
    component: JagoLandingPage,
  },
  {
    name: 'JagoLoginFormPage',
    path: 'jago/register',
    component: JagoLoginForm,
  },
  {
    name: 'JagoHelpListPage',
    path: 'jago/jago-help-list',
    component: JagoHelpList,
  },
  {
    name: 'JagoHelpDetailPage',
    path: 'jago/jago-help-detail',
    component: JagoHelpDetail,
  },
  {
    name: 'JagoDownloadLatestApp',
    path: 'jago/download-app',
    component: CommonDownloadApp,
  },
  {
    name: 'JagoTnCPage',
    path: 'jago/tnc',
    component: JagoTnC,
  },
  {
    name: 'JagoFatcaCrsPage',
    path: 'jago/FatcaCrs',
    component: JagoFatcaCrs,
  },
  {
    name: 'JagoConsentPage',
    path: 'jago/UserConsent',
    component: JagoConsent,
  },
]

export default JagoRoute
