import Vue from 'vue'
import Vuex from 'vuex'
import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
Vue.use(Vuex)
const store = new Vuex.Store({})

interface ICoupon {
  couponStayPopup: boolean
  type: string
}

@Module({ dynamic: true, store, name: 'stayPopupActivitives' })
class StayPopup extends VuexModule {
  couponStayPopup = false
  couponType = ''
  couponShowPopup = false
  @Mutation
  setCouponStayPopup({ couponStayPopup, type }: ICoupon) {
    this.couponStayPopup = couponStayPopup
    this.couponType = type
    this.couponShowPopup = false
  }
  @Mutation
  setCouponShowPopup(isShow: boolean) {
    this.couponShowPopup = isShow
  }
  @Action({ commit: 'setCouponStayPopup' })
  updateCouponStayPopup({ couponStayPopup, type }: ICoupon) {
    return { couponStayPopup, type }
  }
  @Action({ commit: 'setCouponShowPopup' })
  showCouponStayPopup() {
    return true
  }
}
export const StayPopupModule = getModule(StayPopup)
