<template>
  <div v-if="isShow" class="loading">
    <img class="loading-img" src="@base/assets/images/common/loading.gif" />
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="stylus" scoped>
.loading
  position fixed
  top 0px
  left 0px
  right 0px
  bottom 0px
  width 100%
  height 100vh
  width 100vw
  z-index 99999
  display flex
  align-items center
  justify-content center
  .loading-img
    height 160px
    width 160px
    border-radius 40px
    box-shadow 0px 8px 16px 0px rgba(0, 0, 0, 0.24)
</style>
