const message = {
  'en-us': {
    loading: 'Loading',
    faq: [
      {
        name: 'BCA',
        code: 'BCA',
        details: [
          {
            type: 'ATM',
            steps: [
              'Insert your BCA ATM card and PIN',
              'Select menu "Other transaction"',
              'Select "Transfer"',
              'Select "To BCA Virtual Account"',
              'Insert the amount to transfer',
            ],
          },
          {
            type: 'Internet Banking',
            steps: [
              'Log in on KlikBCA',
              'Insert User ID and PIN',
              'Select "Transfer Dana"',
              'Select "transfer to BCA virtual Account"',
              'Select BCA virtual account or choose from transfer list',
              'Insert the amount to transfer',
            ],
          },
          {
            type: 'Mobile Banking',
            steps: [
              'Log in on BCA mobile',
              'Select "m-BCA" insert your access code m-BCA',
              'Select "m-Transfer"',
              'Select "BCA virtual Account"',
              'Select BCA virtual account or choose from transfer list',
              'Insert the amount to transfer',
            ],
          },
          {
            type: 'Other ATM to Bank BCA',
            steps: [
              'Insert your ATM card',
              'Enter your ATM PIN',
              'Select  "TRANSFER" on the menu',
              'Select the menu "Transfer between banks, then enter the bank code"',
              'Enter the Virtual Account number in the Kredit Pintar application, then select "TRUE"',
              'Make sure your Virtual Account data is correct, then enter the number you need to pay, then select "TRUE"',
              'Transaction is complete',
            ],
          },
        ],
      },
      {
        name: 'Bank Mandiri',
        code: 'MANDIRI',
        details: [
          {
            type: 'Mandiri ATM',
            steps: [
              'Insert your ATM card and select "ENGLISH"',
              'Enter PIN, then select "ENTER"',
              'Select "Others"(if this doesn’t appear on your screen, continue to next step)',
              'Select "PAYMENT"',
              'Select "MULTI PAYMENT"',
              'Enter company code: "89251" (Instamoney 89251), then press "CORRECT"',
              'Enter Virtual Account Number, then press "CORRECT"',
              'Enter the amount to transfer, then press "CORRECT"',
              'Customer details will be displayed. Choose number 1 according to the amount billed and then press "YES"',
              'Payment confirmation will be displayed. Select "YES" to proceed',
              'Keep your receipt as proof of payment',
              'Your transaction is successful',
            ],
          },
          {
            type: 'Mandiri Internet Banking',
            steps: [
              'Go to Mandiri Internet Banking website <a style="word-break:break-all">https://ib.bankmandiri.co.id/retail/Login.do?action=form</a>',
              'Login with your USER ID and PIN',
              'Select "Payment"',
              'Select "Multi Payment"',
              'Select "My Account Number"',
              'Select "Instamoney 89251" as service provider',
              'Select "Virtual Account Number"',
              'Enter your virtual account number',
              'Go to confirmation page 1',
              'Click on TOTAL if all details are correct and then click on "CONTINUE"',
              'Go to confirmation page 2',
              'Enter Challenge Code from your Internet Banking Token, then click on "Send"',
              'You will be directed to the confirmation page once your payment has been completed',
            ],
          },
          {
            type: 'Mandiri Mobile Banking',
            steps: [
              'Log in Mobile Banking Mandiri Online (download the latest version)',
              'Click "Icon Menu" in top left side',
              'Select "Payment"',
              'Select "Make New Payment"',
              'Select "Multi Payment"',
              'Select "Instamoney 89251" as service provider',
              'Select "Virtual Account Number"',
              'Enter your virtual account number with "89251" as Company Code, then select "Add as New Number"',
              'Input "Nominal" then select "Confirmation"',
              'Select "Continue"',
              'Appear Payment Confirmation Page',
              'Scroll down in payment confirmation page then select "Confirmation"',
              'Input "PIN" and the transaction completed',
            ],
          },
        ],
      },
      {
        name: 'Other Banks to Bank Mandiri',
        code: 'MANDIRI',
        details: [
          {
            type: 'Other ATM to Bank Mandiri',
            steps: [
              'Select Others',
              'Select Transfer',
              'Select from saving',
              'Select to other Bank',
              'Enter Bank Code followed by your Virtual Account number (Mandiri 008+89251-XXXXXXXXXX)',
              'Input the Nominal Bill as Nominal Transfer',
              'Finish, Transaction Success',
            ],
          },
          {
            type: 'Other Internet Banking to Bank Mandiri',
            steps: [
              'Input User ID and Password',
              'Select Transfer',
              'Select Transfer to other bank',
              'Select bank destination',
              'Enter Virtual Account number (Mandiri 89251-XXXXXXXXXX)',
              'Input the Nominal Bill as Nominal Transfer',
              'Finish, Transaction Success',
            ],
          },
          {
            type: 'Other Mobile Banking to Bank Mandiri',
            steps: [
              'Input User ID and Password',
              'Select Transfer',
              'Select Transfer to other bank',
              'Select bank destination',
              'Enter Virtual Account number (Mandiri 89251-XXXXXXXXXX)',
              'Input the Nominal Bill as Nominal Transfer',
              'Finish, Transaction Success',
            ],
          },
        ],
      },
      {
        name: 'BNI',
        code: 'BNI',
        details: [
          {
            type: 'BNI ATM',
            steps: [
              'Select "Other Menu"',
              'Select "Transfer"',
              'Select "OTHER TRANSACTION"',
              'Select to "BNI ACCOUNT"',
              'Enter Virtual Account Number, then press "CORRECT"',
              'Enter the amount to transfer, then press "YES"',
              'Transaction confirmation completed, press "NO" to complete the transaction',
            ],
          },
          {
            type: 'BNI Internet Banking',
            steps: [
              'Select "Transaction"',
              'Select "TRANSFER ADMINISTRATION INFO"',
              'Select "SET DESTINATION ACCOUNT"',
              'Add a destination account Then click "OK"',
              'Fill in the account data and press "CONTINUE"',
              'Confirmation details will appear, if correct and appropriate, enter the 8-digit number generated from APPLI 2 on your BNI token then click "PROCESS"',
              'Destination account added successfully',
              'Select "TRANSFER"',
              'Select "Transfer to BNI account", then complete all the account data of the recipient, then click "CONTINUE"',
              'Your transaction has been successful',
            ],
          },
          {
            type: 'BNI Mobile Banking',
            steps: [
              'Log in BNI Mobile Banking (download the latest version) after that, select "TRANSFER" menu',
              'Select "WITHIN BANK"',
              'Fill in the field "DEBIT ACCOUNT" then click "TO ACCOUNT” menu',
              'Select menu "AD HOC BENEFICIARY"',
              'Complete the data by filling in, NICKNAME, NO. VIRTUAL ACCOUNT and BENEFICIARY EMAIL ADDRESS',
              'Confirmation will appear then click "CONTINUE"',
              'Fill out all existing form then click "CONTINUE"',
              'The confirmation details appear by requesting the transaction password after it is completed click "CONTINUE"',
              'Your transaction has been successful',
            ],
          },
        ],
      },
      {
        name: 'Other Banks to Bank BNI',
        code: 'BNI',
        details: [
          {
            type: 'Other ATM to Bank BNI',
            steps: [
              'Select Others',
              'Select Transfer',
              'Select from saving',
              'Select to other Bank',
              'Enter Bank Code followed by your Virtual Account number (BNI 009+8808-XXXXXXXXXX)',
              'Input the Nominal Bill as Nominal Transfer',
              'Finish, Transaction Success',
            ],
          },
          {
            type: 'Other Internet Banking to Bank BNI',
            steps: [
              'Input User ID and Password',
              'Select Transfer',
              'Select Transfer to other bank',
              'Select bank destination',
              'Enter Virtual Account number (BNI 8808-XXXXXXXXXX)',
              'Input the Nominal Bill as Nominal Transfer',
              'Finish, Transaction Success',
            ],
          },
          {
            type: 'Other Mobile Banking to Bank BNI',
            steps: [
              'Input User ID and Password',
              'Select Transfer',
              'Select Transfer to other bank',
              'Select bank destination',
              'Enter Virtual Account number (BNI 8808-XXXXXXXXXX)',
              'Input the Nominal Bill as Nominal Transfer',
              'Finish, Transaction Success',
            ],
          },
        ],
      },
      {
        name: 'BRI',
        code: 'BRI',
        details: [
          {
            type: 'BRI ATM',
            steps: [
              'Select Other Transaction',
              'Select Other',
              'Select Payment menu',
              'Select Others',
              'Select BRIVA menu',
              'Input your BRI Virtual Account. (Example: 10477-XXXX XXX XXXX), then click "Yes"',
              'Payment confirmation, select "Yes" if transaction details is correct',
            ],
          },
          {
            type: 'BRI Internet Banking',
            steps: [
              'Input User Id and Password',
              'Select Payment menu',
              'Select BRIVA',
              'Select payment account',
              'Input your BRI Virtual Account number. (Example: 10477-XXXX XXX XXXX)',
              'Input the amount',
              'Input your password and the Mtoken',
            ],
          },
          {
            type: 'BRI Mobile Banking',
            steps: [
              'Log in BRI Mobile Banking (download the latest version)',
              'Select Payment menu',
              'Select BRIVA',
              'Input your BRI Virtual Account and the amount',
              'Input your Pin Number',
              'Press "OK" to continue your transaction',
              'The transaction is successful',
              'A confirmation sms will go to your phone number',
            ],
          },
        ],
      },
      {
        name: 'Other Banks to Bank BRI',
        code: 'BRI',
        details: [
          {
            type: 'Other ATM to Bank BRI',
            steps: [
              'Select Others',
              'Select Transfer',
              'Select from saving',
              'Select to other Bank',
              'Enter Bank Code followed by your Virtual Account number (BRI 002+ 10477-XXXXXXXXXX)',
              'Input the Nominal Bill as Nominal Transfer',
              'Finish, Transaction Success',
            ],
          },
          {
            type: 'Other Internet Banking to Bank BRI',
            steps: [
              'Input User ID and Password',
              'Select Transfer',
              'Select Transfer to other bank',
              'Select bank destination',
              'Enter Virtual Account number (BRI 10477-XXXXXXXXXX)',
              'Input the Nominal Bill as Nominal Transfer',
              'Finish, Transaction Success',
            ],
          },
          {
            type: 'Other Mobile Banking to Bank BRI',
            steps: [
              'Input User ID and Password',
              'Select Transfer',
              'Select Transfer to other bank',
              'Select bank destination',
              'Enter Virtual Account number (BRI 10477-XXXXXXXXXX)',
              'Input the Nominal Bill as Nominal Transfer',
              'Finish, Transaction Success',
            ],
          },
        ],
      },
      {
        name: 'Alfamart',
        code: 'ALFAMART',
        details: [
          {
            type: 'Alfa Group',
            steps: [
              'Enter your Kredit Pintar application',
              'Click the "Alfa Group" payment method',
              'Click the "get payment code" button',
              'Your Virtual Account will appear automatically in the application (only valid for 6 hours)',
              'Show your 16 digit Virtual Account payment number to the Alfa cashier, and make sure the payment is addressed to DOKU Payment which is registered under the name "Kredit Pintar"',
              'After making a payment check back on your application then look at the status of your payment, maximum 30 minutes after making payment',
              'Done',
            ],
          },
        ],
      },
    ],
    faqSimple: [
      {
        name: 'BCA',
        code: 'BCA',
        details: [
          {
            type: 'ATM',
            steps: [
              'Select "Other Transactions"',
              'Select "Transfer"',
              'Select "BCA Virtual Account"',
              'Type the company code, which is "39333" press "Correct"',
              'Enter the BCA Virtual Account number',
              'Enter the amount you want to pay',
              'Make a payment and save the proof',
            ],
          },
          {
            type: 'Internet Banking',
            steps: [
              'Log in on KlikBCA',
              'Insert User ID and PIN',
              'Select "Transfer Dana"',
              'Select "transfer to BCA virtual Account"',
              'Select BCA virtual account or choose from transfer list',
              'Insert the amount to transfer',
            ],
          },
          {
            type: 'Mobile Banking',
            steps: [
              'Log in on BCA mobile',
              'Select "m-BCA" insert your access code m-BCA',
              'Select "m-Transfer"',
              'Select "BCA virtual Account"',
              'Select BCA virtual account or choose from transfer list',
              'Insert the amount to transfer',
            ],
          },
        ],
      },
      {
        name: 'Bank Mandiri',
        code: 'MANDIRI',
        details: [
          {
            type: 'Mandiri ATM',
            steps: [
              'Insert your ATM card and select "ENGLISH"',
              'Enter PIN, then select "ENTER"',
              'Select "Others"(if this doesn’t appear on your screen, continue to next step)',
              'Select "PAYMENT"',
              'Select "MULTI PAYMENT"',
              'Enter company code: "89251" (Instamoney 89251), then press "CORRECT"',
              'Enter Virtual Account Number, then press "CORRECT"',
              'Enter the amount to transfer, then press "CORRECT"',
              'Customer details will be displayed. Choose number 1 according to the amount billed and then press "YES"',
              'Payment confirmation will be displayed. Select "YES" to proceed',
              'Keep your receipt as proof of payment',
              'Your transaction is successful',
            ],
          },
          {
            type: 'Mandiri Internet Banking',
            steps: [
              'Go to Mandiri Internet Banking website <a style="word-break:break-all">https://ib.bankmandiri.co.id/retail/Login.do?action=form</a>',
              'Login with your USER ID and PIN',
              'Select "Payment"',
              'Select "Multi Payment"',
              'Select "My Account Number"',
              'Select "Instamoney 89251" as service provider',
              'Select "Virtual Account Number"',
              'Enter your virtual account number',
              'Go to confirmation page 1',
              'Click on TOTAL if all details are correct and then click on "CONTINUE"',
              'Go to confirmation page 2',
              'Enter Challenge Code from your Internet Banking Token, then click on "Send"',
              'You will be directed to the confirmation page once your payment has been completed',
            ],
          },
          {
            type: 'Mandiri Mobile Banking',
            steps: [
              'Log in Mobile Banking Mandiri Online (download the latest version)',
              'Click "Icon Menu" in top left side',
              'Select "Payment"',
              'Select "Make New Payment"',
              'Select "Multi Payment"',
              'Select "Instamoney 89251" as service provider',
              'Select "Virtual Account Number"',
              'Enter your virtual account number with "89251" as Company Code, then select "Add as New Number"',
              'Input "Nominal" then select "Confirmation"',
              'Select "Continue"',
              'Appear Payment Confirmation Page',
              'Scroll down in payment confirmation page then select "Confirmation"',
              'Input "PIN" and the transaction completed',
            ],
          },
        ],
      },
      {
        name: 'BNI',
        code: 'BNI',
        details: [
          {
            type: 'BNI ATM',
            steps: [
              'Select "Other Menu"',
              'Select "Transfer"',
              'Select "Other Transactions"',
              'Select "BNI Account"',
              'Type the company code, which is "8808" press "Correct"',
              'Enter the virtual account number then say "Right"',
              'Enter NOMINAL then press "Yes"',
              'Confirmation of the transaction is complete, please. "No" to complete the transaction',
            ],
          },
          {
            type: 'BNI Internet Banking',
            steps: [
              'Select "Transaction"',
              'Select "TRANSFER ADMINISTRATION INFO"',
              'Select "SET DESTINATION ACCOUNT"',
              'Add a destination account Then click "OK"',
              'Fill in the account data and press "CONTINUE"',
              'Confirmation details will appear, if correct and appropriate, enter the 8-digit number generated from APPLI 2 on your BNI token then click "PROCESS"',
              'Destination account added successfully',
              'Select "TRANSFER"',
              'Select "Transfer to BNI account", then complete all the account data of the recipient, then click "CONTINUE"',
              'Your transaction has been successful',
            ],
          },
          {
            type: 'BNI Mobile Banking',
            steps: [
              'Log in BNI Mobile Banking (download the latest version) after that, select "TRANSFER" menu',
              'Select "WITHIN BANK"',
              'Fill in the field "DEBIT ACCOUNT" then click "TO ACCOUNT” menu',
              'Select menu "AD HOC BENEFICIARY"',
              'Complete the data by filling in, NICKNAME, NO. VIRTUAL ACCOUNT and BENEFICIARY EMAIL ADDRESS',
              'Confirmation will appear then click "CONTINUE"',
              'Fill out all existing form then click "CONTINUE"',
              'The confirmation details appear by requesting the transaction password after it is completed click "CONTINUE"',
              'Your transaction has been successful',
            ],
          },
        ],
      },
      {
        name: 'BRI',
        code: 'BRI',
        details: [
          {
            type: 'BRI ATM',
            steps: [
              'Select Other Transaction',
              'Select Other',
              'Select Payment menu',
              'Select Others',
              'Select BRIVA menu',
              'Input your BRI Virtual Account. (Example: 10477-XXXX XXX XXXX), then click "Yes"',
              'Payment confirmation, select "Yes" if transaction details is correct',
            ],
          },
          {
            type: 'BRI Internet Banking',
            steps: [
              'Input User Id and Password',
              'Select Payment menu',
              'Select BRIVA',
              'Select payment account',
              'Input your BRI Virtual Account number. (Example: 10477-XXXX XXX XXXX)',
              'Input the amount',
              'Input your password and the Mtoken',
            ],
          },
          {
            type: 'BRI Mobile Banking',
            steps: [
              'Log in BRI Mobile Banking (download the latest version)',
              'Select Payment menu',
              'Select BRIVA',
              'Input your BRI Virtual Account and the amount',
              'Input your Pin Number',
              'Press "OK" to continue your transaction',
              'The transaction is successful',
              'A confirmation sms will go to your phone number',
            ],
          },
        ],
      },
      {
        name: 'Alfamart',
        code: 'ALFAMART',
        details: [
          {
            type: 'Alfa Group',
            steps: [
              'Enter your Kredit Pintar application',
              'Click the "Alfa Group" payment method',
              'Click the "get payment code" button',
              'Your Virtual Account will appear automatically in the application (only valid for 6 hours)',
              'Show your 16 digit Virtual Account payment number to the Alfa cashier, and make sure the payment is addressed to DOKU Payment which is registered under the name "Kredit Pintar"',
              'After making a payment check back on your application then look at the status of your payment, maximum 30 minutes after making payment',
              'Done',
            ],
          },
        ],
      },
    ],
    applicationSubmitted: 'Application submitted',
    uploadFailed: 'Whoops… Network connection is bad. Check your connection or change a wifi and try again',
    incompleteUserInfo:
      'It seems that you have not completed the application form, please have a check and try again later',
    alreadyHaveAccount: 'You already have a Kredit Pintar account and each user can only have 1 account',
    gotoExist: 'Go to Existing Account',
    tryAgain: 'Try again on',
    networkError: 'Network Error, please try again later',
    securityPopupTitle: 'Help us to confirm that this account is yours',
    securityPopupBtn: 'Verify Me',
    securityPopupContent: 'For account safety, we need to verify your identity before proceeding ',
  },
  id: {
    loading: 'Pemuatan',
    faq: [
      {
        name: 'BCA',
        code: 'BCA',
        details: [
          {
            type: 'ATM',
            steps: [
              'Pilih "Transaksi Lainnya"',
              'Pilih "Transfer"',
              'Pilih "Rekening BCA Virtual Account"',
              'Ketik kode perusahaan, yaitu "39333" tekan "Benar"',
              'Masukan nomor BCA Virtual Account',
              'Masukan jumlah yang ingin dibayarkan',
              'Lakukan pembayaran dan simpan buktinya',
            ],
          },
          {
            type: 'Internet Banking / Klik BCA',
            steps: [
              'Lakukan Log in pada aplikasi KlikBCA',
              'Masukkan user ID dan PIN',
              'Pilih "Transfer Dana"',
              'Pilih "Transfer ke BCA virtual Account"',
              'Masukkan nomor BCA Virtual Account atau pilih dari Daftar Transfer',
              'Masukkan Jumlah yang ingin dibayarkan',
            ],
          },
          {
            type: 'Mobile Banking',
            steps: [
              'Lakukan log in pada aplikasi BCA mobile',
              'Pilih "m-BCA" Masukkan kode akses m-BCA',
              'Pilih "m-Transfer"',
              'Pilih "BCA Virtual Account"',
              'Masukkan nomor BCA Virtual account atau pilih dari Daftar Transfer',
              'Masukkan jumlah yang ingin dibayarkan',
            ],
          },
          {
            type: 'ATM Lain ke Bank BCA',
            steps: [
              'Masukan kartu ATM Anda',
              'Masukan PIN ATM Anda',
              'Pilih menu "TRANSFER"',
              'Pilih menu "Transfer antara Bank setelah itu masukan kode bank"',
              'Masukan nomor Virtual Account yang terdapat di aplikasi Kredit Pintar, kemudian pilih "BENAR"',
              'Pastikan data Virtual Account Anda benar, kemudian masukan angka yang perlu Anda bayarkan, kemudian pilih "BENAR"',
              'Transaksi selesai',
            ],
          },
        ],
      },
      {
        name: 'Bank Mandiri',
        code: 'MANDIRI',
        details: [
          {
            type: 'Mandiri ATM',
            steps: [
              'Masukkan kartu ATM dan pilih "Bahasa Indonesia"',
              'Ketik nomor PIN kartu ATM',
              'Pilih lainnya(Jika Langkah ini tidak muncul di layar Anda, lanjutkan ke langkah selanjutnya)',
              'Pilih menu BAYAR/BELI',
              'Pilih menu MULTI PAYMENT',
              'Ketik kode perusahaan, yaitu "89251" (Instamoney 89251), tekan "BENAR"',
              'Masukkan nomor Virtual Account',
              'Isi NOMINAL, kemudian tekan "BENAR"',
              'Muncul konfirmasi data customer. Pilih Nomor 1 sesuai tagihan yang akan dibayar, kemudian tekan "YA"',
              'Muncul konfirmasi pembayaran. Tekan "YA" untuk melakukan pembayaran',
              'Bukti pembayaran dalam bentuk struk agar disimpan sebagai bukti pembayaran yang sah dari Bank Mandiri',
              'Transaksi Anda sudah selesai',
            ],
          },
          {
            type: 'Mandiri Internet Banking',
            steps: [
              'Kunjungi website Mandiri Internet Banking dengan alamat https://ib.bankmandiri.co.id/retail/Logi n.do?action=form',
              'Login dengan memasukkan USER ID dan PIN',
              'Pilih "Pembayaran"',
              'Pilih "Multi Payment"',
              'Pilih "No Rekening Anda"',
              'Pilih Penyedia Jasa "Instamoney 89251"',
              'Pilih "No Virtual Account"',
              'Masukkan nomor Virtual Account',
              'Masuk ke halaman konfirmasi',
              'Apabila benar/sesuai, klik tombol tagihan TOTAL, kemudian klik "Lanjutkan"',
              'Masuk ke halaman konfirmasi 2',
              'Masukkan Challenge Code yang dikirimkan ke Token Internet Banking Anda kemudian klik "Kirim"',
              'Masuk ke halaman konfirmasi pembayaran telah selesai',
            ],
          },
          {
            type: 'Mandiri Mobile Banking',
            steps: [
              'Log in Mobile Banking Mandiri Online (download versi terbaru)',
              'Klik "Icon Menu" di sisi kiri atas',
              'Pilih "Pembayaran"',
              'Pilih "Buat Pembayaran Baru"',
              'Pilih "Multi Payment"',
              'Pilih "Instamoney 89251" sebagai penyedia layanan',
              'Pilih "Virtual Account Number"',
              'Masukkan nomor akun virtual Anda dengan "89251" sebagai Kode Perusahaan, lalu pilih "Tambahkan sebagai Nomor Baru"',
              'Masukan "Nominal" lalu pilih "Confirmation"',
              'Pilih "Lanjutkan"',
              'Muncul Konfirmasi Pembayaran',
              'Gulir ke bawah di halaman konfirmasi pembayaran lalu pilih "Konfirmasi"',
              'Masukan "PIN" dan transaksi selesai',
            ],
          },
        ],
      },
      {
        name: 'Bank Lain ke Bank Mandiri',
        code: 'MANDIRI',
        details: [
          {
            type: 'ATM Lain ke Bank Mandiri',
            steps: [
              'Pilih Menu lain',
              'Pilih Transfer',
              'Pilih dari rekening tabungan',
              'Pilih ke rek. Bank lain',
              'Masukkan kode bank dilanjutkan dengan nomor Virtual Account Anda (Mandiri 008+89251-XXXXXXXXXX)',
              'Input Nominal yang ditagihkan sebagai Nominal Transfer',
              'Selesai, transaksi berhasil',
            ],
          },
          {
            type: 'Internet Banking Lain ke Mandiri',
            steps: [
              'Masukan User ID dan Password',
              'Pilih Transfer',
              'Pilih ke rek. Bank lain',
              'Pilih bank tujuan',
              'Masukkan nomor Virtual Account Anda (Mandiri 89251-XXXXXXXXXX)',
              'Input Nominal yang ditagihkan sebagai Nominal Transfer',
              'Selesai, transaksi berhasil',
            ],
          },
          {
            type: 'Mobile Banking Lain ke Mandiri',
            steps: [
              'Masukan User ID dan Password',
              'Pilih Transfer',
              'Pilih ke rek. Bank lain',
              'Pilih bank tujuan',
              'Masukkan nomor Virtual Account Anda (MandiriI 89251-XXXXXXXXXX)',
              'Input Nominal yang ditagihkan sebagai Nominal Transfer',
              'Selesai, transaksi berhasil',
            ],
          },
        ],
      },
      {
        name: 'BNI',
        code: 'BNI',
        details: [
          {
            type: 'BNI ATM',
            steps: [
              'Pilih "Menu Lain"',
              'Pilih "Transfer"',
              'Pilih "Transaksi Lainnya"',
              'Pilih ke "Rekening BNI"',
              'Ketik kode perusahaan, yaitu "8808" tekan "Benar"',
              'Masukan nomor account virtual lalu takan "Benar"',
              'Masukan NOMINAL lalu tekan "Ya"',
              'Konfirmasi transaksi selesai, teka. "Tidak" untuk menyelesaikan transaksi',
            ],
          },
          {
            type: 'BNI Internet Banking',
            steps: [
              'Pilih "Transaksi"',
              'Pilih "INFO & ADMINISTRASI TRANSFER',
              'Pilih "ATUR REKENING TUJUAN"',
              'Tambahkan akun tujuan Kemudian klik "OK"',
              'Isi data akun dan tekan "LANJUTKAN"',
              'Rincian konfirmasi akan muncul, jika benar dan sesuai, masukkan 8-digit angka yang dihasilkan dari APPLI 2 pada token BNI Anda lalu klik "PROSES"',
              'Akun tujuan berhasil ditambahkan',
              'Pilih "TRANSFER"',
              'Pilih "Transfer ke rekening BNI", lalu lengkapi semua data rekening penerima, lalu klik "LANJUTKAN"',
              'Transaksi Anda telah berhasil',
            ],
          },
          {
            type: 'BNI Mobile Banking',
            steps: [
              'Log in BNI Mobile Banking (download versi terbaru)',
              'pilih menu "TRANSFER"',
              'Pilih "WITHIN BANK"',
              'Isi kolom "DEBIT ACCOUNT" lalu klik menu "TO ACCOUNT"',
              'Pilih menu "AD HOC BENEFICIARY"',
              'Lengkapi data dengan mengisi, NICKNAME, NO VIRTUAL ACCOUNT DAN BENEFICIARY EMAIL ADDRESS',
              'Konfirmasi akan muncul kemudian klik "CONTINUE"',
              'Isi semua form yang ada lalu klik "CONTINUE"',
              'Rincian konfirmasi muncul dengan meminta password transaksi setelah selesai klik "CONTINUE"',
              'Transaksi Anda telah berhasil',
            ],
          },
        ],
      },
      {
        name: 'Bank Lain ke Bank BNI',
        code: 'BNI',
        details: [
          {
            type: 'ATM Lain ke Bank BNI',
            steps: [
              'Pilih "LAINNYA"',
              'Pilih "TRANSFER"',
              'Pilih dari Tabungan',
              'Pilih dari Bank Lain',
              'Masukkan kode Bank diikuti dengan nomor Virtual Account Anda (BNI 009+8808-XXXXXXXXXX)',
              'Masukkan jumlah tagihan sebagai jumlah transfer',
              'Selesai, Transaksi selesai',
            ],
          },
          {
            type: 'Internet Banking Lain ke Bank BNI',
            steps: [
              'Masukkan User ID dan Password',
              'Pilih “TRANSFER”',
              'Pilih “TRANSFER KE BANK LAIN”',
              'Pilih Bank Tujuan',
              'Masukkan nomor Virtual Account (BNI 8808-XXXXXXXXXX)',
              'Masukkan jumlah tagihan sebagai jumlah transfer',
              'Selesai, Transaksi Selesai',
            ],
          },
          {
            type: 'Mobile Banking Lain ke Bank BNI',
            steps: [
              'Masukkan User ID dan Password',
              'Pilih "TRANSFER"',
              'Pilih "TRANSFER KE BANK LAIN"',
              'Pilih Bank Tujuan',
              'Masukkan nomor Virtual Account (BNI 8808-XXXXXXXXXX)',
              'Masukkan jumlah tagihan sebagai jumlah transfer',
              'Selesai, Transaksi selesai',
            ],
          },
        ],
      },
      {
        name: 'BRI',
        code: 'BRI',
        details: [
          {
            type: 'BRI ATM',
            steps: [
              'Pilih Transaksi Lain',
              'Pilih Lainnya',
              'Pilih menu Pembayaran',
              'Pilih ke BRI',
              'Pilih menu BRIVA',
              'Masukan Nomor BRI Virtual Account anda. (Contoh: 10477-XXXX XXX XXXX), lalu klik "Benar"',
              'Konfirmasi pembayaran, pilih "Ya" jika detail transaksi benar',
            ],
          },
          {
            type: 'BRI Internet Banking',
            steps: [
              'Masukan User Id dan Password',
              'Pilih menu Pembayaran',
              'Pilih BRIVA',
              'Pilih rekening Pembayar',
              'Masukkan nomor rekening BRI Virtual Account Anda. (Contoh: 10477-XXXX XXX XXXX)',
              'Masukan nominal yang akan dibayar',
              'Masukan kata sandi dan Mtoken Anda',
            ],
          },
          {
            type: 'BRI Mobile Banking',
            steps: [
              'Log in BRI Mobile Banking (download versi terbaru)',
              'Pilih menu Pembayaran',
              'Pilih BRIVA',
              'Masukan nomor BRI Virtual Account Anda dan jumlah pembayaran',
              'Masukan Nomor Pin anda',
              'Tekan "OK" untuk melanjutkan transaksi Anda',
              'Transaksi berhasil',
              'Konfirmasi sms akan masuk ke nomor telepon anda',
            ],
          },
        ],
      },
      {
        name: 'Bank Lain ke Bank BRI',
        code: 'BRI',
        details: [
          {
            type: 'ATM Lain ke Bank BRI',
            steps: [
              'Pilih Menu lain',
              'Pilih Transfer',
              'Pilih dari rekening tabungan',
              'Pilih ke rek. Bank lain',
              'Masukkan kode bank dilanjutkan dengan nomor Virtual Account Anda (BRI 002+ 10477-XXXXXXXXXX)',
              'Input Nominal yang ditagihkan sebagai Nominal Transfer',
              'Selesai, transaksi berhasil',
            ],
          },
          {
            type: 'Internet Banking Lain ke Bank BRI',
            steps: [
              'Masukan User ID dan Password',
              'Pilih Transfer',
              'Pilih ke rek. Bank lain',
              'Pilih bank tujuan',
              'Masukkan nomor Virtual Account Anda (BRI 10477- XXXXXXXXXX)',
              'Input Nominal yang ditagihkan sebagai Nominal Transfer',
              'Selesai, transaksi berhasil',
            ],
          },
          {
            type: 'Mobile Banking Lain ke Bank BRI',
            steps: [
              'Masukan User ID dan Password',
              'Pilih Transfer',
              'Pilih ke rek. Bank lain',
              'Pilih bank tujuan',
              'Masukkan nomor Virtual Account Anda (BRI 10477-XXXXXXXXXX)',
              'Input Nominal yang ditagihkan sebagai Nominal Transfer',
              'Selesai, transaksi berhasil',
            ],
          },
        ],
      },
      {
        name: 'Alfa Group',
        code: 'ALFAMART',
        details: [
          {
            type: 'Alfa Group',
            steps: [
              'Masuk ke aplikasi Kredit Pintar Anda',
              'Klik metode pembayaran "Alfa Group"',
              'Klik tombol "dapatkan kode pembayaran"',
              'Virtual Account Anda akan muncul secara otomatis pada aplikasi (hanya berlaku selama 6 jam)',
              'Tunjukan nomor 16 digit Virtual Account pembayaran Anda kepada kasir Alfa, dan pastikan pembayaran ditujukan kepada DOKU Payment yang terdaftar atas nama "Kredit Pintar"',
              'Setelah melakukan pembayaran cek kembali pada aplikasi Anda kemudian lihat status pada pembayaran maksimal 30 menit setelah melakukan pembayaran',
              'Selesai',
            ],
          },
        ],
      },
    ],
    faqSimple: [
      {
        name: 'BCA',
        code: 'BCA',
        details: [
          {
            type: 'ATM',
            steps: [
              'Masukkan kartu ATM BCA & PIN',
              'Pilih "Transaksi Lainnya"',
              'Pilih "Transfer"',
              'Pilih "Rekening BCA Virtual Account"',
              'Masukkan nomor BCA Virtual Account',
              'Masukkan jumlah yang ingin dibayarkan',
            ],
          },
          {
            type: 'Internet Banking / Klik BCA',
            steps: [
              'Lakukan Log in pada aplikasi KlikBCA',
              'Masukkan user ID dan PIN',
              'Pilih "Transfer Dana"',
              'Pilih "Transfer ke BCA virtual Account"',
              'Masukkan nomor BCA Virtual Account atau pilih dari Daftar Transfer',
              'Masukkan Jumlah yang ingin dibayarkan',
            ],
          },
          {
            type: 'Mobile Banking',
            steps: [
              'Lakukan log in pada aplikasi BCA mobile',
              'Pilih "m-BCA" Masukkan kode akses m-BCA',
              'Pilih "m-Transfer"',
              'Pilih "BCA Virtual Account"',
              'Masukkan nomor BCA Virtual account atau pilih dari Daftar Transfer',
              'Masukkan jumlah yang ingin dibayarkan',
            ],
          },
        ],
      },
      {
        name: 'Bank Mandiri',
        code: 'MANDIRI',
        details: [
          {
            type: 'Mandiri ATM',
            steps: [
              'Masukkan kartu ATM dan pilih "Bahasa Indonesia"',
              'Ketik nomor PIN kartu ATM',
              'Pilih lainnya(Jika Langkah ini tidak muncul di layar Anda, lanjutkan ke langkah selanjutnya)',
              'Pilih menu BAYAR/BELI',
              'Pilih menu MULTI PAYMENT',
              'Ketik kode perusahaan, yaitu "89251" (Instamoney 89251), tekan "BENAR"',
              'Masukkan nomor Virtual Account',
              'Isi NOMINAL, kemudian tekan "BENAR"',
              'Muncul konfirmasi data customer. Pilih Nomor 1 sesuai tagihan yang akan dibayar, kemudian tekan "YA"',
              'Muncul konfirmasi pembayaran. Tekan "YA" untuk melakukan pembayaran',
              'Bukti pembayaran dalam bentuk struk agar disimpan sebagai bukti pembayaran yang sah dari Bank Mandiri',
              'Transaksi Anda sudah selesai',
            ],
          },
          {
            type: 'Mandiri Internet Banking',
            steps: [
              'Kunjungi website Mandiri Internet Banking dengan alamat https://ib.bankmandiri.co.id/retail/Logi n.do?action=form',
              'Login dengan memasukkan USER ID dan PIN',
              'Pilih "Pembayaran"',
              'Pilih "Multi Payment"',
              'Pilih "No Rekening Anda"',
              'Pilih Penyedia Jasa "Instamoney 89251"',
              'Pilih "No Virtual Account"',
              'Masukkan nomor Virtual Account',
              'Masuk ke halaman konfirmasi',
              'Apabila benar/sesuai, klik tombol tagihan TOTAL, kemudian klik "Lanjutkan"',
              'Masuk ke halaman konfirmasi 2',
              'Masukkan Challenge Code yang dikirimkan ke Token Internet Banking Anda kemudian klik "Kirim"',
              'Masuk ke halaman konfirmasi pembayaran telah selesai',
            ],
          },
          {
            type: 'Mandiri Mobile Banking',
            steps: [
              'Log in Mobile Banking Mandiri Online (download versi terbaru)',
              'Klik "Icon Menu" di sisi kiri atas',
              'Pilih "Pembayaran"',
              'Pilih "Buat Pembayaran Baru"',
              'Pilih "Multi Payment"',
              'Pilih "Instamoney 89251" sebagai penyedia layanan',
              'Pilih "Virtual Account Number"',
              'Masukkan nomor akun virtual Anda dengan "89251" sebagai Kode Perusahaan, lalu pilih "Tambahkan sebagai Nomor Baru"',
              'Masukan "Nominal" lalu pilih "Confirmation"',
              'Pilih "Lanjutkan"',
              'Muncul Konfirmasi Pembayaran',
              'Gulir ke bawah di halaman konfirmasi pembayaran lalu pilih "Konfirmasi"',
              'Masukan "PIN" dan transaksi selesai',
            ],
          },
        ],
      },
      {
        name: 'BNI',
        code: 'BNI',
        details: [
          {
            type: 'BNI ATM',
            steps: [
              'Pilih "Menu Lain"',
              'Pilih "Transfer"',
              'Pilih "TRANSAKSI LAINNYA"',
              'Pilih ke "REKENING BNI"',
              'Masukkan Nomor Account Virtual, lalu tekan "BENAR"',
              'Masukkan NOMINAL, lalu tekan "YA"',
              'Konfirmasi transaksi selesai, tekan "TIDAK" untuk menyelesaikan transaksi',
            ],
          },
          {
            type: 'BNI Internet Banking',
            steps: [
              'Pilih "Transaksi"',
              'Pilih "INFO & ADMINISTRASI TRANSFER',
              'Pilih "ATUR REKENING TUJUAN"',
              'Tambahkan akun tujuan Kemudian klik "OK"',
              'Isi data akun dan tekan "LANJUTKAN"',
              'Rincian konfirmasi akan muncul, jika benar dan sesuai, masukkan 8-digit angka yang dihasilkan dari APPLI 2 pada token BNI Anda lalu klik "PROSES"',
              'Akun tujuan berhasil ditambahkan',
              'Pilih "TRANSFER"',
              'Pilih "Transfer ke rekening BNI", lalu lengkapi semua data rekening penerima, lalu klik "LANJUTKAN"',
              'Transaksi Anda telah berhasil',
            ],
          },
          {
            type: 'BNI Mobile Banking',
            steps: [
              'Log in BNI Mobile Banking (download versi terbaru)',
              'pilih menu "TRANSFER"',
              'Pilih "WITHIN BANK"',
              'Isi kolom "DEBIT ACCOUNT" lalu klik menu "TO ACCOUNT"',
              'Pilih menu "AD HOC BENEFICIARY"',
              'Lengkapi data dengan mengisi, NICKNAME, NO VIRTUAL ACCOUNT DAN BENEFICIARY EMAIL ADDRESS',
              'Konfirmasi akan muncul kemudian klik "CONTINUE"',
              'Isi semua form yang ada lalu klik "CONTINUE"',
              'Rincian konfirmasi muncul dengan meminta password transaksi setelah selesai klik "CONTINUE"',
              'Transaksi Anda telah berhasil',
            ],
          },
        ],
      },
      {
        name: 'BRI',
        code: 'BRI',
        details: [
          {
            type: 'BRI ATM',
            steps: [
              'Pilih Transaksi Lain',
              'Pilih Lainnya',
              'Pilih menu Pembayaran',
              'Pilih ke BRI',
              'Pilih menu BRIVA',
              'Masukan Nomor BRI Virtual Account anda. (Contoh: 10477-XXXX XXX XXXX), lalu klik "Benar"',
              'Konfirmasi pembayaran, pilih "Ya" jika detail transaksi benar',
            ],
          },
          {
            type: 'BRI Internet Banking',
            steps: [
              'Masukan User Id dan Password',
              'Pilih menu Pembayaran',
              'Pilih BRIVA',
              'Pilih rekening Pembayar',
              'Masukkan nomor rekening BRI Virtual Account Anda. (Contoh: 10477-XXXX XXX XXXX)',
              'Masukan nominal yang akan dibayar',
              'Masukan kata sandi dan Mtoken Anda',
            ],
          },
          {
            type: 'BRI Mobile Banking',
            steps: [
              'Log in BRI Mobile Banking (download versi terbaru)',
              'Pilih menu Pembayaran',
              'Pilih BRIVA',
              'Masukan nomor BRI Virtual Account Anda dan jumlah pembayaran',
              'Masukan Nomor Pin anda',
              'Tekan "OK" untuk melanjutkan transaksi Anda',
              'Transaksi berhasil',
              'Konfirmasi sms akan masuk ke nomor telepon anda',
            ],
          },
        ],
      },
      {
        name: 'Alfa Group',
        code: 'ALFAMART',
        details: [
          {
            type: 'Alfa Group',
            steps: [
              'Masuk ke aplikasi Kredit Pintar Anda',
              'Klik metode pembayaran "Alfa Group"',
              'Klik tombol "dapatkan kode pembayaran"',
              'Virtual Account Anda akan muncul secara otomatis pada aplikasi (hanya berlaku selama 6 jam)',
              'Tunjukan nomor 16 digit Virtual Account pembayaran Anda kepada kasir Alfa, dan pastikan pembayaran ditujukan kepada DOKU Payment yang terdaftar atas nama "Kredit Pintar"',
              'Setelah melakukan pembayaran cek kembali pada aplikasi Anda kemudian lihat status pada pembayaran maksimal 30 menit setelah melakukan pembayaran',
              'Selesai',
            ],
          },
        ],
      },
    ],
    applicationSubmitted: 'Aplikasi telah diajukan',
    uploadFailed: 'Oops. Jaringan tidak tersedia. Periksa koneksi Anda atau ganti jaringan wifi dan coba lagi',
    incompleteUserInfo: 'Sepertinya Anda belum mengisi formulir aplikasi, silakan periksa dan coba lagi',
    alreadyHaveAccount: 'Anda sudah memiliki akun Kredit Pintar dan setiap pengguna hanya bisa memiliki 1 akun',
    gotoExist: 'Masuk ke Akun yang sudah ada',
    tryAgain: 'Coba lagi pada',
    networkError: 'Koneksi Bermasalah, Coba Lagi Nanti',
    securityPopupTitle: 'Bantu kami untuk konfirmasi bahwa akun ini milik Anda',
    securityPopupBtn: 'Verifikasi Saya',
    securityPopupContent: 'Untuk keamanan akun, kami perlu memverifikasi identitas Anda sebelum melanjutkan',
  },
}

export default message
