<template>
  <md-popup v-model="isPopupShow" class="popup-alert" :mask-closable="customClose" @hide="hideHandler">
    <div class="content">
      <div class="popup-wrapper">
        <div
          :class="{
            'popup-img-wrapper': true,
            'popup-img-wrapper-security': isAccountSecurity,
            'popup-img-wrapper-new': isWrapperNew,
          }"
          v-show="popupType.type !== 'NORMAL'"
        >
          <md-icon
            :class="{
              'popup-icon': true,
              'popup-icon-new': isWrapperNew,
            }"
            :name="popupType.icon || 'icon_pop_info'"
            svg
          />
          <md-icon
            class="popup-icon-close"
            v-if="!popupType.hideCloseIcon"
            :name="popupType.closeIcon || ''"
            svg
            @click="hideHandler"
          />
        </div>
        <div class="title">{{ popupType.title }}</div>
        <div v-if="customeWrapper.aLabel">
          <div class="message">
            {{ message }}
            <a :href="customeWrapper.terms.href" @click="touchEvent(customeWrapper.terms.event)" class="aLabel">
              {{ customeWrapper.terms.name }}
            </a>
            {{ customeWrapper.and }}
            <a :href="customeWrapper.policy.href" @click="touchEvent(customeWrapper.policy.event)" class="aLabel">
              {{ customeWrapper.policy.name }}
            </a>
          </div>
        </div>
        <div v-else :class="{ 'message-wrapper': true, 'message-pc': isPc }">
          <div
            v-for="(item, index) in popupType.message"
            :key="`message-${index}`"
            :class="{
              message: true,
              'message-new': isWrapperNew,
            }"
          >
            {{ item }} <br />
          </div>
        </div>
        <div
          :class="{
            'foot-wrapper': true,
            'foot-wrapper-new': isWrapperNew || isAccountSecurity,
          }"
        >
          <div class="left-btn" v-show="hasLeftBtn" @click="signOutHandle">{{ $t('Logout') }}</div>
          <div
            v-if="popupType.showCancelButton"
            :class="{
              'button-ok': true,
              'button-cancel': true,
            }"
            @click="hideHandler"
          >
            {{ popupType.buttonCancelText }}
          </div>
          <div
            :class="{
              'button-ok': true,
              'button-re-submit': isWrapperNew || isAccountSecurity,
              disabled: this.isSubmitButtonDisabled,
            }"
            @click="submitHandler"
          >
            {{ popupType.buttonText }}
            {{ popupType.showCountdown && this.countdown > 0 ? `(${this.countdown}s)` : '' }}
          </div>
        </div>
      </div>
    </div>
  </md-popup>
</template>

<script>
import { Action } from '@pintar/id-track-event'
import { JagoAccountEnum } from '@base/enum/jago-account'
import { isFromMobile } from '@base/lib/utils'

export default {
  name: 'PopupAlert',
  model: {
    prop: 'isShow',
    event: 'close',
  },
  props: {
    isShow: { type: Boolean, default: false },
    type: { type: String, default: '' },
    title: { type: String, default: '' },
    message: { type: [String, Array], default: '' },
    buttonText: { type: String, default: 'OK' },
    showCancelButton: { type: Boolean, default: false },
    buttonCancelText: { type: String, default: 'Cancel' },
    customClose: { type: Boolean, default: true },
    showCountdown: { type: Boolean, default: false },
    countdownDuration: { type: Number, default: 10 },
    // PopUp
    customeWrapper: { type: Object, default: () => {} },
    hasLeftBtn: { type: Boolean, default: false },
  },
  data() {
    return {
      isPopupShow: false,
      countdown: 0,
      countdownInterval: null,
    }
  },
  computed: {
    isWrapperNew() {
      const types = [
        'COUPON_EXPIRED',
        'GEOLOCATION',
        'DELETE_ACCOUNT_NOTICE',
        'DELETE_ACCOUNT_CONFIRM',
        JagoAccountEnum.NEED_LIVENESS_PHOTO,
        'NORMAL',
      ]
      return types.includes(this.popupType.type)
    },
    isAccountSecurity() {
      return this.popupType.type === 'ACCOUNT_SECURITY'
    },
    allTypes() {
      return [
        {
          type: 'NONE',
          title: '',
          message: '',
          buttonText: this.$t('OK'),
        },
        //
        {
          type: 'SUCCESS',
          title: '',
          message: this.$t('successMessage'),
          buttonText: this.$t('ok'),
        },
        //
        {
          type: 'LIMIT',
          title: this.$t('limitTitle'),
          message: this.$t('limitMessage'),
          buttonText: this.$t('payBill'),
        },
        //  ( 0)
        {
          type: 'FROZEN',
          title: this.$t('blockedTitle'),
          message: this.$t('frozenMessage'),
          buttonText: this.$t('ok'),
        },
        //  ( > 0)
        {
          type: 'BLOCKED',
          title: this.$t('blockedTitle'),
          message: this.$t('blockedMessage'),
          buttonText: this.$t('payBill'),
        },
        //
        {
          type: 'ONGOING',
          title: '',
          message: this.$t('ongoingMessage'),
          buttonText: this.$t('ok'),
        },
        // nkp
        {
          type: 'NKP_ONGOING',
          title: '',
          message: this.$t('nkpOngoingMessage'),
          buttonText: this.$t('ok'),
        },
        //
        {
          type: 'REJECTED',
          title: this.$t('rejectedTitle'),
          message: this.$t('rejectedMessage'),
          buttonText: this.$t('ok'),
        },
        //
        {
          type: 'NKP_REJECTED',
          title: this.$t('rejectedTitle'),
          message: this.$t('nkpRejectedMessage'),
          buttonText: this.$t('ok'),
        },
        //
        {
          type: 'UNQUALIFIED',
          title: this.$t('unqualifiedTitle'),
          message: this.$t('unqualifiedMessage'),
          buttonText: this.$t('ok'),
        },
        //
        {
          type: 'UNAVAILABLE',
          title: this.$t('unavailableTitle'),
          message: this.$t('unavailableMessage'),
          buttonText: this.$t('ok'),
        },
        //
        {
          type: 'APPLY_SUCCESS',
          message: this.$t('applySuccess'),
          buttonText: this.$t('ok'),
        },
        //
        {
          type: 'APPLY_SUCCESS_WITH_PENDING',
          message: this.$t('applySuccessWithPending'),
          buttonText: this.$t('ok'),
        },
        //
        {
          type: 'WAITING',
          buttonText: this.$t('ok'),
        },
        //
        {
          type: 'UNABLE_TO_LOAN',
          title: this.$t('unableToLoanTitle'),
          message: this.$t('unableToLoanMessage'),
          buttonText: this.$t('ok'),
        },
        // copon
        {
          type: 'COUPON_EXPIRED',
          icon: 'popup-icon-new',
          closeIcon: 'popup_alert_close',
          message: this.$t('voucherFailed'),
          buttonText: this.$t('reSubmit'),
        },
        // effortless
        {
          type: 'EFFORTLESS',
          message: this.$t('effortlessMessage'),
          buttonText: this.$t('ok'),
          customClose: false,
        },
        // effortless
        {
          type: 'EFFORTLESS_LOGIN_FORBID',
          message: this.$t('effortlessLoginForbid'),
          buttonText: this.$t('ok'),
          customClose: false,
        },
        // 		(EFFORTLESS_LOAN_FACE_TIMES_ERROR / EFFORTLESS_LOAN_FACE_SIMILARITY_ERROR)
        {
          type: 'EFFORTLESS_LOAN_FACE_TIMES_ERROR',
          message: this.$t('effortlessFaceMessage'),
          buttonText: this.$t('ok'),
          customClose: false,
        },
        {
          type: 'EFFORTLESS_LOAN_FACE_SIMILARITY_ERROR',
          message: this.$t('effortlessFaceMessage'),
          buttonText: this.$t('ok'),
          customClose: false,
        },
        //account security
        {
          type: 'ACCOUNT_SECURITY',
          title: this.$t('securityPopupTitle'),
          icon: 'popup-icon-new',
          closeIcon: '',
          message: this.$t('securityPopupContent'),
          buttonText: this.$t('securityPopupBtn'),
          hideCloseIcon: true,
          customClose: false,
        },
        {
          type: 'NEED_LIVENESS_PHOTO',
          icon: 'icon_popup_black',
          message: this.$t('jagoNotRegister'),
          buttonText: this.$t('ok'),
          customClose: false,
        },
        {
          type: 'GEOLOCATION',
          title: this.$t('geolocationTitle'),
          icon: 'popup-icon-new',
          message: this.$t('geolocationMesage'),
          buttonText: this.$t('ok'),
          closeIcon: 'popup_alert_close',
        },
        // delete account
        {
          type: 'DELETE_ACCOUNT_NOTICE',
          title: this.$t('deleteAccountNoticeTitle'),
          icon: 'popup-icon-new',
          message: [this.$t('deleteAccountNoticeMessage1'), this.$t('deleteAccountNoticeMessage2')],
          buttonText: this.$t('deleteAccountNoticeBtn'),
          hideCloseIcon: true,
          showCountdown: true,
        },
        {
          type: 'DELETE_ACCOUNT_CONFIRM',
          title: this.$t('deleteAccountConfirmTitle'),
          icon: 'popup-icon-new',
          message: this.$t('deleteAccountConfirmMessage'),
          buttonText: this.$t('deleteAccountConfirmBtn'),
          hideCloseIcon: true,
          showCancelButton: true,
        },
        {
          type: 'NORMAL',
          title: '',
          icon: '',
          showCancelButton: true,
        },
      ]
    },
    popupType() {
      const temp =
        this.allTypes.filter((item) => {
          return item.type === this.type.toUpperCase()
        })[0] || {}
      const message = this.message ? this.message : temp.message
      let messageList = []
      if (typeof message === 'string') {
        messageList.push(message)
      } else {
        messageList = message
      }
      return {
        icon: temp.icon,
        closeIcon: temp.closeIcon,
        type: temp.type,
        title: this.title ? this.title : temp.title,
        message: messageList,
        buttonText: this.buttonText ? this.buttonText : temp.buttonText,
        showCancelButton: this.showCancelButton || temp.showCancelButton,
        buttonCancelText: temp.buttonCancelText || this.buttonCancelText,
        hideCloseIcon: temp.hideCloseIcon,
        showCountdown: temp.showCountdown,
        countdownDuration: temp.countdownDuration || this.countdownDuration,
      }
    },
    isSubmitButtonDisabled() {
      return this.popupType.showCountdown && this.countdown > 0
    },
    isPc() {
      return !isFromMobile()
    },
  },
  watch: {
    isShow: {
      handler() {
        this.isPopupShow = this.isShow
        if (this.isPopupShow) {
          this.$eventTrack({
            action: Action.DialogDisplay,
            extra: {
              message: this.popupType.title,
            },
          })

          // start countdown
          if (this.popupType.showCountdown) {
            this.startCountdown()
          }
        } else {
          clearInterval(this.countdownInterval)
        }
      },
      immediate: true,
    },
  },
  methods: {
    touchEvent(event) {
      if (!event) return
      this.$eventTrack({
        action: Action[event],
      })
    },
    addCloseEvent() {
      this.$emit('addCloseEvent')
    },
    hideHandler() {
      this.addCloseEvent()
      this.$emit('hide')
      this.closeHandler()
    },
    closeHandler() {
      this.$eventTrack({
        action: Action.DialogCancel,
        extra: {
          message: this.popupType.title,
        },
      })
      this.isPopupShow = false
      this.$emit('close', false)
    },
    submitHandler() {
      if (this.isSubmitButtonDisabled) return

      this.$eventTrack({
        action: Action.DialogConfirm,
        extra: {
          message: this.popupType.title,
        },
      })
      this.$emit('submit', 'submit')
      this.closeHandler()
    },
    signOutHandle() {
      this.$emit('submit', 'signOut')
      this.closeHandler()
    },
    startCountdown() {
      this.countdown = this.popupType.countdownDuration

      this.countdownInterval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--
        } else {
          clearInterval(this.countdownInterval)
        }
      }, 1000)
    },
  },
  beforeDestroy() {
    clearInterval(this.countdownInterval)
  },
  i18n: {
    messages: {
      'en-us': {
        Logout: 'Sign out',
        submit: 'Submit',
        ok: 'OK',
        payBill: 'Pay Bill',
        successMessage: 'KPintar customer service team has received your issue and will resolve it as soon as possible',
        limitTitle: 'Limit is not enough!',
        limitMessage: 'Pay other bills first to have more available limit',
        blockedTitle: 'Limit locked!',
        frozenMessage: 'You have violated out terms of service and your limit is frozen',
        blockedMessage:
          'You have violated out terms of service and your limit is frozen. Please pay off your remaining bills and try again later',
        ongoingMessage: "Don't worry! You can apply limit after settle your current ongoing bill",
        rejectedTitle: 'Try again on',
        rejectedMessage: 'Your limit is temporary locked because your previous application was rejected',
        nkpRejectedMessage: 'We apologize your previous application was rejected',
        unqualifiedTitle: 'Your limit is unqualified',
        unqualifiedMessage: 'Make a good credit history will give you chance to get loans',
        unavailableTitle: 'Product unavailable',
        unavailableMessage: 'Sorry, this prodect is unavailable right now. Please select another product',
        applySuccess: 'Great, your application is submitted!',
        applySuccessWithPending: 'Approval may take up to 24 hours',
        unableToLoanTitle: 'Application rejected',
        unableToLoanMessage: "We noticed you have breached platform policy, the application can't be approved",
        nkpOngoingMessage: 'You already have a Kredit Pintar account and each user can only have 1 account',
        voucherFailed: 'Apply voucher failed, click the button to re-submit your order',
        reSubmit: 'Re-submit',
        effortlessMessage:
          'Effortless Loan will help you to increase Credit, Please complete an Effortless Loan first.',
        effortlessLoginForbid: 'Sorry, you are currently ineligible to apply',
        effortlessFaceMessage: 'Your face verification does not match, please make sure your photo is clear and valid.',
        jagoNotRegister: 'Sorry, you can not register for a Jago account at the moment',
        geolocationTitle: 'Location access needed',
        geolocationMesage:
          'Your location allows us to know you better and make borrowing instant and easy. Please allow it in your browser setting and system settings',
        deleteAccountNoticeTitle: 'Read it before you delete your account',
        deleteAccountNoticeMessage1: 'Once account deletion is confirmed, it cannot be undone.',
        deleteAccountNoticeMessage2:
          'Once you delete your account, your user and billing information cannot be retrieved. If you re-register, your credit level will be re-evaluated and activation may fail, so please proceed with caution.',
        deleteAccountNoticeBtn: 'Continue',
        deleteAccountConfirmTitle: 'Are you sure you want to delete your account?',
        deleteAccountConfirmMessage: 'Once account deletion is confirmed, it cannot be undone.',
        deleteAccountCancelBtn: 'Cancel',
        deleteAccountConfirmBtn: 'Confirm',
      },
      id: {
        Logout: 'Keluar',
        submit: 'Ajukan',
        ok: 'OK',
        payBill: 'Bayar Tagihan',
        successMessage:
          'Tim Layanan Pelanggan KPintar telah menerima permasalahan Anda dan akan segera menyelesaikannya',
        limitTitle: 'Limit tidak mencukupi!',
        limitMessage: 'Mohon lunasi tagihan Anda untuk dapat tambahan limit',
        blockedTitle: 'Limit terkunci!',
        frozenMessage: 'Anda telah melanggar Ketentuan Layanan dan limit Anda terkunci',
        blockedMessage:
          'Anda telah melanggar Ketentuan Layanan dan limit Anda terkunci. Mohon lunasi semua tagihan dan coba lagi nanti',
        ongoingMessage:
          'Jangan khawatir! Anda dapat menerapkan batas kredit setelah menyelesaikan tagihan Anda saat ini',
        rejectedTitle: 'Silakan coba lagi pada',
        rejectedMessage: 'Limit Anda terkunci sementara karena pengajuan sebelumnya ditolak',
        nkpRejectedMessage: 'Mohon maaf, pengajuan Anda sebelumnya ditolak',
        unqualifiedTitle: 'Limit Anda tidak memenuhi syarat',
        unqualifiedMessage: 'Bangun riwayat kredit yang baik untuk mendapatkan persetujuan pinjaman',
        unavailableTitle: 'Produk tidak tersedia',
        unavailableMessage: 'Maaf, untuk saat ini Produk tidak tersedia. Silakan pilih produk lain',
        applySuccess: 'Pengajuan Anda berhasil dikirim!',
        applySuccessWithPending: 'Verifikasi butuh waktu hingga 24 jam',
        unableToLoanTitle: 'Pengajuan ditolak',
        unableToLoanMessage: 'Kami perhatikan Anda telah melanggar kebijakan platform, aplikasi tidak dapat disetujui',
        nkpOngoingMessage: 'Anda sudah memiliki akun Kredit Pintar dan setiap pengguna hanya bisa memiliki 1 akun',
        voucherFailed: 'Gagal menggunakan voucher, klik tombol ini untuk coba lagi',
        reSubmit: 'Coba lagi',
        effortlessMessage:
          'Pinjaman mudah akan membantu tingkatkan kredit Anda, mohon lengkapi pengajuannya terlebih dahulu',
        effortlessLoginForbid: 'Maaf, saat ini Anda tidak memenuhi syarat untuk mengajukan pinjaman',
        effortlessFaceMessage: 'Verifikasi wajah Anda tidak cocok, pastikan foto Anda jelas dan valid.',
        jagoNotRegister: 'Maaf, saat ini Anda tidak dapat daftar akun Jago',
        geolocationTitle: 'Akses lokasi dibutuhkan',
        geolocationMesage:
          'Informasi lokasi Anda memungkinkan kami mengenal Anda lebih baik dan membuat peminjaman menjadi instan dan mudah. Harap izinkan ini di pengaturan browser dan pengaturan sistem Anda',
        deleteAccountNoticeTitle: 'Perhatikan sebelum Anda menghapus akun Anda.',
        deleteAccountNoticeMessage1: 'Setelah penghapusan akun dikonfirmasi, akun Anda tidak dapat dikembalikan lagi.',
        deleteAccountNoticeMessage2:
          'Setelah Anda menghapus akun, informasi akun dan tagihan Anda akan hilang. Jika Anda registerasi ulang, tingkat kredit Anda akan dievaluasi ulang dan aktivasi mungkin akan gagal, harap berhati-hati.',
        deleteAccountNoticeBtn: 'Lanjutkan',
        deleteAccountConfirmTitle: 'Apakah Anda yakin ingin menghapus akun?',
        deleteAccountConfirmMessage: 'Setelah penghapusan akun dikonfirmasi, akun Anda tidak dapat dikembalikan lagi.',
        deleteAccountCancelBtn: 'Batal',
        deleteAccountConfirmBtn: 'Setuju',
      },
    },
  },
}
</script>

<style lang="stylus" scoped>
.popup-alert
  z-index 9999
.content
  width 100vw
.popup-wrapper
  background-color white
  margin auto 80px
  padding 46px 48px 56px
  border-radius 24px
  box-shadow 0px 4px 16px 0px rgba(0,0,0,0.08)
  .popup-img-wrapper
    display flex
    justify-content center
    align-items center
  .popup-img-wrapper-new
    display flex
    justify-content space-between
    align-items flex-start
    .popup-icon
      margin-bottom 30px
  .popup-img-wrapper-security
    justify-content flex-start
    .popup-icon
      margin 0 0 30px 0
  .popup-icon
    width 76px
    height 76px
    margin 0px auto 30px
  .popup-icon-new
    width 64px
    height 64px
    margin 0px
  .popup-icon-close
    width 36px
    height 36px
  .title
    font-size 40px
    line-height 48px
    fontBold()
    margin-bottom 16px
  .message
    font-size 32px
    line-height 38px
    color #666666
    margin-bottom 8px
  .message-wrapper
    margin-top 18px
    max-height 420px
    overflow scroll
    .message-new
      color #000
      line-height 52px
      font-size 32px
      margin-bottom 12px
  .message-pc
    overflow auto
  .foot-wrapper
    margin-top 48px
    overflow auto
    width 100%
    line-height 40px
    display flex
    justify-content flex-end
    gap 12px
    .button-ok
      fontBold()
      color #1cc812
      font-size 28px
      &.disabled
        opacity 0.5
        cursor pointer
    .button-re-submit
      background #1cc812
      color #fff
      font-family Inter
      padding 16px 28px
      line-height 32px
      border-radius 12px
    .button-cancel
      background #fff
      color #666
      font-family Inter
      padding 16px 45px
      line-height 32px
      border 1px solid #ccc
      border-radius 12px
    .left-btn
      font-size 28px
      color #1cc812
      font-family Inter
      padding 16px 28px
      line-height 32px
      margin-right 16px
  .foot-wrapper-new
    margin-top 48px
</style>
