import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import app from '@base/store/app'
import loan from '@base/store/loan'
import createLogger from 'vuex/dist/logger'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: {
    pageDurationName: '',
  },
  mutations: {
    savePageDurationName(state: any, name: string) {
      state.pageDurationName = name
    },
  },
  actions: {
    setPageDurationName({ commit }, name: string) {
      commit('savePageDurationName', name)
    },
  },
  modules: {
    user,
    loan,
    app,
  },
  strict: debug,
  plugins: debug
    ? [
        createLogger({}),
        createPersistedState({
          paths: [''],
        }),
      ]
    : [
        createPersistedState({
          paths: [''],
        }),
      ],
})
