





























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Component as VueComp } from 'vue-tsx-support'

@Component
export default class PhoneNumber extends VueComp<{}, {}> {
  @Prop({ type: Array, default: () => ['Country', ''] }) titles!: string[]
  @Prop({ type: [String, Number], default: '' }) value!: string | number
  @Prop({ type: String, default: '' }) placeholder!: string
  @Prop({ type: String, default: '' }) error!: string
  @Prop({ type: Boolean, default: false }) disablePoneCode!: boolean

  @Watch('value', { immediate: true })
  valueChange(val: string | number) {
    this.innerValue = val
  }

  private innerValue: string | number = ''
}
