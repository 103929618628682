// effortless

const EffortlessHome = () => import(/* webpackChunkName: "EffortlessHome" */ '@el/views/loan-home/EffortlessHome.vue')
const EffortlessApplyLoan = () => import(/* webpackChunkName: "EffortlessApplyLoan" */ '@el/views/apply-loan/index.vue')
const EsignLoading = () => import(/* webpackChunkName: "EsignLoading" */ '@el/views/apply/EsignLoading.vue')

const EffortlessLoanRoute = [
  {
    name: 'EffortlessHomePage',
    path: 'effortless/home',
    component: EffortlessHome,
    meta: {
      back: 'effortlessHomePage',
    },
  },
  {
    name: 'EffortlessApplyLoanPage',
    path: 'effortless/apply-loan',
    component: EffortlessApplyLoan,
  },
  {
    name: 'EffortlessEsignLoading',
    path: 'effortless/esign-loading',
    component: EsignLoading,
  },
]

export default EffortlessLoanRoute
