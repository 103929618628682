const DeleteAccount = () => import(/* webpackChunkName: "DeleteAccount" */ '../views/delete-account/index.vue')
const DeleteAccountReject = () =>
  import(/* webpackChunkName: "DeleteAccountReject" */ '../views/delete-account/DeleteAccountReject.vue')

const DeleteAccountRoute = [
  {
    name: 'DeleteAccount',
    path: 'delete-account',
    component: DeleteAccount,
  },
  {
    name: 'DeleteAccountReject',
    path: 'delete-account/reject',
    component: DeleteAccountReject,
  },
]

export default DeleteAccountRoute
