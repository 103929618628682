export enum JagoAccountEnum {
  /** Nik  Email  */
  NIK_OR_EMAIL_LACKED = 'NIK_OR_EMAIL_LACKED',
  /**  UNREGISTERED | NEW | IDM_PROFILE_CREATED | IDM_PROFILE_ACTIVE | IN_REGISTRATION | PROSPECT */
  OTHERS = 'OTHERS',
  /**  */
  GO_REGISTER = 'GO_REGISTER',
  /** SEMI_ACTIVE | ESCALATED | APPROVED */
  PENDING = 'PENDING',
  /**  */
  ACTIVE = 'ACTIVE',
  //开卡申请被拒
  REJECTED = 'REJECTED',
  /** jago 400  5 尝试次数过多请明天再试*/
  MAX_RETRY_COUNT_REACHED_TRY_TOMORROW = 'MAX_RETRY_COUNT_REACHED_TRY_TOMORROW',
  /** jago 400  5(register ) */
  TRY_AGAIN = 'TRY_AGAIN',
  /** jago 400  5(register2 ) */
  TRY_AGAIN_LATER = 'TRY_AGAIN_LATER',
  //用户信息缺失
  USER_INFO_LACKED = 'USER_INFO_LACKED',
  //开卡成功待绑定 返回跳jago获取authCode的url
  TO_BE_BOUND = 'TO_BE_BOUND',
  //需要更新手机号或邮箱
  NEED_TO_UPDATE_MOBILE_OR_EMAIL = 'NEED_TO_UPDATE_MOBILE_OR_EMAIL',
  //调用成功, 请稍后
  WAITING = 'WAITING',
  // 没有ktp 、活体信息
  NEED_BASIC_INFO = 'NEED_BASIC_INFO',
  //没有ktp 有活体，不能继续提交
  CANT_SUBMIT = 'CANT_SUBMIT',
  // any liveness photo of the user, toast
  NEED_LIVENESS_PHOTO = 'NEED_LIVENESS_PHOTO',
}

export enum JagoBindingEnum {
  SUCCESS = 'SUCCESS',
  // code验证失败
  AUTH_FAILED = 'AUTH_FAILED',
}
