import { captureException } from '@sentry/browser'

export const getItem = (key: string) => {
  try {
    return localStorage.getItem(key)
  } catch (error) {
    captureException(error)
    return null
  }
}

export const setItem = (key: string, value: any) => {
  try {
    localStorage.setItem(key, value)
  } catch (error) {
    captureException(error)
  }
}

export const removeItem = (key: string) => {
  try {
    localStorage.removeItem(key)
  } catch (error) {
    captureException(error)
  }
}

export const getBaseItem = (name: string, defaultValue: string) => {
  return JSON.parse(getItem(name) || defaultValue)
}

export const getThirdPartyParam = (key: string) => {
  return getBaseItem('thirdPartyParams', '{}')[key]
}

export const getVkycList = () => {
  if (!getItem('vkycList')) {
    setItem('vkycList', JSON.stringify([]))
  }
  return JSON.parse(getItem('vkycList') as string)
}

export const getSession = (key: string) => {
  try {
    const value = sessionStorage.getItem(key)
    try {
      const data = value && JSON.parse(value)
      if (data?.start) {
        const now = Date.now()
        // 过期则删除缓存
        if (now - data.start > data.expires) {
          sessionStorage.removeItem(key)
          return null
        } else {
          return data.value
        }
      }
      return data
    } catch (e) {
      console.log('JSON.parse(data) error!')
      return value
    }
  } catch (error) {
    captureException(error)
    return null
  }
}

export const setSession = (key: string, value: any, expires?: number) => {
  try {
    if (expires) {
      sessionStorage.setItem(
        key,
        JSON.stringify({
          value,
          expires,
          start: Date.now(),
        }),
      )
    } else {
      sessionStorage.setItem(key, JSON.stringify(value))
    }
  } catch (error) {
    captureException(error)
  }
}

export const removeSession = (key: string) => {
  try {
    sessionStorage.removeItem(key)
  } catch (error) {
    captureException(error)
  }
}
