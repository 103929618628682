import ajax from '@base/lib/ajax'
import { IVideoKycStatus } from '@base/interface/common'

export const getDefaultAgreement = (params: { loanId?: string; productId?: string }) =>
  ajax.get('/loan/loan-agreement', { params })
export const getDefaultAgreementV1 = (params: { productId?: string }) =>
  ajax.post('/loan/v1/loan-agreement/pre-view', params)

// H5 development login
export const login = () =>
  ajax.post('/auth/login/otp', {
    otp: '0000',
    mobileNumber: '+6281265397945', //'+628574912296', //'+62820211223453', //'+62820211223111', //'+62852877090',
  })

// get
export const getAllOrder = () => ajax.get('/loan/list')

export const getRewardMissions = () => ajax.get(`/api/reward-missions`)

// ------------  ---------------
/**
 *  link
 */
export const getlinkAccountStatus = () => ajax.get('/crawler/list-website/authorized')

/**
 *  Link
 */
export const getLinkLoginAuth = (params: { website: string }) => ajax.get('/crawler/auth/parameters', { params })

/**
 * link
 */
export const submitLinkAccount = (params: any) => ajax.post('/crawler/auth/submit', params)

/**
 * link
 */
export const linkRetryApi = (params: { taskId: string }) => ajax.post('/crawler/auth/refresh', params)

/**
 *  ，，
 */
export const authCaptcha = (params: any) => ajax.post('/crawler/captcha/submit', params)

/**
 *
 * @param params
 */
export const repeatSendCode = (params: any) => ajax.post('/crawler/captcha/resubmit', params)

/**
 * video kyc
 */
export const getIsNeedVideoKyc = () => ajax.get<string | null>('/vkyc/landing-url')

/**
 * video kyc
 */
export const getVideoKycStatus = () => ajax.get<IVideoKycStatus>('/vkyc/status')

/**
 * video kyc
 */
export const getVideoKycUrl = () => ajax.get<string>('/vkyc/video-record-url')

export const getIfDelayNotification = () => ajax.get<string>('/variable/delay-notification-status')
