import { IPDFData, ISelectedInsurance } from '@base/interface/loan'
import { VuexModule, Module, Mutation } from 'vuex-module-decorators'
import { getItem } from '@base/lib/storage-handler'

export interface ILoanStore {
  tenor?: number
  amount?: number
  channel: string
  bankAccount: string
  bankCode: string
  productId?: string
  rateVersion: string
  loanPurpose: string
  isChangeBank: boolean
  insuranceId: string
  locationParams: any
  extra: any
  bankList: Array<{
    paymentBankCode: string
    paymentBankAccountNo: string
  }>
  concurrent: boolean
  from: string
}

interface IBankInfo {
  bankCode: string
  bankAccount: string
}
@Module({ name: 'loan' })
export default class Loan extends VuexModule {
  tenor = 0
  amount = 0
  channel = 'OKP'
  bankAccount = ''
  bankCode = ''
  productId = ''
  rateVersion = ''
  loanPurpose = ''
  isChangeBank = false
  insuranceId = ''
  locationParams: any = null
  extra: any = null
  bankList: Array<{
    paymentBankCode: string
    paymentBankAccountNo: string
  }> = []
  concurrent = false
  selectedInsurance: ISelectedInsurance = {
    productId: '',
    insuranceId: '',
    isSelected: null,
    userId: '',
    createTime: 0,
  }
  pdfData: IPDFData = {
    chubb_insurance_pa_general_pdf: '',
    chubb_insurance_life_pdf: '',
  }

  selectBankInfo = {
    bankCode: '',
    bankAccount: '',
  }
  from = ''

  @Mutation
  resetLoanParams() {
    this.tenor = 0
    this.amount = 0
    this.channel = 'OKP'
    this.bankAccount = ''
    this.bankCode = ''
    this.productId = ''
    this.rateVersion = ''
    this.loanPurpose = ''
    this.isChangeBank = false
    this.bankList = []
    this.insuranceId = ''
    this.locationParams = null
    this.extra = null
    this.concurrent = false
    this.selectBankInfo = {
      bankAccount: '',
      bankCode: '',
    }
    this.from = ''
  }

  @Mutation
  saveLoanParams(payload: ILoanStore) {
    this.channel = payload.channel || 'OKP'
    this.bankAccount = payload.bankAccount || ''
    this.bankCode = payload.bankCode || ''
    this.loanPurpose = payload.loanPurpose || ''
    this.isChangeBank = payload.isChangeBank
    this.bankList = payload.bankList || []
    this.insuranceId = payload.insuranceId || ''
    this.locationParams = payload.locationParams
    this.extra = payload.extra
    this.concurrent = payload.concurrent
    this.productId = payload.productId || ''
    this.rateVersion = payload.rateVersion || ''
    this.from = payload.from || ''
  }

  @Mutation
  saveSelectedInsurance(selectedInsurance: ISelectedInsurance) {
    const userInfo = getItem('userInfo')
    try {
      if (userInfo) {
        const { userId } = JSON.parse(userInfo)
        this.selectedInsurance = selectedInsurance
        this.selectedInsurance.userId = userId
      }
    } catch (error: any) {
      this.selectedInsurance = selectedInsurance
    }
  }

  @Mutation
  saveInsurancePDFData(data: IPDFData) {
    this.pdfData = data
  }

  @Mutation
  saveSelectBankInfo(data: IBankInfo) {
    this.selectBankInfo = data
  }
}
